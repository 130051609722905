define("soferuj/models/review/details", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    rating5Count: (0, _model.attr)('number'),
    rating4Count: (0, _model.attr)('number'),
    rating3Count: (0, _model.attr)('number'),
    rating2Count: (0, _model.attr)('number'),
    rating1Count: (0, _model.attr)('number'),
    question1Rating: (0, _model.attr)('number'),
    question2Rating: (0, _model.attr)('number'),
    question3Rating: (0, _model.attr)('number'),
    recommended: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});