define("ember-paper/templates/components/paper-grid-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zZuYxcHu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"tile\"],[[30,[36,0],[\"paper-grid-tile\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-grid-list.hbs"
    }
  });
  _exports.default = _default;
});