define("soferuj/modules/test/index/components/test-options/component", ["exports", "lodash", "soferuj/modules/statics"], function (_exports, _lodash, _statics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    questionTypeLocal: Ember.computed('options.questionType', function () {
      return _statics.default.questionsTypeLocal[this.get('options.questionType')];
    }),
    testNumbers: Ember.computed('options.type', function () {
      let range = this.get('options.type') === 'ab' ? _lodash.default.range(1, 36) : _lodash.default.range(36, 61);
      let numbers = range.map(function (num) {
        return {
          label: num,
          value: num
        };
      });
      return [{
        label: "náhodný",
        value: 0
      }].concat(numbers);
    }),
    questionSettingsAvailable: Ember.computed('options.number', function () {
      return this.isTrainingType && this.get('options.number.value') === 0;
    }),
    questionNumbers: [{
      label: 10,
      value: 10
    }, {
      label: 20,
      value: 20
    }, {
      label: '27 (oficiálny test)',
      value: 27
    }, {
      label: 30,
      value: 30
    }, {
      label: 50,
      value: 50
    }, {
      label: 75,
      value: 75
    }, {
      label: 100,
      value: 100
    }]
  });
  _exports.default = _default;
});