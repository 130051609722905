define('ember-router-helpers/helpers/transition-to', ['exports', 'ember-router-helpers/utils/handle-query-params'], function (exports, _handleQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),

    compute(_params) {
      return maybeEvent => {
        if (maybeEvent !== undefined && typeof maybeEvent.preventDefault === 'function') {
          maybeEvent.preventDefault();
        }

        let params = (0, _handleQueryParams.default)(_params);
        return this.get('router').transitionTo(...params);
      };
    }
  });
});