define("soferuj/modules/components/image-cropper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lIdEnE7o",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cropper-container\"],[14,5,\"max-width:400px;\"],[12],[2,\"\\n\\t\"],[10,\"img\"],[15,\"src\",[30,[36,1],[\"/images/tmp/\",[35,0,[\"profilePicture\"]]],null]],[14,\"width\",\"400px\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],null,[[\"value\",\"class\",\"onChange\"],[[32,0,[\"accepted\"]],\"profile-pic-consent\",[30,[36,3],[[32,0],[30,[36,2],[[32,0,[\"accepted\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\tSúhlasím so \"],[10,\"a\"],[14,6,\"/documents/suhlas-so-spracovanim-osobnych-udajov-profilovka.pdf\"],[14,\"target\",\"_blank\"],[12],[2,\"spracovaním osobných údajov - profilová fotka\"],[13],[2,\".\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"layout\"],[12],[2,\"\\n\\t\"],[6,[37,6],null,[[\"raised\",\"primary\",\"class\",\"disabled\",\"onClick\"],[true,true,\"green\",[30,[36,5],[[32,0,[\"accepted\"]]],null],[30,[36,3],[[32,0],\"cropp\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Uložiť\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[6,[37,6],null,[[\"onClick\"],[[30,[36,3],[[32,0],\"cancel\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Zrušiť\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"concat\",\"mut\",\"action\",\"paper-checkbox\",\"not\",\"paper-button\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/image-cropper/template.hbs"
    }
  });
  _exports.default = _default;
});