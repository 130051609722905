define("soferuj/modules/driving-school/index/components/language-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H+B5YjoT",
    "block": "{\"symbols\":[\"@language\"],\"statements\":[[10,\"div\"],[14,0,\"language-item\"],[12],[2,\"\\n  \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/index/components/language-item/template.hbs"
    }
  });
  _exports.default = _default;
});