define("soferuj/models/review/author", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    authorId: (0, _model.attr)('string'),
    // change to ID
    profilePicture: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    myVote: (0, _model.attr)('string')
  });
  _exports.default = _default;
});