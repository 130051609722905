define('@fortawesome/ember-fontawesome/components/fa-icon', ['exports', '@fortawesome/ember-fontawesome/templates/components/fa-icon', '@fortawesome/fontawesome-svg-core', 'ember-get-config'], function (exports, _faIcon, _fontawesomeSvgCore, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getConfigOption(key, defaultValue) {
    return Ember.getWithDefault(_emberGetConfig.default, `fontawesome.${key}`, defaultValue);
  }

  function objectWithKey(key, value) {
    return Array.isArray(value) && value.length > 0 || !Array.isArray(value) && value ? { [key]: value } : {};
  }

  function classList(previousClasses) {
    let classes = {
      'fa-spin': this.get('spin'),
      'fa-pulse': this.get('pulse'),
      'fa-fw': this.get('fixedWidth'),
      'fa-border': this.get('border'),
      'fa-li': this.get('listItem'),
      'fa-flip-horizontal': this.get('flip') === 'horizontal' || this.get('flip') === 'both',
      'fa-flip-vertical': this.get('flip') === 'vertical' || this.get('flip') === 'both',
      [`fa-${this.get('size')}`]: this.getWithDefault('size', null) !== null,
      [`fa-rotate-${this.get('rotation')}`]: this.getWithDefault('rotation', null) !== null,
      [`fa-pull-${this.get('pull')}`]: this.getWithDefault('pull', null) !== null
    };

    return Object.keys(classes).map(key => classes[key] ? key : null).filter(key => key).concat(previousClasses.filter(c => !c.match(/^fa-/)));
  }

  function normalizeIconArgs(prefix, icon) {
    const defaultPrefix = getConfigOption('defaultPrefix', 'fas');

    if (!icon) {
      return { prefix: defaultPrefix, iconName: null };
    }

    if (typeof icon === 'object' && icon.prefix && icon.iconName) {
      return icon;
    }

    if (typeof prefix === 'string' && typeof icon === 'string') {
      return { prefix: prefix, iconName: icon };
    }

    if (typeof icon === 'string') {
      return { prefix: defaultPrefix, iconName: icon };
    }
  }

  const IconComponent = Ember.Component.extend({
    layout: _faIcon.default,
    tagName: 'svg',
    classNameBindings: ['allClasses'],
    attributeBindings: [
    // attributes watched for mutation
    'data-prefix', 'data-icon', 'data-fa-transform', 'data-fa-mask', 'data-fa-processed',
    // accessibility attributes
    'aria-hidden', 'aria-labelledby',
    // svg attributes
    'role', 'xmlns', 'viewBox', 'safeStyle:style'],
    html: Ember.computed('abstractIcon.children.[]', function () {
      const abstractIcon = this.get('abstractIcon');
      let newHtml;
      if (!abstractIcon) {
        newHtml = Ember.String.htmlSafe('');
      } else {
        newHtml = Ember.String.htmlSafe(abstractIcon.children.reduce((acc, cur) => {
          return `${acc}${(0, _fontawesomeSvgCore.toHtml)(cur)}`;
        }, ''));
      }
      return newHtml;
    }),
    safeStyle: Ember.computed('attributes', function () {
      const attributes = this.get('attributes');
      const style = Ember.getWithDefault(attributes, 'style');
      return style ? Ember.String.htmlSafe(`${style}`) : undefined;
    }),
    abstractIcon: Ember.computed('prefix', 'class', 'icon', 'transform', 'mask', 'symbol', 'title', 'spin', 'pulse', 'fixedWidth', 'listItem', 'border', 'flip', 'size', 'rotation', 'pull', function () {
      const iconLookup = normalizeIconArgs(this.get('prefix'), this.get('icon'));
      const classes = objectWithKey('classes', [...classList.bind(this)(this.getWithDefault('class', '').split(' '))]);
      const transformProp = this.get('transform');
      const transform = objectWithKey('transform', typeof transformProp === 'string' ? _fontawesomeSvgCore.parse.transform(transformProp) : transformProp);
      const mask = objectWithKey('mask', normalizeIconArgs(null, this.get('mask')));
      const symbol = this.getWithDefault('symbol', false);
      let title = this.getWithDefault('title', null);
      if (title) {
        title = `${title}`;
      }

      const o = Ember.assign({}, classes, transform, mask, { symbol, title });

      const renderedIcon = (0, _fontawesomeSvgCore.icon)(iconLookup, o);
      if (!renderedIcon) {
        console.warn(`Could not find icon: iconName=${iconLookup.iconName}, prefix=${iconLookup.prefix}`);
        return null;
      }

      return renderedIcon.abstract[0];
    }),
    attributes: Ember.computed('abstractIcon.attributes', function () {
      const abstractIcon = this.get('abstractIcon');
      return abstractIcon ? abstractIcon.attributes : {};
    }),
    allClasses: Ember.computed('abstractIcon', 'attributes.class', 'class', function () {
      const abstractIcon = this.get('abstractIcon');
      const attributes = this.get('attributes');
      const classes = this.get('class');
      const iconClasses = Ember.getWithDefault(attributes, 'class');
      if (!abstractIcon) {
        return _fontawesomeSvgCore.config.replacementClass;
      }

      return `${iconClasses} ${classes}`;
    }),
    'data-prefix': Ember.computed('attributes.data-prefix', function () {
      const attributes = this.get('attributes');
      return Ember.getWithDefault(attributes, 'data-prefix');
    }),
    'data-icon': Ember.computed('attributes.data-icon', function () {
      const attributes = this.get('attributes');
      return Ember.getWithDefault(attributes, 'data-icon');
    }),
    'data-fa-transform': Ember.computed('attributes.data-fa-transform', function () {
      const attributes = this.get('attributes');
      return Ember.getWithDefault(attributes, 'data-fa-transform');
    }),
    'data-fa-mask': Ember.computed('attributes.data-fa-mask', function () {
      const attributes = this.get('attributes');
      return Ember.getWithDefault(attributes, 'data-fa-mask');
    }),
    'data-fa-processed': Ember.computed('attributes.data-fa-processed', function () {
      const attributes = this.get('attributes');
      return Ember.getWithDefault(attributes, 'data-fa-processed');
    }),
    'aria-hidden': Ember.computed('attributes.aria-hidden', function () {
      const attributes = this.get('attributes');
      return Ember.getWithDefault(attributes, 'aria-hidden');
    }),
    'aria-labelledby': Ember.computed('attributes.aria-labelledby', function () {
      const attributes = this.get('attributes');
      return Ember.getWithDefault(attributes, 'aria-labelledby');
    }),
    'role': Ember.computed('attributes.role', function () {
      const attributes = this.get('attributes');
      return Ember.getWithDefault(attributes, 'role');
    }),
    'xmlns': Ember.computed('attributes.xmlns', function () {
      const attributes = this.get('attributes');
      return Ember.getWithDefault(attributes, 'xmlns');
    }),
    'viewBox': Ember.computed('attributes.viewBox', function () {
      const abstractIcon = this.get('abstractIcon');
      if (!abstractIcon) {
        return '0 0 448 512';
      }
      const attributes = this.get('attributes');
      return Ember.getWithDefault(attributes, 'viewBox');
    })
  });

  // Enables {{fa-icon 'iconnamehere'}} syntax, while still allowing {{fa-icon icon='iconnamehere'}}
  IconComponent.reopenClass({
    positionalParams: ['icon']
  });

  exports.default = IconComponent;
});