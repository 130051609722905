define("soferuj/modules/driving-school/pricing/components/course-form/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    types: [{
      type: 'standard',
      name: 'štandardný'
    }, {
      type: 'training',
      name: 'kondičné jazdy'
    }, {
      type: 'express',
      name: 'rýchlokurz'
    }],
    categories: [{
      id: 1,
      name: 'A'
    }, {
      id: 2,
      name: 'AM'
    }, {
      id: 3,
      name: 'A1'
    }, {
      id: 4,
      name: 'A2'
    }, {
      id: 5,
      name: 'B'
    }, {
      id: 6,
      name: 'B1'
    }, {
      id: 7,
      name: 'BE'
    }, {
      id: 8,
      name: 'C'
    }, {
      id: 9,
      name: 'C1'
    }, {
      id: 10,
      name: 'C1E'
    }, {
      id: 11,
      name: 'CE'
    }, {
      id: 12,
      name: 'D'
    }, {
      id: 13,
      name: 'D1'
    }, {
      id: 14,
      name: 'D1E'
    }, {
      id: 15,
      name: 'DE'
    }, {
      id: 16,
      name: 'T'
    }],
    durations: [{
      value: 'hours',
      label: 'hodín'
    }, {
      value: 'days',
      label: 'dni'
    },
    //{value: 'weeks', label: 'týždene'},
    {
      value: 'months',
      label: 'mesiace'
    }],
    type: Ember.computed('course.type', function () {
      return _lodash.default.find(this.types, {
        type: this.get('course.type')
      });
    }),
    duration: Ember.computed('course.duration', function () {
      const duration = this.get('course.duration');
      const interval = _lodash.default.find(this.durations, {
        value: _lodash.default.keys(duration)[0]
      });
      return {
        value: _lodash.default.values(duration)[0],
        interval
      };
    }),
    actions: {
      changeType(_ref) {
        let {
          type
        } = _ref;
        this.set('course.type', type);
      },
      changeDurationInterval(interval) {
        let duration = {};
        const value = _lodash.default.values(this.get('course.duration'))[0];
        duration[interval.value] = value;
        this.set('course.duration', duration);
      },
      changeDurationValue(value) {
        let duration = {};
        const interval = _lodash.default.keys(this.get('course.duration'))[0];
        duration[interval] = Number(value);
        this.set('course.duration', duration);
      }
    }
  });
  _exports.default = _default;
});