define("soferuj/modules/driving-school/components/edit-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Cm/3fE+I",
    "block": "{\"symbols\":[\"&default\",\"@onEditSection\",\"@showTrigger\"],\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"edit-trigger\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@raised\",\"@mini\",\"@accent\",\"@class\",\"@onClick\"],[true,true,true,\"edit-trigger-button\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\",\"@class\"],[\"pencil-alt\",\"icon\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/components/edit-trigger/template.hbs"
    }
  });
  _exports.default = _default;
});