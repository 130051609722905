define("soferuj/modules/driving-school/contact/components/map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5o8/DRXl",
    "block": "{\"symbols\":[\"g\",\"@editable\"],\"statements\":[[10,\"div\"],[14,0,\"driving-school-contact-map\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"edit-container\"],[12],[2,\"\\n      \"],[8,\"place-autocomplete-field\",[],[[\"@value\",\"@handlerController\",\"@inputClass\",\"@types\",\"@class\",\"@setValueWithProperty\",\"@restrictions\",\"@preventSubmit\",\"@placeChangedCallback\"],[[32,0,[\"address\"]],[32,0],\"place-autocomplete--input\",\"geocode\",\"flex\",\"name\",[32,0,[\"restrictions\"]],true,[32,0,[\"addressChanged\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"paper-input\",[],[[\"@placeholder\",\"@value\",\"@onChange\"],[\"Vyhľadať presnú adresu (napr. Komenského 99, Košice)\",[32,0,[\"address\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"address\"]]],null]],null]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"small\"],[14,0,\"search-description\"],[12],[2,\"Upresnite polohu na mape, presunutím ukazovateľa\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"g-map\",[],[[\"@lat\",\"@lng\",\"@zoom\",\"@onLoad\"],[[32,0,[\"mapProps\",\"lat\"]],[32,0,[\"mapProps\",\"lng\"]],[32,0,[\"mapProps\",\"zoom\"]],[32,0,[\"onMapLoad\"]]]],[[\"default\"],[{\"statements\":[[8,[32,1,[\"canvas\"]],[[24,0,\"ember-google-map\"]],[[],[]],null],[2,\"\\n    \"],[8,[32,1,[\"marker\"]],[],[[\"@lat\",\"@lng\",\"@draggable\",\"@icon\",\"@onDragend\"],[[32,0,[\"mapProps\",\"lat\"]],[32,0,[\"mapProps\",\"lng\"]],[32,2],\"/images/marker.png\",[30,[36,1],[[32,0,[\"markerDragged\"]]],null]]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/contact/components/map/template.hbs"
    }
  });
  _exports.default = _default;
});