define("soferuj/modules/test/history/test/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    test: Ember.computed('model', function () {
      return this.model ? this.model.data.attributes : null;
    }),
    actions: {
      markQuestion() {
        return false;
      }
    }
  });
  _exports.default = _default;
});