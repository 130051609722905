define("ember-paper/templates/components/paper-optgroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5IBsXtez",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"label\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"label\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-optgroup.hbs"
    }
  });
  _exports.default = _default;
});