define("soferuj/modules/test/components/question-answer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IIR2FZ2K",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,10],null,[[\"onClick\",\"hasPrimaryAction\",\"shouldBeClickable\",\"focused\"],[[30,[36,9],[[32,0],[35,8],[35,7],[35,0]],null],[30,[36,6],[[35,5]],null],[30,[36,6],[[35,5]],null],[30,[36,6],[[35,5]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"span\"],[14,0,\"sign\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,1],null,[[\"value\"],[[35,0]]]]],[2,\")\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"p\"],[14,0,\"text\"],[12],[1,[34,2]],[6,[37,4],[[30,[36,3],[[35,0],3],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\".\"]],\"parameters\":[]},{\"statements\":[[2,\",\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"answerNumber\",\"test/components/question-sign\",\"questionAnswer\",\"eq\",\"if\",\"isClickable\",\"readonly\",\"questionNumber\",\"onQuestionMark\",\"action\",\"paper-item\"]}",
    "meta": {
      "moduleName": "soferuj/modules/test/components/question-answer/template.hbs"
    }
  });
  _exports.default = _default;
});