define("ember-google-maps/components/g-map/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Renders a canvas div into which the map is inserted.
   *
   * @class Canvas
   * @namespace GMap
   * @module ember-google-maps/components/g-map/canvas
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',
    // TODO: Remove in Octane version. Use `...attributes` instead.
    computedClasses: Ember.computed('class', 'classNames', function () {
      let classes = ['ember-google-map', Ember.get(this, 'class'), Ember.get(this, 'classNames')];
      return classes.filter(x => x).join(' ');
    }),
    didInsertElement() {
      this._super(...arguments);
      function registerCanvas() {
        let id = Ember.get(this, 'id');
        let canvas = document.getElementById(id);
        this._internalAPI._registerCanvas(canvas);
      }

      // TODO: Remove in Octane version. Splattributes somehow affect the
      // rendering loop, so this is necessary for 2.18.
      Ember.run.scheduleOnce('render', this, registerCanvas);
    }
  });
  _exports.default = _default;
});