define("soferuj/models/news", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    text: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date')
  });
  _exports.default = _default;
});