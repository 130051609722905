define("ember-metrics/-private/utils/object-transforms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compact = compact;
  _exports.default = void 0;
  _exports.hasOwnProperties = hasOwnProperties;
  _exports.without = without;
  /**
   * Present config options
   * @see {@link https://api.emberjs.com/ember/3.28/functions/@ember%2Futils/isPresent|isPresent}
   * @param {Options} opts
   * @returns {Options}
   */
  function compact(opts) {
    return includeKeys(opts, key => Ember.isPresent(opts[key]));
  }

  /**
   * Config options without `excludedKeys`
   * @param {Options} opts
   * @param {string[]} excludedKeys
   * @returns {Options}
   */
  function without(opts, excludedKeys) {
    return includeKeys(opts, key => !excludedKeys.includes(key));
  }

  /**
   * Config options with allowed keys
   * @param {Options} opts
   * @param {(key:string) => boolean} include
   * @returns {Options}
   */
  function includeKeys(opts, include) {
    const newOpts = {};
    for (const key in opts) {
      if (include(key)) newOpts[key] = opts[key];
    }
    return newOpts;
  }

  /**
   * Check if given object has any own properties
   * @param {Record<string,unknown>} obj
   * @returns {boolean}
   */
  function hasOwnProperties(obj) {
    return Object.getOwnPropertyNames(obj).length > 0;
  }
  var _default = {
    compact,
    without,
    hasOwnProperties
  };
  _exports.default = _default;
});