define("soferuj/modules/info/road-signs/road-sign/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RoadSignComponent extends _component.default {
    get sign() {
      return this.args.sign;
    }
    get description() {
      return Ember.String.htmlSafe(this.args.sign.description);
    }
  }
  _exports.default = RoadSignComponent;
});