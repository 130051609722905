define("soferuj/modules/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    headTags: [{
      type: 'meta',
      tagId: 'description',
      attrs: {
        name: 'description',
        content: 'Zoznam autoškôl na Slovensku, testy na vodičák spracované ako nikde inde a množstvo užitočných informácií.'
      }
    }],
    model() {
      let model = this.store.peekAll('news');
      if (!model.length) {
        model = this.store.query('news', {
          limit: 5
        });
      }
      return model;
    }
  });
  _exports.default = _default;
});