define("soferuj/modules/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yWRAcHZT",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"class\"],[\"not-found\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"layout-column flex-100 layout-align-center-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"error-code\"],[12],[2,\"Vaša stránka nebola nájdená\"],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[2,\"Nahrali sme novú verziu webstránky, čo mohlo spôsobiť, že adresa na ktorú sa chcete dostať sa presunula. \"],[10,\"br\"],[12],[13],[2,\" Ospravedlňujeme sa za to.\"],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[2,\"Hľadáte niečo z tohto?\"],[13],[2,\"\\n\"],[6,[37,1],null,[[\"route\"],[\"driving-schools\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[6,[37,0],null,[[\"primary\",\"raised\"],[true,true]],[[\"default\"],[{\"statements\":[[2,\"Autoškoly\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],null,[[\"route\"],[\"test\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[6,[37,0],null,[[\"primary\",\"raised\"],[true,true]],[[\"default\"],[{\"statements\":[[2,\"Testy\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],null,[[\"route\"],[\"info\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[6,[37,0],null,[[\"primary\",\"raised\"],[true,true]],[[\"default\"],[{\"statements\":[[2,\"Informácie\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"paper-button\",\"link-to\",\"page/page-content\"]}",
    "meta": {
      "moduleName": "soferuj/modules/not-found/template.hbs"
    }
  });
  _exports.default = _default;
});