define("soferuj/modules/register/driving-school/basic-info/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    registration: Ember.inject.controller('register.drivingSchool'),
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service(),
    duplicateSchools: null,
    actions: {
      checkDuplicate() {
        this.get('model.name.length') >= 3 && this.ajax.request(`driving-schools?duplicates=${this.get('model.name')}`).then(response => {
          if (response.data.length) {
            this.registration.send('toggleDuplicate');
            this.set('duplicateSchools', response.data);
          }
        });
      },
      toggleDuplicate() {
        this.registration.send('toggleDuplicate');
      },
      setOwner(school) {
        this.registration.send('setDrivingSchoolOwner', school);
      },
      submit() {
        this.registration.send('nextStep');
      }
    }
  });
  _exports.default = _default;
});