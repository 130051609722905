define("soferuj/modules/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8TSVmcKN",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"],[1,[34,1]],[2,\"\\n\"],[10,\"main\"],[12],[2,\"\\n\\t\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[34,4]],[2,\"\\n\"],[1,[34,5]],[2,\"\\n\"],[8,\"application/components/cookie-consent\",[],[[],[]],null],[2,\"\\n\"],[1,[34,6]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"head-layout\",\"application/components/navigation-bar\",\"-outlet\",\"component\",\"paper-toaster\",\"application/components/scroll-up\",\"application/components/main-footer\"]}",
    "meta": {
      "moduleName": "soferuj/modules/application/template.hbs"
    }
  });
  _exports.default = _default;
});