define("in-repo-pin-addon/templates/components/g-map-addons/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bQyC189D",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[30,[36,1],[[35,0,[\"marker\"]],\"expected `gMap.marker` to be a contextual component but found a string. Did you mean `(component gMap.marker)`? ('in-repo-pin-addon/templates/components/g-map-addons/pin.hbs' @ L1:C2) \"],null]],[[\"lat\",\"lng\"],[\"51.507568\",\"-0.127762\"]]]]],\"hasEval\":false,\"upvars\":[\"gMap\",\"-assert-implicit-component-helper-argument\",\"component\"]}",
    "meta": {
      "moduleName": "in-repo-pin-addon/templates/components/g-map-addons/pin.hbs"
    }
  });
  _exports.default = _default;
});