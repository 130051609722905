define("soferuj/modules/login/components/password-form/component", ["exports", "soferuj/models/user/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_validations.default, {
    ajax: Ember.inject.service(),
    identity: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    isPasswordVisible: false,
    inputType: Ember.computed('isPasswordVisible', function () {
      return this.isPasswordVisible ? "text" : "password";
    }),
    actions: {
      onSubmit() {
        this.set('isSubmitted', true);
        const notifications = this.paperToaster;
        if (this.get('validations.attrs.password.isInvalid')) {
          return;
        }
        let data = {
          password: this.password,
          token: this.token
        };
        this.set('password', '');
        this.ajax.patch('change-password', {
          data
        }).then(() => {
          this.router.transitionTo('login');
          notifications.show('Heslo bolo zmenené, môžte sa prihlásiť');
        }).catch(_ref => {
          let {
            payload
          } = _ref;
          if (payload.errors === 'token invalid') {
            // this.set('errorMessage', 'Chyba. Zresetujte si heslo ešte raz.');
            notifications.show('Chyba. Zresetujte si heslo ešte raz.', {
              toastClass: 'warning'
            });
          }
        });
      }
    }
  });
  _exports.default = _default;
});