define("soferuj/modules/login/activation/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let statuses = {
    loading: {
      icon: 'cog',
      iconClass: 'registration-checking-icon',
      title: 'Prebieha kontrola účtu.',
      subtitle: 'Potrvá to len chvíľku...'
    },
    success: {
      icon: 'check-circle',
      iconPrefix: 'far',
      iconClass: 'registration-completed-icon',
      title: 'Aktivácia prebehla úspešne',
      subtitle: 'Za chvíľku budete automaticky presmerovaný'
    },
    failed: {
      icon: 'times',
      iconClass: 'registration-times-icon',
      title: 'Platnosť aktivácie vypršala',
      subtitle: 'Preposlať aktivačný email'
    },
    sent: {
      icon: 'envelope',
      iconPrefix: 'far',
      iconClass: 'registration-sent-icon',
      title: 'Aktivačný email bol odoslaný',
      subtitle: 'Skontrolujte emailovu schránku'
    }
  };
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service('ajax'),
    identity: Ember.inject.service(),
    state: 'loading',
    init: function () {
      this._super();
      this.addObserver('model.token', this.tokenLoaded);
    },
    tokenLoaded: function () {
      let token = this.get('model.token');
      this.ajax.post('activation', {
        data: {
          token
        }
      }).then(() => {
        this.set('state', 'success');
        Ember.run.later(async () => {
          this.transitionToRoute('/');
          await this.identity.load();
        }, 2000);
      }).catch(_ref => {
        let {
          payload
        } = _ref;
        if (payload.errors === 'token invalid') {
          this.set('state', 'failed');
        }
      });
    },
    status: Ember.computed('state', function () {
      return statuses[this.state];
    }),
    actions: {
      resendActivation() {
        this.ajax.post('resend-activation', {
          data: {
            token: this.get('model.token')
          }
        }).then(() => {
          this.set('state', 'sent');
        });
      }
    }
  });
  _exports.default = _default;
});