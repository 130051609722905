define("soferuj/modules/register/driving-school/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Vj4MlSTG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-register-driving-school\"],[12],[2,\"\\n\"],[6,[37,13],null,[[\"title\"],[\"Registrácia autoškoly\"]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,12],[[30,[36,10],[[35,11]],null],[30,[36,10],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,9],null,[[\"currentStepIndex\",\"isDuplicate\"],[[35,8],[35,7]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,14],null,null,[[\"default\"],[{\"statements\":[[6,[37,6],null,[[\"class\"],[\"layout-column\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"header\"],[14,0,\"layout-padding\"],[12],[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[10,\"h2\"],[12],[2,\"Registráciou získate kontrolu nad svojou autoškolou\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[10,\"h2\"],[12],[6,[37,1],[[35,0,[\"name\"]]],null,[[\"default\"],[{\"statements\":[[2,\"Autoškola \"],[1,[35,0,[\"name\"]]]],\"parameters\":[]}]]],[2,\" \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\\t\"],[1,[34,3]],[2,\"\\n\\n\\t\\t\\t\"],[10,\"section\"],[14,0,\"content layout-column flex\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"if\",\"inviteToken\",\"paper-divider\",\"-outlet\",\"component\",\"page/page-card\",\"isDuplicate\",\"currentStepIndex\",\"register/driving-school/components/register-stepper\",\"not\",\"isCompleted\",\"and\",\"page/page-header\",\"page/page-content\"]}",
    "meta": {
      "moduleName": "soferuj/modules/register/driving-school/template.hbs"
    }
  });
  _exports.default = _default;
});