define("soferuj/modules/test/components/user-ranking/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "716x0H8F",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ranking\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"value\"],[12],[2,\"  \\n\"],[6,[37,8],[[35,1,[\"ranking\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,7],[[30,[36,6],[[35,1,[\"ranking\"]],[35,5]],null],[30,[36,4],[[35,1,[\"ranking\"]],\".\"],null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      ? \\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[1,[30,[36,9],[[30,[36,8],[[30,[36,6],[[35,1,[\"ranking\"]],[35,5]],null],\"medal\",\"certificate\"],null]],[[\"class\"],[[30,[36,4],[\"ranking-icon ranking-icon-\",[35,1,[\"ranking\"]]],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"content flex layout-column layout-gt-\",[34,0],\"-row layout-align-gt-\",[34,0],\"-space-between-center\"]]],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[34,10]],[13],[2,\"\\n\\n\"],[6,[37,8],[[35,1,[\"ranking\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"stats \",[34,0],\" layout-row\"]]],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"points\"],[12],[1,[30,[36,2],[[35,1,[\"rating\"]]],null]],[2,\" bodov\"],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"item\"],[12],[1,[30,[36,2],[[30,[36,3],[[35,1,[\"test_count\"]],[35,1,[\"testCount\"]]],null]],null]],[2,\" testov\"],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"item\"],[12],[1,[30,[36,2],[[30,[36,3],[[35,1,[\"average_points\"]],[35,1,[\"averagePoints\"]]],null]],null]],[2,\" priemer. počet bodov\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"breakpoint\",\"ranking\",\"round\",\"or\",\"concat\",\"medalRanks\",\"contains\",\"unless\",\"if\",\"fa-icon\",\"title\"]}",
    "meta": {
      "moduleName": "soferuj/modules/test/components/user-ranking/template.hbs"
    }
  });
  _exports.default = _default;
});