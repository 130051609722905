define("soferuj/modules/components/soferuj-star-rating/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IyqllpXc",
    "block": "{\"symbols\":[\"@class\",\"@rating\",\"@size\",\"@editable\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,1],[\"soferuj-star-rating rating-\",[30,[36,0],[[32,2]],null],\"-stars hover-\",[32,0,[\"hoverStarsCount\"]],\"-stars \",[32,1]],null]],[12],[2,\"\\n  \"],[8,\"star-rating\",[],[[\"@rating\",\"@useHalfStars\",\"@width\",\"@height\",\"@readOnly\",\"@onHover\",\"@onClick\"],[[32,2],false,[30,[36,2],[[32,3],26],null],[30,[36,2],[[32,3],26],null],[30,[36,3],[[32,4]],null],[32,0,[\"onHover\"]],[32,0,[\"onClick\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"round\",\"concat\",\"or\",\"not\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/soferuj-star-rating/template.hbs"
    }
  });
  _exports.default = _default;
});