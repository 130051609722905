define("soferuj/modules/driving-school/reviews/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const ITEMS_PER_PAGE = 2;
  let DrivingSchoolReviews = (_dec = Ember.inject.controller, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class DrivingSchoolReviews extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "drivingSchool", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "limit", _descriptor4, this);
      _defineProperty(this, "sortOptions", [{
        name: 'najrelevantnejších',
        orderBy: 'relevance'
      }, {
        name: 'najnovších',
        orderBy: 'published_at',
        order: 'desc'
      }, {
        name: 'najvyššieho hodnotenia',
        orderBy: 'rating',
        order: 'desc'
      }, {
        name: 'najnižšieho hodnotenia',
        orderBy: 'rating',
        order: 'asc'
      }]);
      _initializerDefineProperty(this, "sorting", _descriptor5, this);
      _initializerDefineProperty(this, "activeReview", _descriptor6, this);
      _initializerDefineProperty(this, "responseDialogOpen", _descriptor7, this);
    }
    get hasReviews() {
      return !!this.model.drivingSchool.reviewCount;
    }
    get allReviewsLoaded() {
      return this.model.drivingSchool.reviewCount === this.model.reviews.length;
    }
    createReview() {
      this.drivingSchool.createReview();
    }
    openResponseDialog(review) {
      this.activeReview = review;
      this.responseDialogOpen = true;
    }
    closeResponseDialog() {
      this.activeReview = null;
      this.responseDialogOpen = false;
    }
    changeSorting(sorting) {
      this.sorting = sorting;
      this.limit = ITEMS_PER_PAGE;
      this.send('refreshModel');
    }
    loadNext() {
      this.limit += ITEMS_PER_PAGE;
      this.send('refreshModel');
    }
    likeReview(id) {
      if (!this.drivingSchool.hasAssignedSchool) {
        this.drivingSchool.assignSchoolFromReviewVote();
        return;
      }
      this.ajax.post(`/reviews/${id}/like`).then(data => this.store.pushPayload(data));
    }
    dislikeReview(id) {
      if (!this.drivingSchool.hasAssignedSchool) {
        this.drivingSchool.assignSchoolFromReviewVote();
        return;
      }
      this.ajax.post(`reviews/${id}/dislike`).then(data => this.store.pushPayload(data));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "drivingSchool", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ITEMS_PER_PAGE;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sorting", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.sortOptions[0];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "activeReview", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "responseDialogOpen", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "createReview", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "createReview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openResponseDialog", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "openResponseDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeResponseDialog", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "closeResponseDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSorting", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "changeSorting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadNext", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "loadNext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "likeReview", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "likeReview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dislikeReview", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "dislikeReview"), _class.prototype)), _class));
  _exports.default = DrivingSchoolReviews;
});