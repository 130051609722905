define("soferuj/models/course/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    category: {
      description: 'Kategória kurzu',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      })]
    },
    type: {
      description: 'Typ kurzu',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      })]
    },
    duration: {
      description: 'Trvanie kurzu',
      validators: [(0, _emberCpValidations.validator)('course-duration')]
    },
    price: {
      description: 'Cena kurzu',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        gte: 20,
        lte: 2000
      })]
    }
    //drivingSchool: validator('belongs-to')
  }, {
    debounce: 200
  });
  var _default = Validations;
  _exports.default = _default;
});