define("soferuj/modules/test/statistics/controller", ["exports", "ember-cli-pagination/computed/paged-array", "moment"], function (_exports, _pagedArray, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    identity: Ember.inject.service(),
    type: 'ab',
    summaryFilters: {
      type: {
        name: 'Všetky testy'
      },
      date: {
        type: 'last-14-days',
        name: 'Posledných 14 dní'
      }
    },
    dailySummaryTypeFilter: {
      name: 'Všetky testy'
    },
    wrongQuestionsTypeFilter: null,
    medalRanks: [1, 2, 3],
    types: [{
      name: 'Všetky testy'
    }, {
      type: 'training',
      name: 'Cvičné testy'
    }, {
      type: 'official',
      name: 'Oficiálne testy'
    }],
    timeRanges: [{
      type: 'last-14-days',
      name: 'Posledných 14 dní'
    }, {
      type: 'overall',
      name: 'Celkovo'
    }],
    openedQuestion: null,
    chart: Ember.computed('model.summary', function () {
      return {
        data: {
          labels: ['Úspešných testov', 'Neúspešných testov'],
          datasets: [{
            data: [this.model.summary.good_count, this.model.summary.bad_count],
            backgroundColor: ['#4caf50', '#f44336'],
            borderWidth: 3
          }]
        },
        options: {
          cutoutPercentage: 75,
          legend: {
            display: false
          }
        }
      };
    }),
    activityChart: Ember.computed('model.daily_summary', function () {
      const dailySummary = this.model.dailySummary;
      return {
        data: {
          labels: dailySummary.map(summary => new Date(summary.day)),
          datasets: [{
            label: 'Úspešnosť',
            type: 'line',
            data: dailySummary.map(summary => summary.success_percentage),
            yAxisID: 'summary',
            borderColor: '#4cafe8',
            fill: false,
            borderWidth: 2
          }, {
            label: 'Absolvovaných testov',
            data: dailySummary.map(summary => summary.all_count),
            yAxisID: 'main',
            backgroundColor: 'silver'
          }, {
            label: 'Úspešných testov',
            data: dailySummary.map(summary => summary.good_count),
            yAxisID: 'main',
            backgroundColor: '#4caf50'
          }, {
            label: 'Neúspešných testov',
            data: dailySummary.map(summary => summary.bad_count),
            yAxisID: 'main',
            backgroundColor: '#f44336'
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              type: 'time',
              time: {
                unit: 'day',
                tooltipFormat: 'D. MMM YYYY',
                displayFormats: {
                  day: 'D. MMM'
                },
                max: (0, _moment.default)().hours(12),
                min: (0, _moment.default)().subtract(14, 'days').startOf('day')
              }
            }],
            yAxes: [{
              id: 'main',
              stacked: true
            }, {
              id: 'summary',
              display: false
            }]
          },
          tooltips: {
            mode: 'index',
            intersect: true,
            callbacks: {
              label: function (tooltipItem, data) {
                const suffix = tooltipItem.datasetIndex === 0 ? '%' : '';
                return Chart.defaults.global.tooltips.callbacks.label(tooltipItem, data) + suffix;
              }
            }
          }
        }
      };
    }),
    stranka: 1,
    naStranku: 10,
    pagedWrongQuestions: (0, _pagedArray.default)('filteredWrongQuestions', {
      page: Ember.computed.alias('parent.stranka'),
      perPage: Ember.computed.alias('parent.naStranku')
    }),
    totalPages: Ember.computed.oneWay('pagedWrongQuestions.totalPages'),
    filteredWrongQuestions: Ember.computed('model.wrong_questions', 'wrongQuestionsTypeFilter', function () {
      const type = this.wrongQuestionsTypeFilter;
      if (!type) return this.model.wrongQuestions;
      return this.model.wrongQuestions.filter(question => question.question_type.name === type);
    }),
    actions: {
      changeType(type) {
        this.set('type', type);
        this.send('refreshModel');
      },
      changeSummaryFilter(type, value) {
        this.set(`summaryFilters.${type}`, value);
        this.send('refreshModel');
      },
      changeDailySummaryFilter(value) {
        this.set('dailySummaryTypeFilter', value);
        this.send('refreshModel');
      },
      openQuestion(question) {
        let openedQuestion = this.openedQuestion;
        if (openedQuestion && openedQuestion === question) {
          this.set('openedQuestion', null);
          return;
        }
        this.set('openedQuestion', question);
      },
      wrongQuestionCountByType(type) {
        return this.model.wrongQuestions.filter(question => question.question_type.name === type).length;
      },
      markQuestion() {
        return;
      }
    }
  });
  _exports.default = _default;
});