define("soferuj/modules/login/recovery/index/controller", ["exports", "soferuj/modules/login/recovery/index/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_validations.default, {
    ajax: Ember.inject.service('ajax'),
    paperToaster: Ember.inject.service(),
    actions: {
      reset() {
        this.set('isSubmitted', true);
        if (this.get('validations.isValid')) {
          this.ajax.post('reset-password', {
            data: {
              email: this.email
            }
          }).then(() => {
            this.set("email", null);
            this.paperToaster.show('Skontrolujte si emailovu schránku.');
          });
        }
      }
    }
  });
  _exports.default = _default;
});