define("ember-paper/utils/contrasts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable */
  // Auto Generated file from exportSass.
  var _default = {
    "light-contrast-color": "rgba(255, 255, 255, 0.87)",
    "dark-contrast-color": "rgba(0, 0, 0, 0.87)",
    "strong-light-contrast-color": "rgb(255, 255, 255)"
  };
  _exports.default = _default;
});