define("soferuj/modules/login/recovery/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7c2xaybe",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[6,[37,9],null,[[\"class\"],[\"route-login\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"login-register-container layout-column\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"header\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"strong\"],[12],[2,\"Resetovanie hesla\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"small\"],[12],[2,\"Na email ti zašleme ďalšie pokyny\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\n\"],[6,[37,7],null,[[\"class\",\"isTouched\",\"onSubmit\"],[\"layout-column\",[30,[36,6],[[35,5]],null],[30,[36,2],[[32,0],\"reset\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,4],[[32,1,[\"input\"]]],[[\"label\",\"value\",\"errors\",\"onChange\"],[\"Email\",[35,0],[35,3,[\"attrs\",\"email\",\"messages\"]],[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null]]]]],[2,\"\\n\\n\\t\\t\\t\\t\"],[6,[37,4],[[32,1,[\"submit-button\"]]],[[\"primary\",\"raised\",\"class\"],[true,true,\"flex\"]],[[\"default\"],[{\"statements\":[[2,\"Zresetovať heslo\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[6,[37,8],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Spomenul som si na heslo\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"email\",\"mut\",\"action\",\"validations\",\"component\",\"isSubmitted\",\"readonly\",\"paper-form\",\"link-to\",\"page/page-content\"]}",
    "meta": {
      "moduleName": "soferuj/modules/login/recovery/index/template.hbs"
    }
  });
  _exports.default = _default;
});