define("soferuj/modules/driving-school/components/editable-dialog/licence-types/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dEL9Ndnz",
    "block": "{\"symbols\":[\"licenceType\",\"controls\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"edit-licence-types\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"licenceTypes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"paper-item\",[[24,0,\"licence-type-item\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"b\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"md-secondary-container\"],[12],[2,\"\\n        \"],[8,[32,2,[\"checkbox\"]],[],[[\"@value\",\"@onChange\"],[[30,[36,0],[[32,1],[32,3,[\"licenceTypes\"]]],null],[30,[36,1],[[32,0,[\"toggleLicenceType\"]],[32,1]],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"  \\n\"]],\"hasEval\":false,\"upvars\":[\"contains\",\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/components/editable-dialog/licence-types/template.hbs"
    }
  });
  _exports.default = _default;
});