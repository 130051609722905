define("ember-paper/components/paper-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HQfM2BuV",
    "block": "{\"symbols\":[\"dd\",\"@disabled\",\"&attrs\",\"&default\"],\"statements\":[[8,\"basic-dropdown\",[[17,3]],[[\"@disabled\",\"@triggerComponent\",\"@contentComponent\",\"@calculatePosition\",\"@onClose\",\"@onOpen\"],[[32,2],\"paper-menu/trigger\",\"paper-menu/content\",[32,0,[\"calculatePosition\"]],[32,0,[\"close\"]],[32,0,[\"open\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,4,[[30,[36,0],null,[[\"trigger\",\"content\"],[[32,1,[\"Trigger\"]],[32,1,[\"Content\"]]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-menu/template.hbs"
    }
  });
  _exports.default = _default;
});