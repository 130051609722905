define("soferuj/modules/driving-school/components/editable-dialog/component", ["exports", "@glimmer/component", "soferuj/modules/driving-school/components/editable-dialog/editable-section.enum"], function (_exports, _component, _editableSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PROPS = {
    [_editableSection.EDITABLE_SECTION.SCHOOL_NAME]: {
      title: 'názov autoškoly'
    },
    [_editableSection.EDITABLE_SECTION.DESCRIPTION]: {
      title: 'popis autoškoly',
      sizeClass: 'size-lg'
    },
    [_editableSection.EDITABLE_SECTION.SERVICES_LIST]: {
      title: 'ponúkané služby'
    },
    [_editableSection.EDITABLE_SECTION.LICENCE_TYPES]: {
      title: 'kurzy ktoré vyučujete'
    },
    [_editableSection.EDITABLE_SECTION.LANGUAGES]: {
      title: 'vyučované jazyky'
    },
    [_editableSection.EDITABLE_SECTION.CONTACT]: {
      title: 'kontaktné informácie'
    },
    [_editableSection.EDITABLE_SECTION.ADDRESS]: {
      title: 'adresu'
    },
    [_editableSection.EDITABLE_SECTION.LOCATION]: {
      title: '',
      sizeClass: 'size-lg'
    }
  };
  let EditableDialogTrigger = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class EditableDialogTrigger extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      //@tracked isDeleting = false;
      _defineProperty(this, "props", PROPS[this.args.sectionName]);
      _defineProperty(this, "languagesBackup", this.args.model.languages.toArray());
      _defineProperty(this, "licenceTypesBackup", this.args.model.licenceTypes.toArray());
    }
    editCancel() {
      const model = this.args.model;
      model.rollbackAttributes();
      // for better relations reset https://gist.github.com/shamcode/7fecbfb3d1ac65f1565e2b012aaf796d
      this.args.model.languages.clear().pushObjects(this.languagesBackup);
      this.args.model.licenceTypes.clear().pushObjects(this.licenceTypesBackup);
      this.args.onClose();
    }
    delete() {
      const model = this.args.model;
      model.destroyRecord(); // TODO: doesnt remove from store, error in ember data
    }

    save() {
      const model = this.args.model;
      if (model.get('validations.isValid')) {
        model.save().then(() => this.args.onClose());
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "editCancel", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "editCancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = EditableDialogTrigger;
});