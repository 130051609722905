define("soferuj/modules/register/driving-school/components/register-stepper/register-step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nAqrAaF3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"line\"],[12],[13],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[30,[36,8],[[35,7],[35,6]],null],[30,[36,5],[[35,4]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[6,[37,3],null,[[\"route\"],[[30,[36,2],[\"register.driving-school.\",[35,1]],null]]],[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"link\",\"concat\",\"link-to\",\"isDuplicate\",\"not\",\"lastValidStep\",\"step\",\"lte\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/register/driving-school/components/register-stepper/register-step/template.hbs"
    }
  });
  _exports.default = _default;
});