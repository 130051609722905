define("soferuj/modules/driving-school/components/editable-dialog/editable-section.enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EDITABLE_SECTION = void 0;
  let EDITABLE_SECTION;
  _exports.EDITABLE_SECTION = EDITABLE_SECTION;
  (function (EDITABLE_SECTION) {
    EDITABLE_SECTION["SCHOOL_NAME"] = "school-name";
    EDITABLE_SECTION["DESCRIPTION"] = "description";
    EDITABLE_SECTION["SERVICES_LIST"] = "services-list";
    EDITABLE_SECTION["LICENCE_TYPES"] = "licence-types";
    EDITABLE_SECTION["LANGUAGES"] = "languages";
    EDITABLE_SECTION["CONTACT"] = "contact";
    EDITABLE_SECTION["ADDRESS"] = "address";
    EDITABLE_SECTION["LOCATION"] = "location";
  })(EDITABLE_SECTION || (_exports.EDITABLE_SECTION = EDITABLE_SECTION = {}));
});