define("soferuj/modules/components/page/page-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jPyoHbHv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"layout-align-center-center layout-row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[15,0,[31,[\"page-card \",[34,1],\" \",[34,0]]]],[12],[2,\"\\n\\t\\t\"],[18,1,null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"size\",\"class\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/page/page-card/template.hbs"
    }
  });
  _exports.default = _default;
});