define("soferuj/modules/test/user-rankings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model() {
      return this.ajax.request('stats/user-ranking');
    }
  });
  _exports.default = _default;
});