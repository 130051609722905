define("soferuj/modules/register/user/route", ["exports", "soferuj/mixins/unauthenticated-route"], function (_exports, _unauthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_unauthenticatedRoute.default, {
    titleToken: 'Registrácia používateľa',
    model() {
      return this.createRecord();
    },
    createRecord() {
      let user = this.store.createRecord('user');
      user.set('type', 'user');
      user.set('sendNews', true);
      return user;
    },
    actions: {
      resetModel() {
        this.controller.set('model', this.createRecord());
      },
      willTransition() {
        this.controller.get('model').rollbackAttributes();
      }
    }
  });
  _exports.default = _default;
});