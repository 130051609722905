define("soferuj/helpers/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.errors = errors;
  function errors(_ref) {
    let [error] = _ref;
    if (!error) return;
    return [{
      message: error.validation
    }];
  }
  var _default = Ember.Helper.helper(errors);
  _exports.default = _default;
});