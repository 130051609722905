define("soferuj/router", ["exports", "ember-cli-analytics/mixins/trackable", "ember-router-scroll", "soferuj/config/environment"], function (_exports, _trackable, _emberRouterScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend(_trackable.default, _emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('driving-schools', {
      path: '/autoskoly'
    }, function () {
      this.route('index', {
        path: '/'
      });
      this.route('location', {
        path: ':location'
      });
    });
    this.route('driving-school', {
      path: '/autoskola/:drivingSchoolUrl'
    }, function () {
      this.route('contact', {
        path: '/kontakt'
      });
      this.route('pricing', {
        path: '/kurzy'
      });
      this.route('reviews', {
        path: '/hodnotenia'
      });
      this.route('user-application', {
        path: '/prihlaska-do-kurzu'
      });
    });
    this.route('test', {
      path: '/testy'
    }, function () {
      this.route('official', {
        path: '/oficialny'
      }, function () {
        this.route('number', {
          path: ':testNumber'
        });
      });
      this.route('training', {
        path: '/cvicny'
      }, function () {
        this.route('number', {
          path: ':testNumber'
        });
      });
      this.route('history', {
        path: '/historia'
      }, function () {
        this.route('test', {
          path: '/:testId'
        });
      });
      this.route('statistics', {
        path: '/statistika'
      });
      this.route('user-rankings', {
        path: '/top-10'
      });
    });
    this.route('contact', {
      path: '/kontakt'
    });
    this.route('login', {
      path: '/prihlasenie'
    }, function () {
      this.route('activation', {
        path: '/aktivacia/:token'
      });
      this.route('recovery', {
        path: '/obnova-hesla'
      }, function () {
        this.route('new-password', {
          path: '/nove-heslo/:token'
        });
      });
    });
    this.route('register', {
      path: '/registracia'
    }, function () {
      this.route('user', {
        path: '/pouzivatel'
      });
      this.route('driving-school', {
        path: '/autoskola'
      }, function () {
        this.route('basic-info', {
          path: '/zakladne-informacie'
        });
        this.route('contact', {
          path: '/kontakt'
        });
        this.route('profile-picture', {
          path: '/profilova-fotka'
        });
        this.route('finish', {
          path: '/dokoncenie'
        });
      });
    });
    this.route('settings', {
      path: '/nastavenia'
    }, function () {
      this.route('my-schools', {
        path: '/moje-autoskoly'
      });
    });
    this.route('info', {
      path: '/informacie'
    }, function () {
      this.route('licence-types', {
        path: '/skupiny-vodicakov'
      });
      this.route('road-signs', {
        path: '/dopravne-znacky'
      });
      this.route('fines', {
        path: '/pokuty'
      });
      this.route('safe-distance', {
        path: '/bezpecna-vzdialenost'
      });
      this.route('vehicle-usage', {
        path: '/konstrukcia-udzba-a-vybava-vozidla'
      });
      this.route('first-aid', {
        path: 'kurz-prvej-pomoci'
      });
      this.route('final-exam', {
        path: 'zaverecna-skuska'
      });
      this.route('car-accident', {
        path: 'ako-postupovat-pri-nehode'
      });
      this.route('choose-school', {
        path: 'ako-si-vybrat-autoskolu'
      });
    });
    this.route('pages', {
      path: '/stranky'
    }, function () {
      this.route('cookies');
      this.route('conditions', {
        path: '/vseobecne-podmienky'
      });
      this.route('personal-data', {
        path: '/ochrana-osobnych-udajov'
      });
    });
    this.route('not-found', {
      path: '/*path'
    });
    this.route('notFound');
    this.route('admin', function () {
      this.route('reviews');
    });
    this.route('for-vehicle', {
      path: '/pre-vozidlo'
    }, function () {
      this.route('history', {
        path: 'kontrola-historie-vozidla'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});