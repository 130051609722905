define("soferuj/services/identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Service.extend((_dec = Ember.computed('loggedInUser'), (_obj = {
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    isFetched: false,
    loggedInUser: null,
    init() {
      this._super(...arguments);
    },
    get user() {
      return this.loggedInUser ? this.store.peekRecord('user', this.loggedInUser.id) : undefined;
    },
    isAuthenticated: Ember.computed('user', function () {
      return !!this.user;
    }),
    async load() {
      return await this.store.queryRecord('user', {
        me: true,
        include: 'drivingSchools'
      }).then(user => {
        this.set('loggedInUser', user);
        this.set('isFetched', true);
      }).catch(() => this.set('isFetched', true));
    },
    login(username, password) {
      return this.ajax.post('login', {
        data: {
          username,
          password
        }
      });
    },
    logout() {
      return this.ajax.post('logout').then(() => {
        this.set('loggedInUser', null);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "user", [_dec], Object.getOwnPropertyDescriptor(_obj, "user"), _obj)), _obj)));
  _exports.default = _default;
});