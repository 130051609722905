define("ember-google-maps/components/g-map", ["exports", "ember-google-maps/templates/components/g-map", "ember-google-maps/utils/options-and-events", "ember-google-maps/utils/helpers", "ember-concurrency"], function (_exports, _gMap, _optionsAndEvents, _helpers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function safeScheduleOnce(queue, context, onSuccess, onError) {
    function func() {
      if (context.isDestroying || context.isDestroyed) {
        onError.call(context);
      } else {
        onSuccess.call(context);
      }
    }
    Ember.run.scheduleOnce(queue, context, func);
  }
  function skipErrorReporting() {}
  function GMapAPI(source) {
    return {
      get id() {
        return Ember.get(source, 'mapId');
      },
      get map() {
        return source.map;
      },
      get components() {
        return source.components;
      },
      actions: {
        update: () => source._updateMap(),
        trigger: () => source._trigger()
      }
    };
  }

  /**
   * @class GMap
   * @module ember-google-maps/components/g-map
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * @property googleMapsApi
     * @type GoogleMapsApi
     * @readOnly
     */
    googleMapsApi: Ember.inject.service(),
    fastboot: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.lookup('service:fastboot');
    }),
    layout: _gMap.default,
    tagName: '',
    /**
     * Zoom level for the map
     *
     * @property zoom
     * @type {Number}
     * @default 8
     * @public
     */
    zoom: 8,
    /**
     * The latitude and longitude of the center of the map.
     *
     * @property center
     * @type {google.maps.LatLng}
     * @public
     */
    center: Ember.computed('lat', 'lng', _helpers.position),
    google: Ember.computed.reads('googleMapsApi.google'),
    mapComponent: Ember.computed.reads('map'),
    /**
     * A unique id for the current map instance.
     *
     * @property mapId
     * @type {String}
     * @public
     */
    mapId: Ember.computed(function () {
      return `ember-google-map-${Ember.guidFor(this)}`;
    }),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)(),
    _options: Ember.computed.readOnly('_optionsAndEvents.options'),
    _events: Ember.computed.readOnly('_optionsAndEvents.events'),
    _createOptions(options) {
      return _objectSpread(_objectSpread({}, options), {}, {
        center: Ember.get(this, 'center'),
        zoom: Ember.get(this, 'zoom')
      });
    },
    _createEvents(events) {
      return events;
    },
    init() {
      this._super(...arguments);
      this.components = {};
      this.gMap = {};
      this.publicAPI = GMapAPI(this);
      this._internalAPI = {
        _registerCanvas: this._registerCanvas.bind(this),
        _registerComponent: this._registerComponent.bind(this),
        _unregisterComponent: this._unregisterComponent.bind(this)
      };
      this._canvasIsRendering = Ember.RSVP.defer();
      this._eventListeners = new Map();
      Ember.get(this, '_initMap').perform();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      if (Ember.get(this, 'map')) {
        let options = this._createOptions(Ember.get(this, '_options'));
        this._updateMap(options);
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      this._eventListeners.forEach(remove => remove());
    },
    /**
     * Initialize the map, register events and prep internal components.
     *
     * @method _initMap
     * @private
     * @return
     */
    _initMap: (0, _emberConcurrency.task)(function* () {
      yield Ember.get(this, 'google');

      // After google loads, we need to wait for Ember to update any values read
      // directly from the google object and used in templates. For example, map
      // controls may be positioned with `ControlPosition.TOP_LEFT`, the value of
      // which is only available once google loads.
      yield this._waitForNextRunloop();
      let canvas = yield this._canvasIsRendering.promise;
      let options = this._createOptions(Ember.get(this, '_options'));
      let map = new google.maps.Map(canvas, options);
      function waitForComponents() {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this._waitForComponents().then(() => {
          var _this$onComponentsLoa;
          this._componentsInitialized = true;
          (_this$onComponentsLoa = this.onComponentsLoad) === null || _this$onComponentsLoa === void 0 ? void 0 : _this$onComponentsLoa.call(this, this.publicAPI);
        });
      }
      function setupMap() {
        var _this$onLoad;
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        Ember.set(this, 'map', map);
        let payload = {
          map: this.map,
          publicAPI: this.publicAPI
        };
        (0, _optionsAndEvents.addEventListeners)(map, this._createEvents(Ember.get(this, '_events')), payload).forEach(_ref => {
          let {
            name,
            remove
          } = _ref;
          return this._eventListeners.set(name, remove);
        });
        (_this$onLoad = this.onLoad) === null || _this$onLoad === void 0 ? void 0 : _this$onLoad.call(this, this.publicAPI);
        safeScheduleOnce('afterRender', this, waitForComponents, skipErrorReporting);
      }
      google.maps.event.addListenerOnce(map, 'idle', Ember.run.bind(this, setupMap));
    }),
    _waitForComponents() {
      let componentsAreInitialized = Object.keys(this.components).map(name => this.components[name]).reduce((array, componentGroup) => array.concat(componentGroup), []).map(components => Ember.get(components, 'isInitialized.promise'));
      return Ember.RSVP.all(componentsAreInitialized);
    },
    _waitForNextRunloop() {
      return new Promise(resolve => {
        safeScheduleOnce('actions', this, resolve, skipErrorReporting);
      });
    },
    /**
     * Update the map options.
     *
     * @method _updateMap
     * @return
     */
    _updateMap(options) {
      Ember.get(this, 'map').setOptions(options);
    },
    /**
     * Helper method to trigger Google Maps events.
     *
     * @method _trigger
     * @param {String} event Event name
     * @return
     */
    _trigger() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      google.maps.event.trigger(Ember.get(this, 'map'), ...args);
    },
    _registerCanvas(canvas) {
      Ember.set(this, 'canvas', canvas);
      this._canvasIsRendering.resolve(canvas);
    },
    /**
     * Register a contextual component with the map component.
     *
     * @method _registerComponent
     * @param {String} type Plural name of the component
     * @param {Object} componentAPI
     * @return
     */
    _registerComponent(type, componentAPI) {
      this.components[type] = this.components[type] || Ember.A();
      this.components[type].pushObject(componentAPI);
    },
    /**
     * Unregister a contextual component with the map component.
     *
     * @method _unregisterComponent
     * @param {String} type Name of the component
     * @param {Object} componentAPI
     * @return
     */
    _unregisterComponent(type, componentAPI) {
      this.components[type].removeObject(componentAPI);
    },
    _updateGMap() {
      for (var _len2 = arguments.length, props = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        props[_key2] = arguments[_key2];
      }
      Ember.setProperties(this.gMap, Object.assign({}, ...props));
    }
  });
  _exports.default = _default;
});