define("soferuj/modules/application/components/scroll-up/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      const $scrollUp = $('.scroll-up-button');
      $(window).scroll(function () {
        if ($(this).scrollTop() > 500 && $scrollUp.is(':hidden')) {
          $scrollUp.fadeIn();
        }
        if ($(this).scrollTop() < 500 && $scrollUp.is(':visible')) {
          $scrollUp.fadeOut();
        }
      });
    },
    // TODO: create reusable action
    actions: {
      scrollUp() {
        const content = document.getElementById('main-toolbar');
        if (!!content && content.scrollIntoView) {
          content.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }
    }
  });
  _exports.default = _default;
});