define("ember-composable-helpers/helpers/reverse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [array] = _ref;
      if (!Ember.isArray(array)) {
        return [array];
      }
      Ember.set(this, 'array', array);
      return Ember.A(array).slice(0).reverse();
    },
    arrayContentDidChange: Ember.observer('array.[]', function () {
      this.recompute();
    })
  });
  _exports.default = _default;
});