define("soferuj/modules/driving-school/index/components/addition-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EQxFvF1H",
    "block": "{\"symbols\":[\"@title\"],\"statements\":[[8,\"paper-item\",[[24,0,\"service-list-item\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[12],[1,[32,1]],[13],[2,\"\\n  \"],[10,\"span\"],[15,0,[32,0,[\"props\",\"class\"]]],[12],[1,[32,0,[\"props\",\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/index/components/addition-item/template.hbs"
    }
  });
  _exports.default = _default;
});