define("soferuj/modules/driving-school/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    titleToken: function (model) {
      return 'Autoškola ' + (model ? model.get('name') : 'nebola nájdená');
    },
    model: async function (params) {
      return this.store.queryRecord('driving-school', {
        slug: params.drivingSchoolUrl,
        include: 'languages,licenceTypes'
      }).catch(e => null);
    },
    actions: {
      didTransition: function () {
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.controller.set('preserveScrollPosition', true);
        });
      }
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('preserveScrollPosition', false);
        controller.set('isEditing', false);
      }
    }
  });
  _exports.default = _default;
});