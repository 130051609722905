define("soferuj/modules/contact/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'email.username': (0, _emberCpValidations.validator)('presence', true),
    'email.email': {
      description: 'Email',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })]
    },
    'email.message': {
      description: 'Text',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 15
      })]
    }
  });
  var _default = Validations;
  _exports.default = _default;
});