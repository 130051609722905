define("soferuj/modules/settings/index/info-form/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    paperToaster: Ember.inject.service(),
    serverErrors: {},
    isTouched: Ember.computed.or('serverErrors.{email,username,password}'),
    emailErrors: Ember.computed.union('model.validations.attrs.email.messages', 'serverErrors.email'),
    usernameErrors: Ember.computed.union('model.validations.attrs.username.messages', 'serverErrors.username'),
    actions: {
      removeErrors(prop) {
        if (this.get(`serverErrors.${prop}`)) {
          let errors = _lodash.default.omit(this.serverErrors, prop);
          this.set('serverErrors', errors);
        }
      },
      updateProfile() {
        let model = this.model;
        let fields = _lodash.default.pick(model.toJSON(), ['email', 'firstName', 'lastName', 'username', 'email', 'sendNews']);
        this.ajax.patch('me', {
          data: _lodash.default.merge(fields, {
            password: this.password
          })
        }).then(() => {
          this.paperToaster.show('Údaje boli zmenené');
          this.set('password', null);
        }).catch(_ref => {
          let {
            payload: {
              errors
            }
          } = _ref;
          let serverErrors = {};

          // TODO: some translator service, or api should send it translated
          if (errors.email) {
            serverErrors['email'] = ['Používateľ s takým emailom už existuje'];
          }
          if (errors.username) {
            serverErrors['username'] = ['Takéto používateľské meno už existuje'];
          }
          this.set('password', null);
          this.set('serverErrors', serverErrors);
        });
      }
    }
  });
  _exports.default = _default;
});