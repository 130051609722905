define("soferuj/modules/driving-school/components/editable-dialog/school-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "z2u0na44",
    "block": "{\"symbols\":[\"@model\",\"@onUpdateModel\"],\"statements\":[[8,\"paper-input\",[],[[\"@block\",\"@value\",\"@placeholder\",\"@required\",\"@errorMessages\",\"@onChange\"],[true,[32,1,[\"name\"]],\"Názov autoškoly\",true,[30,[36,0],null,[[\"required\"],[\"Názov je povinný.\"]]],[30,[36,1],[[32,2],\"name\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"hash\",\"fn\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/components/editable-dialog/school-name/template.hbs"
    }
  });
  _exports.default = _default;
});