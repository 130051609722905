define("soferuj/modules/components/edit-profile-picture-dialog/component", ["exports", "@glimmer/component", "soferuj/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3; // @ts-ignore
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let EditProfilePictureDialog = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class EditProfilePictureDialog extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isCropping", _descriptor, this);
      _initializerDefineProperty(this, "identity", _descriptor2, this);
      _initializerDefineProperty(this, "ajax", _descriptor3, this);
    }
    get baseUrl() {
      return this.args.isDrivingSchool ? `driving-schools/${this.args.model.id}` : 'me';
    }
    onUploadPicture() {
      const input = document.getElementById('upload-profile-picture');
      if (input) input.click();
    }
    onSaveTmpPicture(event) {
      const data = event.target.files[0];
      var formData = new FormData();
      formData.append('profile_picture', data);

      // @ts-ignore
      $.ajax({
        type: 'POST',
        url: `${_environment.default.APP.API_URL}/profile-picture`,
        withCredentials: true,
        processData: false,
        contentType: false,
        dataType: 'json',
        data: formData,
        success: _ref => {
          let {
            data
          } = _ref;
          Ember.run.later(() => {
            this.args.model.profilePicture = data.attributes['profile-picture'];
            this.isCropping = true;
          }, 500);
        }
      });
    }
    onCancel() {
      this.isCropping = !this.isCropping;
      this.removeTempPicture();
      if (this.args.onlyCropp) return this.args.onCropp(null);
    }
    savePhoto(_, editProperties) {
      if (this.args.onlyCropp) return this.args.onCropp(editProperties);
      const {
        model
      } = this.args;
      this.ajax.patch(`${this.baseUrl}/profile-picture`, {
        data: {
          profile_picture: model.profilePicture,
          edit_properties: editProperties
        }
      }).then(_ref2 => {
        let {
          data
        } = _ref2;
        model.profilePicture = data.attributes['profile-picture'];
        this.isCropping = false;
      });
    }
    removeProfilePicture() {
      if (this.args.onlyCropp) {
        this.removeTempPicture();
        return;
      }
      let model = this.args.model;
      this.ajax.delete(`${this.baseUrl}/profile-picture`, {
        data: {
          profile_picture: model.profilePicture
        }
      }).then(() => model.profilePicture = null);
    }
    removeTempPicture() {
      this.ajax.delete('profile-picture', {
        data: {
          profile_picture: this.args.model.profilePicture
        }
      });
      this.args.model.profilePicture = null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isCropping", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "identity", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onUploadPicture", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onUploadPicture"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSaveTmpPicture", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSaveTmpPicture"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancel", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "savePhoto", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "savePhoto"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeProfilePicture", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "removeProfilePicture"), _class.prototype)), _class));
  _exports.default = EditProfilePictureDialog;
});