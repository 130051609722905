define("soferuj/modules/components/page/page-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g8wScHXR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[34,0],\" page-content layout-row layout-padding layout-align-center-center\"]]],[12],[2,\"\\n\\t\"],[10,\"div\"],[15,0,[31,[\"flex-sm-90 flex-gt-sm-80 flex \",[34,1]]]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"class\",\"innerClass\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/page/page-content/template.hbs"
    }
  });
  _exports.default = _default;
});