define("soferuj/validators/course-duration", ["exports", "ember-cp-validations/validators/base", "lodash"], function (_exports, _base, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CourseDuration = _base.default.extend({
    validate(value) {
      const checkKeys = _lodash.default.includes(['hours', 'days', 'months'], _lodash.default.keys(value)[0]);
      return checkKeys && _lodash.default.isNumber(_lodash.default.values(value)[0]) ? true : "Potrebné vyplniť obe hodnoty";
    }
  });
  var _default = CourseDuration;
  _exports.default = _default;
});