define("soferuj/modules/register/driving-school/controller", ["exports", "query-string"], function (_exports, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const steps = ['basic-info', 'contact', 'profile-picture', 'finish'];
  var _default = Ember.Controller.extend({
    preserveScrollPosition: true,
    isCompleted: false,
    isDuplicate: false,
    schoolId: null,
    invitationToken: null,
    profilePictureProperties: null,
    init: function () {
      this._super();
      const {
        drivingSchoolId,
        inviteToken,
        schoolName
      } = _queryString.default.parse(window.location ? window.location.search : '');
      if (inviteToken) {
        this.set('inviteToken', inviteToken);
        this.set('isDuplicate', true); // only to disable back button
        setTimeout(() => this.set('model.name', schoolName), 500);
      }
      if (drivingSchoolId) {
        this.set('schoolId', drivingSchoolId);
      }
    },
    currentStepIndex: Ember.computed('currentStep', function () {
      return steps.indexOf(this.currentStep) + 1;
    }),
    actions: {
      toggleDuplicate() {
        this.toggleProperty('isDuplicate');
      },
      setProfilePictureProperties(properties) {
        this.set('profilePictureProperties', properties);
      },
      setDrivingSchoolOwner(school) {
        this.set('schoolId', school.id);
        this.set('model.name', school.attributes.name);
        this.transitionToRoute(`register.driving-school.finish`);
      },
      previousStep() {
        let routeName = steps[this.currentStepIndex - 2];
        this.transitionToRoute(`register.driving-school.${routeName}`);
      },
      nextStep() {
        let routeName = steps[this.currentStepIndex];
        this.transitionToRoute(`register.driving-school.${routeName}`);
      },
      onCompleted() {
        this.send('resetModel');
        this.set('isCompleted', true);
      }
    }
  });
  _exports.default = _default;
});