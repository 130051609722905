define("soferuj/modules/register/driving-school/contact/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Controller.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    ajax: Ember.inject.service(),
    registration: Ember.inject.controller('register.drivingSchool'),
    restrictions: {
      country: 'sk'
    },
    mapProps: {
      zoom: 7,
      lat: 48.6772234,
      lng: 19.7049394
    },
    locations: [],
    village: Ember.computed('model.address.villageId', function () {
      return this.locations.find(location => location.value === this.get('model.address.villageId'));
    }),
    async init() {
      this._super(...arguments);
      const villagesByRegion = await this.ajax.request('region-villages?schoolsPresent=true');
      const locations = villagesByRegion.reduce(
      // Duplicate
      (locations, region) => {
        const villages = region.villages.map(village => ({
          label: village.name,
          value: village.id,
          slug: village.slug
        }));
        locations = locations.concat([{
          label: region.name,
          value: region.id + '_region',
          slug: region.slug,
          class: 'region',
          disabled: true
        }]).concat(villages);
        return locations;
      }, []);
      this.set('locations', locations);
    },
    villageChanged(option) {
      if (option.disabled) return;
      this.set('model.address.villageId', option.value);
    },
    addressChanged(_ref) {
      let {
        address_components,
        geometry
      } = _ref;
      if (!address_components || !geometry) return;
      const streetNumber = _lodash.default.find(address_components, address => _lodash.default.includes(address.types, 'street_number'));
      const zipCode = _lodash.default.find(address_components, address => _lodash.default.includes(address.types, 'postal_code'));
      const lat = geometry.location.lat();
      const lng = geometry.location.lng();
      const villageName = _lodash.default.find(address_components, address => _lodash.default.intersection(address.types, ['sublocality', 'locality']).length).long_name;
      const village = this.get('locations').find(location => location.label === villageName);
      this.set('model.address', {
        villageId: village ? village.value : null,
        street: _lodash.default.find(address_components, address => _lodash.default.includes(address.types, 'route')).long_name,
        zipCode: zipCode ? zipCode.long_name : '',
        streetNumber: streetNumber ? streetNumber.long_name : '',
        location: {
          x: lat,
          y: lng
        }
      });
      this.map.setZoom(18);
      this.map.panTo({
        lat,
        lng
      });
    },
    markerDragged(_ref2) {
      let {
        googleEvent: event
      } = _ref2;
      this.set('model.address.location', {
        x: event.latLng.lat(),
        y: event.latLng.lng()
      });
    },
    onMapLoad(_ref3) {
      let {
        map
      } = _ref3;
      this.set('map', map);
    },
    submit() {
      this.registration.send('nextStep');
    }
  }, (_applyDecoratedDescriptor(_obj, "villageChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "villageChanged"), _obj), _applyDecoratedDescriptor(_obj, "addressChanged", [_dec2], Object.getOwnPropertyDescriptor(_obj, "addressChanged"), _obj), _applyDecoratedDescriptor(_obj, "markerDragged", [_dec3], Object.getOwnPropertyDescriptor(_obj, "markerDragged"), _obj), _applyDecoratedDescriptor(_obj, "onMapLoad", [_dec4], Object.getOwnPropertyDescriptor(_obj, "onMapLoad"), _obj), _applyDecoratedDescriptor(_obj, "submit", [_dec5], Object.getOwnPropertyDescriptor(_obj, "submit"), _obj)), _obj)));
  _exports.default = _default;
});