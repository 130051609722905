define("soferuj/modules/register/driving-school/components/register-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "apfPoseS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"footer\"],[14,0,\"layout layout-align-space-between-center layout-padding\"],[12],[2,\"\\n\\t\"],[10,\"span\"],[12],[1,[35,0,[\"currentStepIndex\"]]],[2,\" z 4 krokov\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,7],[[30,[36,6],[[35,0,[\"currentStepIndex\"]],1],null],[30,[36,5],[[35,0,[\"isDuplicate\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[6,[37,4],null,[[\"class\",\"onClick\"],[\"small\",[30,[36,3],[[32,0],\"previousStep\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Späť\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,9],[[35,8,[\"submit-button\"]]],[[\"raised\",\"primary\",\"class\"],[true,true,\"small\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[6,[37,2],[[30,[36,1],[[35,0,[\"currentStepIndex\"]],4],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Dokončit\"]],\"parameters\":[]},{\"statements\":[[2,\"Ďalej\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"registration\",\"eq\",\"if\",\"action\",\"paper-button\",\"not\",\"not-eq\",\"and\",\"form\",\"component\"]}",
    "meta": {
      "moduleName": "soferuj/modules/register/driving-school/components/register-footer/template.hbs"
    }
  });
  _exports.default = _default;
});