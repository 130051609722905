define("soferuj/modules/test/statistics/components/not-found-questions/component", ["exports", "soferuj/modules/statics"], function (_exports, _statics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['questions-blank-slate', 'not-found', 'layout-column', 'layout-align-center-center', 'flex-100'],
    questionType: Ember.computed('type', function () {
      return _statics.default.questionsTypeLocal[this.type];
    })
  });
  _exports.default = _default;
});