define("ember-paper/templates/components/paper-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LW7LQid9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"md-tabs-wrapper\"],[15,0,[30,[36,9],[[35,8],\"md-stretch-tabs\"],null]],[12],[2,\"\\n\\n\"],[6,[37,9],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"md-prev-button\"],[14,\"role\",\"button\"],[15,0,[30,[36,4],[[35,3],\"md-disabled\"],null]],[15,\"onclick\",[30,[36,5],[[32,0],\"previousPage\"],null]],[12],[2,\"\\n      \"],[1,[30,[36,6],[\"keyboard-arrow-left\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"md-next-button\"],[14,\"role\",\"button\"],[15,0,[30,[36,4],[[35,7],\"md-disabled\"],null]],[15,\"onclick\",[30,[36,5],[[32,0],\"nextPage\"],null]],[12],[2,\"\\n      \"],[1,[30,[36,6],[\"keyboard-arrow-left\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"md-tabs-canvas\"],[15,0,[31,[[30,[36,9],[[35,10],\"md-paginated\"],null],\" \",[30,[36,9],[[35,11],\"md-center-tabs\"],null]]]],[14,\"tabindex\",\"-1\"],[14,\"role\",\"tablist\"],[12],[2,\"\\n    \"],[10,\"md-pagination-wrapper\"],[15,0,[30,[36,9],[[35,11],\"md-center-tabs\"],null]],[15,5,[30,[36,9],[[35,10],[35,12]],null]],[12],[2,\"\\n\\n      \"],[18,1,[[30,[36,16],null,[[\"tab\"],[[30,[36,15],[\"paper-tab\"],[[\"noInk\",\"selected\",\"onSelect\"],[[35,14],[35,13],[30,[36,5],[[32,0],\"onChange\"],null]]]]]]]]],[2,\"\\n\\n\"],[6,[37,9],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"movingRight\",\"left\",\"right\"],[[35,1],[35,0,[\"left\"]],[35,0,[\"right\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"inkBar\",\"movingRight\",\"paper-ink-bar\",\"canPageBack\",\"unless\",\"action\",\"paper-icon\",\"canPageForward\",\"shouldStretch\",\"if\",\"shouldPaginate\",\"shouldCenter\",\"paginationStyle\",\"selected\",\"noInk\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-tabs.hbs"
    }
  });
  _exports.default = _default;
});