define("soferuj/modules/components/profile-picture-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WuHEzQRk",
    "block": "{\"symbols\":[\"@onEdit\",\"@drivingSchool\",\"@image\",\"@tempPicture\",\"@class\",\"@size\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"profile-picture-new \",[32,6],\" \",[32,5]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"wrapper\"],[12],[2,\"\\n\"],[6,[37,1],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[15,\"src\",[31,[\"/images/tmp/\",[32,3]]]],[14,\"alt\",\"profilová fotka\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"img\"],[15,\"src\",[31,[\"/images/profile_pictures/\",[30,[36,1],[[32,2],\"driving_schools/\",\"users/\"],null],[32,3]]]],[14,\"alt\",\"profilová fotka\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"fa-icon\",[[24,0,\"icon icon-driving-school\"]],[[\"@icon\"],[\"car\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"fa-icon\",[[24,0,\"icon icon-person\"]],[[\"@icon\"],[\"user\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"fa-icon\",[[24,0,\"edit-button\"],[4,[38,0],[\"click\",[32,1]],null]],[[\"@icon\"],[\"pencil-alt\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/profile-picture-new/template.hbs"
    }
  });
  _exports.default = _default;
});