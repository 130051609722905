define("ember-cli-meta-tags/initializers/head-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    // ember 1.13 backwards compatibility
    const application = arguments[1] || arguments[0];
    application.inject('service:head-tags', 'router', 'router:main');
  }
  var _default = {
    name: 'head-tags',
    initialize: initialize
  };
  _exports.default = _default;
});