define("soferuj/modules/settings/index/route", ["exports", "soferuj/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    titleToken: 'Môj účet'
  });
  _exports.default = _default;
});