define("soferuj/modules/driving-school/user-application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DrivingSchoolUserApplication = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.controller, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class DrivingSchoolUserApplication extends Ember.Controller.extend({}) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "identity", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "metrics", _descriptor3, this);
      _initializerDefineProperty(this, "drivingSchool", _descriptor4, this);
      _initializerDefineProperty(this, "agree", _descriptor5, this);
      _initializerDefineProperty(this, "application", _descriptor6, this);
      _initializerDefineProperty(this, "sending", _descriptor7, this);
      _initializerDefineProperty(this, "isSend", _descriptor8, this);
      _defineProperty(this, "emailValidation", [{
        message: 'Nesprávny formát emailu',
        validate: inputValue => {
          let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return emailPattern.test(inputValue);
        }
      }]);
    }
    async init() {
      super.init();
      const user = this.identity.user;
      setTimeout(() => {
        this.application = {
          name: user === null || user === void 0 ? void 0 : user.name,
          email: user === null || user === void 0 ? void 0 : user.email
        };
      }, 200);
    }
    sendApplication() {
      if (!this.application.captcha) {
        return;
      }
      this.sending = true;
      this.ajax.post(`/driving-schools/${this.model.id}/send-application`, {
        data: this.application
      }).then(() => {
        this.metrics.trackEvent({
          category: 'Driving school',
          action: 'application-sent'
        });
        this.isSend = true;
        this.agree = false;
        this.sending = false;
        this.application = {};
      });
    }
    onCaptchaResolved(code) {
      this.application.captcha = code;
    }
    openInviteDialog() {
      this.drivingSchool.toggleInviteDialog();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "identity", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "drivingSchool", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "agree", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "application", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "sending", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isSend", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sendApplication", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "sendApplication"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCaptchaResolved", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onCaptchaResolved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openInviteDialog", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "openInviteDialog"), _class.prototype)), _class));
  _exports.default = DrivingSchoolUserApplication;
});