define("ember-paper/components/paper-menu/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PxaH7LlC",
    "block": "{\"symbols\":[\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\",\"&attrs\",\"&default\"],\"statements\":[[8,\"basic-dropdown-trigger\",[[24,0,\"md-menu\"],[24,\"tabindex\",\"-1\"],[17,5]],[[\"@htmlTag\",\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\"],[\"md-menu\",[32,1],[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,6,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-menu/trigger/template.hbs"
    }
  });
  _exports.default = _default;
});