define("soferuj/modules/test/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    number: (0, _model.attr)(),
    type: (0, _model.attr)(),
    questions: (0, _model.attr)(),
    evaluation: (0, _model.attr)(),
    startedAt: (0, _model.attr)('date'),
    test: (0, _model.attr)() // official or training
  });
  _exports.default = _default;
});