define("soferuj/modules/driving-school/adapter", ["exports", "soferuj/modules/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _adapter.default.extend({
    urlForQueryRecord(_ref, modelName) {
      let {
        slug,
        ...filters
      } = _ref;
      if (slug) {
        let baseUrl = this.buildURL();
        return `${baseUrl}/${modelName}s/${slug}`;
      }
      return this._super(...arguments);
    }
  });
  _exports.default = _default;
});