define("ember-paper/components/paper-autocomplete/no-matches-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZBTukf3Q",
    "block": "{\"symbols\":[\"@onCreate\",\"@noMatchesMessage\"],\"statements\":[[10,\"ul\"],[14,0,\"ember-power-select-options md-autocomplete-suggestions\"],[14,\"role\",\"listbox\"],[12],[2,\"\\n  \"],[11,\"li\"],[24,0,\"ember-power-select-option ember-power-select-option--no-matches-message md-autocomplete-suggestion\"],[24,\"role\",\"option\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-autocomplete/no-matches-message/template.hbs"
    }
  });
  _exports.default = _default;
});