define("soferuj/modules/test/history/test/route", ["exports", "soferuj/mixins/authenticated-route", "soferuj/modules/shadowing"], function (_exports, _authenticatedRoute, _shadowing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    ajax: Ember.inject.service(),
    model(params) {
      return this.ajax.request(`tests/history/${params.testId}`).then(test => {
        test.data.attributes.questions = (0, _shadowing.vyjasnit)(test.data.attributes.questions);
        return test;
      }).catch(e => console.log('História testu nebola nájdená'));
    }
  });
  _exports.default = _default;
});