define("soferuj/modules/driving-schools/components/map/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    googleMapsApi: Ember.inject.service(),
    google: Ember.computed.reads('googleMapsApi.google'),
    activeSchool: null,
    myLocation: null,
    // courseTypes: [
    //   {label: 'Všetky', value: 'all'},
    //   {label: 'Normálny kurz', value: 'standard'},
    //   {label: 'Rýchlokurz', value: 'express'},
    //   {label: 'Kondičné jazdy', value: 'training'}
    // ],

    didReceiveAttrs() {
      this._super(...arguments);
      if (this.map) this.zoomToBounds();
    },
    zoomToBounds() {
      let bounds = new google.maps.LatLngBounds();
      const map = this.map;
      const mapLocations = this.mapLocations.filter(_ref => {
        let {
          active
        } = _ref;
        return active;
      });
      if (mapLocations.length && this.filtersEnabled) {
        mapLocations.map(_ref2 => {
          let {
            location
          } = _ref2;
          return bounds.extend({
            lat: location.x,
            lng: location.y
          });
        });
        map.fitBounds(bounds);
      } else if (mapLocations.length && !this.filtersEnabled && this.myLocation) {
        map.panTo({
          lat: this.myLocation.latitude,
          lng: this.myLocation.longitude
        });
        map.setZoom(13);
      } else {
        map.setZoom(8);
        map.setCenter({
          lat: 48.6772234,
          lng: 19.7049394
        });
      }
    },
    openTooltip(drivingSchool) {
      this.set('activeSchool', null);
      this.set('activeSchool', drivingSchool);
    },
    onMapLoaded(_ref3) {
      let {
        map
      } = _ref3;
      this.set('map', map);
      this.zoomToBounds();
      if (navigator.geolocation) {
        Ember.run.later(() => {
          navigator.geolocation.getCurrentPosition(_ref4 => {
            let {
              coords
            } = _ref4;
            this.myLocation = coords;
            this.zoomToBounds();
          }, err => console.error(err), {
            maximumAge: 60000,
            timeout: 5000,
            enableHighAccuracy: true
          });
        }, 1000);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "openTooltip", [_dec], Object.getOwnPropertyDescriptor(_obj, "openTooltip"), _obj), _applyDecoratedDescriptor(_obj, "onMapLoaded", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onMapLoaded"), _obj)), _obj)));
  _exports.default = _default;
});