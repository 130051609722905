define("soferuj/modules/test/statistics/components/wrong-questions-filters/component", ["exports", "soferuj/modules/statics"], function (_exports, _statics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    mobileFilterText: Ember.computed('wrongQuestionsTypeFilter', function () {
      const filter = this.wrongQuestionsTypeFilter;
      if (!filter) return 'Filtrovať';
      return _statics.default.questionsTypeLocal[filter];
    })
  });
  _exports.default = _default;
});