define("soferuj/modules/shadowing", ["exports", "soferuj/modules/statics", "crypto-js"], function (_exports, _statics, _cryptoJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vyjasnit = vyjasnit;
  _exports.zahmlit = zahmlit;
  function zahmlit(veci) {
    return _cryptoJs.default.AES.encrypt(JSON.stringify(veci), (0, _statics.ZAHMLENIE_FULL)()).toString();
  }
  function vyjasnit(veci) {
    var bytes = _cryptoJs.default.AES.decrypt(veci, (0, _statics.ZAHMLENIE_FULL)());
    return JSON.parse(bytes.toString(_cryptoJs.default.enc.Utf8));
  }
});