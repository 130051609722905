define("soferuj/mixins/authenticated-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    identity: Ember.inject.service(),
    init: function () {
      this._super();
      this.addObserver('identity.isFetched', this.checkIfLoggedIn);
    },
    beforeModel() {
      return this.checkIfLoggedIn();
    },
    checkIfLoggedIn() {
      const identity = this.identity;
      if (identity.isFetched && !identity.user) this.transitionTo('login');
    }
  });
  _exports.default = _default;
});