define("ember-changeset-validations/validators/inclusion", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-validators"], function (_exports, _validationErrors, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateInclusion;
  function validateInclusion() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (options.list) {
      options.in = options.list;
    }
    return (key, value) => {
      let result = (0, _emberValidators.validate)('inclusion', value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});