define("ember-changeset/utils/is-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isObject;
  /**
   * Employ Ember strategies for isObject detection
   * @method isObject
   */
  function isObject(val) {
    return val !== null && typeof val === 'object' && !(val instanceof Date || val instanceof RegExp) && !Ember.isArray(val);
  }
});