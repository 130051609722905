define("soferuj/modules/components/image-cropper/component", ["exports", "ember-cli-image-cropper/components/image-cropper"], function (_exports, _imageCropper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: needs refactor
  var _default = _imageCropper.default.extend({
    drivingSchool: false,
    aspectRatio: 1,
    minCropBoxWidth: 150,
    // TODO: check this size
    minCropBoxHeight: 150,
    cropperContainer: '.cropper-container > img',
    previewClass: '.crop-preview',
    zoomable: false,
    movable: false,
    actions: {
      cancel() {
        this.sendAction('onCancel');
        this.cropper.destroy();
      },
      cropp() {
        let model = this.model;
        var croppedImage = this.cropper.getCroppedCanvas();
        var {
          height,
          width,
          x,
          y
        } = this.cropper.getData();
        const editProperties = {
          height: Math.round(height),
          width: Math.round(width),
          top: Math.round(y),
          left: Math.round(x)
        };
        this.sendAction('onCropped', croppedImage, editProperties);
        this.cropper.destroy();
      }
    }
  });
  _exports.default = _default;
});