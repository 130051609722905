define("soferuj/modules/driving-school/contact/components/map/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Map = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class Map extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "restrictions", {
        country: 'sk'
      });
      _initializerDefineProperty(this, "map", _descriptor, this);
      _initializerDefineProperty(this, "address", _descriptor2, this);
    }
    get mapProps() {
      return {
        zoom: this.args.model.get('address.location') ? 18 : 7,
        lat: this.args.model.get('address.location.x') || 48.6772234,
        lng: this.args.model.get('address.location.y') || 19.7049394
      };
    }
    addressChanged(_ref) {
      let {
        geometry
      } = _ref;
      if (!geometry) return;
      const lat = geometry.location.lat();
      const lng = geometry.location.lng();
      this.args.onUpdateModel('address.location', {
        x: lat,
        y: lng
      });
      this.map.setZoom(18);
      this.map.panTo({
        lat,
        lng
      });
    }
    markerDragged(_ref2) {
      let {
        googleEvent: event
      } = _ref2;
      this.args.onUpdateModel('address.location', {
        x: event.latLng.lat(),
        y: event.latLng.lng()
      });
    }
    onMapLoad(_ref3) {
      let {
        map
      } = _ref3;
      this.map = map;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "map", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addressChanged", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "addressChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markerDragged", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "markerDragged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMapLoad", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onMapLoad"), _class.prototype)), _class));
  _exports.default = Map;
});