define("ember-composable-helpers/helpers/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [callback, array] = _ref;
      Ember.set(this, 'array', array);
      Ember.set(this, 'callback', callback);
      return Ember.get(this, 'content');
    },
    byPathDidChange: Ember.observer('callback', function () {
      let callback = Ember.get(this, 'callback');
      if (Ember.isEmpty(callback)) {
        Ember.defineProperty(this, 'content', []);
        return;
      }
      Ember.defineProperty(this, 'content', Ember.computed.map('array', callback));
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });
  _exports.default = _default;
});