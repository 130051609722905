define("ember-paper/templates/components/paper-sidenav-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YceXw45M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],[[32,0],[35,0]],null]]]],\"hasEval\":false,\"upvars\":[\"toggle\",\"action\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-sidenav-toggle.hbs"
    }
  });
  _exports.default = _default;
});