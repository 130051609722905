define("soferuj/modules/test/statistics/route", ["exports", "soferuj/mixins/authenticated-route", "moment", "query-string", "soferuj/modules/shadowing"], function (_exports, _authenticatedRoute, _moment, _queryString, _shadowing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    ajax: Ember.inject.service('ajax'),
    model() {
      const statsController = this.controllerFor('test.statistics');
      const type = statsController.get('type');
      const summaryFilters = statsController.get('summaryFilters');
      const before14days = (0, _moment.default)().subtract(14, 'days').startOf('day').toISOString();
      const summaryParams = _queryString.default.stringify({
        type,
        test: summaryFilters.type.type,
        date_from: summaryFilters.date.type === 'overall' ? undefined : before14days
      });
      const dailySummaryParams = _queryString.default.stringify({
        type,
        test: statsController.get('dailySummaryTypeFilter.type'),
        date_from: before14days
      });
      return Ember.RSVP.hash({
        myRanking: this.ajax.request('stats/user-ranking/me'),
        summary: this.ajax.request(`stats/summary?${summaryParams}`),
        dailySummary: this.ajax.request(`stats/daily-summary?${dailySummaryParams}`),
        wrongQuestions: this.ajax.request(`stats/wrong-questions?type=${type}`).then(_ref => {
          let {
            wrongQuestions
          } = _ref;
          return (0, _shadowing.vyjasnit)(wrongQuestions);
        })
      });
    },
    actions: {
      refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});