define("soferuj/modules/test/components/question-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    evaluationClass: Ember.computed('isMarked', 'isRight', 'isWrong', function () {
      if (this.isMarked) {
        return 'marked-answer';
      }
      if (this.isRight) {
        return 'right-answer';
      }
      if (this.isWrong) {
        return 'wrong-answer';
      }
      return '';
    }),
    canSkip: Ember.computed('actualQuestion', function () {
      return this.onQuestionChange && !this.testWasValidated && this.actualQuestion === this.index && !this.isLast;
    }),
    isMarked: Ember.computed('question.answer', function () {
      let question = this.question;
      return question.answer && !question.correct_answer;
    }),
    isRight: Ember.computed('question.correct_answer', function () {
      let question = this.question;
      return question.answer && question.correct_answer === question.answer;
    }),
    isWrong: Ember.computed('question.correct_answer', 'question.evaluation', function () {
      let question = this.question;
      return question.correct_answer && !question.answer || question.answer && question.answer !== question.correct_answer;
    }),
    isEmptyAnswer: Ember.computed('question.evaluation', function () {
      let question = this.question;
      return question.correct_answer && (question.answer === undefined || question.answer === null);
    })
  });
  _exports.default = _default;
});