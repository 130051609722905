define("soferuj/modules/test/history/index/route", ["exports", "soferuj/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    titleToken: 'História testov',
    model() {
      const filters = this.controllerFor('test.history.index').get('filters');
      return this.store.query('test-history', {
        type: filters.type
      });
    },
    actions: {
      // TODO: try https://stackoverflow.com/questions/49930156/ember-modify-model-in-a-route
      refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});