define("soferuj/modules/driving-school/components/editable-dialog/description/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AdN2guf9",
    "block": "{\"symbols\":[\"@model\",\"@onUpdateModel\"],\"statements\":[[8,\"paper-input\",[],[[\"@block\",\"@textarea\",\"@value\",\"@placeholder\",\"@passThru\",\"@onChange\"],[true,true,[32,1,[\"description\"]],\"Popis autoškoly\",[30,[36,0],null,[[\"rows\"],[4]]],[30,[36,1],[[32,2],\"description\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"hash\",\"fn\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/components/editable-dialog/description/template.hbs"
    }
  });
  _exports.default = _default;
});