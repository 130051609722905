define("soferuj/helpers/format-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatAddress = formatAddress;
  function formatAddress(_ref) {
    let [drivingSchool] = _ref;
    const {
      city,
      zipCode,
      street,
      streetNumber
    } = drivingSchool.address;
    return `${city || ''} ${zipCode || ''}, ${street || ''} ${streetNumber || ''}`;
  }
  var _default = Ember.Helper.helper(formatAddress);
  _exports.default = _default;
});