define("ember-cli-analytics/integrations/google-analytics", ["exports", "jquery", "ember-cli-analytics/integrations/base", "ember-cli-analytics/utils/can-use-dom", "ember-cli-analytics/utils/without"], function (_exports, _jquery, _base, _canUseDom, _without) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    /*
     * Send the current page URL to
     * the analytics engine.
     *
     * @method trackPage
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackPage() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const sendEvent = {
        hitType: 'pageview'
      };
      const event = Ember.assign({}, sendEvent, options);
      if (_canUseDom.default) {
        window.ga('send', event);
      }
    },
    /*
     * Send an arbitrary event to the
     * analytics engine.
     *
     * @method trackEvent
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackEvent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const sendEvent = {
        hitType: 'event'
      };
      const gaEvent = {};
      if (options.nonInteraction) {
        gaEvent.nonInteraction = options.nonInteraction;
        delete options.nonInteraction;
      }
      for (let key in options) {
        const value = options[key];

        // If key is not a 'dimension' or 'metric', prepend with 'event'
        const shouldPrefix = !/^(dimension|metric)[0-9]{1,2}/.test(key);
        if (shouldPrefix) {
          key = `event${Ember.String.capitalize(key)}`;
        }
        gaEvent[key] = value;
      }
      const event = Ember.assign({}, sendEvent, gaEvent);
      if (_canUseDom.default) {
        window.ga('send', event);
      }
    },
    /*
     * Identify an anonymous user with a
     * unique ID. This is useful when a
     * user returns to the application
     * an we wish to further track them.
     *
     * This should not be called in
     * conjunction with alias.
     *
     * @method identity
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    identify() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const {
        id
      } = options;
      (false && !(id) && Ember.assert('You must pass a distinct id', id));
      if (_canUseDom.default) {
        window.ga('set', 'userId', id);
      }
    },
    /*
     * Insert the JavaScript tag into the
     * page, and perform any necessary
     * setup.
     *
     * @method insertTag
     * @on init
     */
    insertTag: Ember.on('init', function () {
      const config = Ember.get(this, 'config');
      const {
        id,
        remarketing,
        ecommerce,
        enhancedEcommerce,
        set
      } = Ember.assign({}, config);
      const properties = (0, _without.default)(config, 'id', 'remarketing', 'ecommerce', 'enhancedEcommerce', 'set');
      (false && !(id) && Ember.assert('You must pass a valid `id` to the GoogleAnaltics adapter', id));
      if (!_canUseDom.default) {
        return;
      }
      if (!window.ga) {
        /* eslint-disable */
        (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r;
          i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments);
          }, i[r].l = 1 * new Date();
          a = s.createElement(o), m = s.getElementsByTagName(o)[0];
          a.async = 1;
          a.src = g;
          m.parentNode.insertBefore(a, m);
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
        /* eslint-enable */
      }

      if (Ember.isPresent(Object.keys(properties))) {
        window.ga('create', id, properties);
      } else {
        window.ga('create', id, 'auto');
      }
      if (remarketing) {
        window.ga('require', 'displayfeatures');
      }
      if (ecommerce) {
        window.ga('require', 'ecommerce');
      }
      if (enhancedEcommerce) {
        window.ga('require', 'ecommerce');
      }
      if (set) {
        for (const attr of Object.keys(set)) {
          window.ga('set', attr, set[attr]);
        }
      }
    }),
    /*
     * Remove the JavaScript tag from the
     * page, and perform any necessary
     * teardown.
     *
     * @method removeTag
     * @on willDestroy
     */
    removeTag: Ember.on('willDestroy', function () {
      if (_canUseDom.default) {
        (0, _jquery.default)('script[src*="google-analytics"]').remove();
        delete window.ga;
      }
    })
  });
  _exports.default = _default;
});