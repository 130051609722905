define("soferuj/initializers/ember-cli-webfontloader", ["exports", "soferuj/config/environment", "ember-cli-webfontloader/initializers/ember-cli-webfontloader"], function (_exports, _environment, _emberCliWebfontloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-cli-webfontloader',
    initialize() {
      if (typeof FastBoot === 'undefined') {
        const config = Ember.get(_environment.default, 'webFontConfig') || {};
        (0, _emberCliWebfontloader.default)(config);
      }
    }
  };
  _exports.default = _default;
});