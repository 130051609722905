define("ember-paper/components/paper-select/eps-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nLz/qmR0",
    "block": "{\"symbols\":[\"@extra\",\"@placeholder\",\"@select\",\"&default\",\"@allowClear\"],\"statements\":[[10,\"md-select-value\"],[15,0,[31,[\"md-select-value \",[30,[36,0],[[32,0,[\"isPlaceholder\"]],\"md-select-placeholder\"],null]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,3,[\"selected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"ember-power-select-selected-item\"],[12],[18,4,[[32,3,[\"selected\"]],[32,3]]],[13],[2,\"\\n\\n\"],[6,[37,0],[[30,[36,3],[[32,5],[30,[36,2],[[32,3,[\"disabled\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"span\"],[24,0,\"ember-power-select-clear-btn\"],[24,\"role\",\"button\"],[4,[38,1],[\"mousedown\",[32,0,[\"clear\"]]],null],[4,[38,1],[\"touchstart\",[32,0,[\"clear\"]]],null],[12],[2,\"×\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"ember-power-select-placeholder\"],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"ember-power-select-placeholder\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[10,\"span\"],[14,0,\"md-select-icon\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"not\",\"and\"]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-select/eps-trigger/template.hbs"
    }
  });
  _exports.default = _default;
});