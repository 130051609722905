define("ember-concurrency/helpers/perform", ["exports", "ember-concurrency/-private/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.performHelper = performHelper;
  function performHelper(args, hash) {
    return (0, _helpers.taskHelperClosure)('perform', 'perform', args, hash);
  }
  var _default = Ember.Helper.helper(performHelper);
  _exports.default = _default;
});