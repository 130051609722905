define("soferuj/modules/test/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    type: 'training',
    metrics: Ember.inject.service(),
    router: Ember.inject.service(),
    options: Ember.Object.create({
      type: 'ab',
      number: {
        label: 'náhodný',
        value: 0
      },
      questionCount: {
        label: '27 (oficiálny test)',
        value: 27
      },
      questionType: 'all'
    }),
    isOfficialType: Ember.computed('type', function () {
      return this.type === 'official';
    }),
    isTrainingType: Ember.computed('type', function () {
      return this.type === 'training';
    }),
    nextTest() {
      let {
        number,
        type
      } = this.options;
      let nextNumber;

      // TODO: when random, it should give a random number

      if (number.value && type === 'ab') {
        nextNumber = number.value === 35 ? 1 : ++number.value;
      }
      if (number.value && type === 'cdt') {
        nextNumber = number.value === 60 ? 36 : ++number.value;
      }
      if (nextNumber) {
        let url = this.isOfficialType ? 'test.official.number' : 'test.training.number';
        this.transitionToRoute(url, nextNumber);
      }
    },
    actions: {
      redirect(link) {
        this.router.transitionTo(link);
      },
      changeType(type) {
        this.set('options.number', {
          label: 'náhodný',
          value: 0
        });
        this.set('type', type);
      },
      changeOption(type, value) {
        this.set(`options.${type}`, value);
      },
      startQuickTest() {
        const options = {
          type: this.get('options.type'),
          number: {
            label: 'náhodný',
            value: 0
          },
          questionCount: {
            label: '27 (oficiálny test)',
            value: 27
          },
          questionType: 'all'
        };
        this.metrics.trackEvent({
          category: 'Test',
          action: 'start-quick'
        });
        this.set('options', options);
        this.transitionToRoute('test.training');
      },
      startTest() {
        let url = this.isOfficialType ? 'test.official' : 'test.training';
        console.log(this.metrics);
        this.metrics.trackEvent({
          category: 'Test',
          action: 'start'
        });
        this.transitionToRoute(url);
      }
    }
  });
  _exports.default = _default;
});