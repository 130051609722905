define("soferuj/modules/test/user-rankings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gJA6oa2f",
    "block": "{\"symbols\":[\"ranking\"],\"statements\":[[10,\"div\"],[14,0,\"route-tests-top10\"],[12],[2,\"\\n\\t\"],[1,[30,[36,10],null,[[\"title\",\"backLink\"],[\"TOP 10 najúspešnejších za posledných 14 dní\",\"test\"]]]],[2,\"\\n\\n\"],[6,[37,11],null,[[\"class\"],[\"info\"]],[[\"default\"],[{\"statements\":[[6,[37,9],null,[[\"size\"],[\"md\"]],[[\"default\"],[{\"statements\":[[6,[37,8],null,null,[[\"default\"],[{\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"class\"],[\"md-2-line\"]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],null,[[\"title\",\"ranking\",\"breakpoint\"],[[32,1,[\"username\"]],[32,1],\"xs\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,1],[35,3,[\"lastObject\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"paper-divider\",\"test/components/user-ranking\",\"paper-item\",\"model\",\"eq\",\"unless\",\"-track-array\",\"each\",\"paper-list\",\"page/page-card\",\"page/page-header\",\"page/page-content\"]}",
    "meta": {
      "moduleName": "soferuj/modules/test/user-rankings/template.hbs"
    }
  });
  _exports.default = _default;
});