define("soferuj/modules/register/driving-school/components/register-footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      previousStep() {
        this.registration.send('previousStep');
      }
    }
  });
  _exports.default = _default;
});