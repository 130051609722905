define("soferuj/modules/test/serializer", ["exports", "ember-data", "soferuj/modules/shadowing"], function (_exports, _emberData, _shadowing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPISerializer.extend({
    serialize(snapshot, options) {
      var json = this._super(...arguments);
      json.data.attributes.questions = (0, _shadowing.zahmlit)(json.data.attributes.questions);
      return json;
    },
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.data.attributes.questions = (0, _shadowing.vyjasnit)(payload.data.attributes.questions);
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
  _exports.default = _default;
});