define("soferuj/modules/test/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    titleToken: `Autoškola testy, Oficiálne testy na vodičák, Online testy ${(0, _moment.default)().format('YYYY')}`,
    headTags: [{
      type: 'meta',
      tagId: 'description',
      attrs: {
        name: 'description',
        content: `Aktuálne, oficiálne online testy na vodičák ${(0, _moment.default)().format('YYYY')} pre skupiny A,B,C,D,T. Moderné testy pri ktorých sa viete zamerať na určitý typ otázok ako križovatky..`
      }
    }],
    beforeModel() {
      let testSettings = this.controllerFor('test.index');
      if (this.get('router.url') === '/testy/cvicny') {
        testSettings.set('type', 'training');
        this.transitionTo('test');
      }
      if (this.get('router.url') === '/testy/oficialny') {
        testSettings.set('type', 'official');
        this.transitionTo('test');
      }
    }
  });
  _exports.default = _default;
});