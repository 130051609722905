define("soferuj/modules/register/driving-school/finish/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    identity: Ember.inject.service('identity'),
    model() {
      if (this.get('identity.user.type') === 'driving-school') {
        return null;
      }
      let user = this.store.createRecord('user');
      user.set('type', 'driving-school');
      return user;
    },
    actions: {
      resetModel() {
        this.refresh();
      },
      willTransition() {
        if (this.controller.get('model')) {
          this.controller.get('model').rollbackAttributes();
        }
        this.controller.set('therms', false);
      }
    }
  });
  _exports.default = _default;
});