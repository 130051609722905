define("ember-paper/components/paper-card-header-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class PaperCardHeaderTitle
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['md-title']
  });
  _exports.default = _default;
});