define("soferuj/validators/messages", ["exports", "ember-cp-validations/validators/messages", "soferuj/validations/messages"], function (_exports, _messages, _messages2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // override defaults
  // https://github.com/offirgolan/ember-validators/blob/master/addon/messages.js
  var _default = _messages.default.extend(_messages2.default);
  _exports.default = _default;
});