define("ember-google-maps/templates/components/g-map/marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1EgLDJ+y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[32,0,[\"mapComponent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,4],null,[[\"infoWindow\"],[[30,[36,3],[\"g-map/info-window\"],[[\"map\",\"_internalAPI\",\"gMap\",\"target\"],[[35,2],[35,1],[35,0],[32,0,[\"mapComponent\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"gMap\",\"_internalAPI\",\"map\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/marker.hbs"
    }
  });
  _exports.default = _default;
});