define("soferuj/modules/settings/index/password-form/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    password: {
      description: 'Heslo',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 5
      })]
    },
    newPassword: {
      description: 'Heslo',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 5
      })]
    }
  });
  var _default = Validations;
  _exports.default = _default;
});