define("ember-paper/components/paper-autocomplete/options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZhX0vNsV",
    "block": "{\"symbols\":[\"opt\",\"index\",\"@options\",\"@select\",\"&attrs\",\"@groupIndex\",\"&default\"],\"statements\":[[8,\"vertical-collection\",[[24,0,\"md-autocomplete-suggestions\"],[24,\"role\",\"listbox\"],[16,\"aria-controls\",[31,[\"ember-power-select-trigger-\",[32,4,[\"uniqueId\"]]]]],[17,5],[4,[38,0],[[32,0,[\"addHandlers\"]]],null]],[[\"@items\",\"@tagName\",\"@containerSelector\",\"@estimateHeight\",\"@staticHeight\",\"@bufferSize\"],[[32,3],\"ul\",\".md-autocomplete-suggestions-container\",48,true,3]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"li\"],[15,0,[31,[\"ember-power-select-option md-autocomplete-suggestion \",[30,[36,2],[[30,[36,1],[[32,1],[32,4,[\"highlighted\"]]],null],\"selected\"],null]]]],[15,\"aria-selected\",[31,[[30,[36,3],[[32,1],[32,4,[\"selected\"]]],null]]]],[15,\"aria-disabled\",[30,[36,2],[[32,1,[\"disabled\"]],\"true\"],null]],[15,\"aria-current\",[31,[[30,[36,1],[[32,1],[32,4,[\"highlighted\"]]],null]]]],[15,\"data-option-index\",[31,[[32,6],[32,2]]]],[14,\"role\",\"option\"],[15,\"tabindex\",[30,[36,2],[[32,1,[\"disabled\"]],\"-1\",\"0\"],null]],[12],[2,\"\\n    \"],[18,7,[[32,1],[32,4]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"eq\",\"if\",\"ember-power-select-is-selected\"]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-autocomplete/options/template.hbs"
    }
  });
  _exports.default = _default;
});