define("@ember/polyfills/lib/weak_set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals WeakSet */
  var _default = typeof WeakSet === 'function' ? WeakSet : class WeakSetPolyFill {
    constructor() {
      this._map = new WeakMap();
    }
    add(val) {
      this._map.set(val, true);
      return this;
    }
    delete(val) {
      return this._map.delete(val);
    }
    has(val) {
      return this._map.has(val);
    }
  };
  _exports.default = _default;
});