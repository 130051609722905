define("soferuj/modules/register/driving-school/basic-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RPtJhDNn",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[30,[36,6],[[35,2,[\"isDuplicate\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[1,[30,[36,5],null,[[\"model\",\"registration\",\"checkDuplicate\",\"submit\"],[[35,4],[35,2],[30,[36,0],[[32,0],\"checkDuplicate\"],null],[30,[36,0],[[32,0],\"submit\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[1,[30,[36,3],null,[[\"class\",\"registration\",\"duplicateSchools\",\"onSkip\",\"onSelect\"],[\"layout-column flex\",[35,2],[35,1],[30,[36,0],[[32,0],\"toggleDuplicate\"],null],[30,[36,0],[[32,0],\"setOwner\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"duplicateSchools\",\"registration\",\"register/driving-school/basic-info/duplicate-schools\",\"model\",\"register/driving-school/basic-info/info-form\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/register/driving-school/basic-info/template.hbs"
    }
  });
  _exports.default = _default;
});