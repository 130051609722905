define("soferuj/modules/test/components/test-countdown/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['count-down'],
    classNameBindings: ['isRed'],
    seconds: 1200,
    // 20min
    interval: null,
    isRed: Ember.computed('seconds', function () {
      return this.seconds <= 20;
    }),
    didInsertElement: function () {
      this.runTimer();
    },
    runTimer: function () {
      let interval = Ember.run.later(() => {
        this.decrementProperty('seconds');
        this.set('time', _moment.default.duration(this.seconds, "seconds").format("mm:ss", {
          trim: false
        }));
        this.runTimer();
        if (this.seconds === 0) {
          this.onEvaluate();
          Ember.run.cancel(this.interval);
        }
      }, 1000);
      this.set('interval', interval);
    },
    willDestroyElement() {
      Ember.run.cancel(this.interval);
    }
  });
  _exports.default = _default;
});