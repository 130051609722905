define("soferuj/modules/components/car-history-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rdvLh7Z9",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"car-history-banner\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/images/for_vehicle/speedometer.png\"],[12],[13],[2,\"\\n  \"],[10,\"b\"],[12],[2,\"Skontrolujte históriu jazdeného vozidla\"],[13],[2,\"\\n\"],[6,[37,0],null,[[\"route\"],[\"for-vehicle.history\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"paper-button\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Prečítať viac\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/car-history-banner/template.hbs"
    }
  });
  _exports.default = _default;
});