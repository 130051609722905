define("soferuj/modules/driving-school/controller", ["exports", "query-string", "soferuj/modules/driving-school/components/editable-dialog/editable-section.enum", "soferuj/modules/driving-school/types/add-school-dialog-state.enum"], function (_exports, _queryString, _editableSection, _addSchoolDialogState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DrivingSchoolController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed('router.currentRouteName'), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class DrivingSchoolController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "identity", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "isEditing", _descriptor3, this);
      _initializerDefineProperty(this, "addSchoolDialogState", _descriptor4, this);
      _initializerDefineProperty(this, "createReviewDialogOpen", _descriptor5, this);
      _initializerDefineProperty(this, "editingSection", _descriptor6, this);
      _initializerDefineProperty(this, "editingProfilePicture", _descriptor7, this);
      _initializerDefineProperty(this, "inviteDialogOpen", _descriptor8, this);
      _initializerDefineProperty(this, "inviteToken", _descriptor9, this);
      _defineProperty(this, "EDITABLE_SECTION", _editableSection.EDITABLE_SECTION);
    }
    init() {
      super.init();
      const {
        inviteToken
      } = _queryString.default.parse(window.location ? window.location.search : '');
      if (inviteToken) {
        this.inviteDialogOpen = true;
        this.inviteToken = inviteToken;
      }
    }
    get currentRouteName() {
      return this.router.currentRouteName;
    }
    get isOwner() {
      return this.identity.isAuthenticated && this.identity.user.isDrivingSchool && !!this.hasAssignedSchool;
    }
    get hasAssignedSchool() {
      const assignedSchools = this.identity.get('user.drivingSchools');
      return !!assignedSchools.find(_ref => {
        let {
          id
        } = _ref;
        return id === this.model.id;
      });
    }
    createReview() {
      if (!this.identity.isAuthenticated) {
        this.router.transitionTo('login');
        return;
      }
      if (!this.hasAssignedSchool) {
        this.addSchoolDialogState = _addSchoolDialogState.ADD_SCHOOL_DIALOG_STATE.ADD_REVIEW;
        return;
      }
      this.addSchoolDialogState = _addSchoolDialogState.ADD_SCHOOL_DIALOG_STATE.CLOSED;
      this.createReviewDialogOpen = true;
    }
    assignSchoolFromReviewVote() {
      this.addSchoolDialogState = _addSchoolDialogState.ADD_SCHOOL_DIALOG_STATE.ADD_VOTE;
    }
    closeDialogs() {
      this.addSchoolDialogState = _addSchoolDialogState.ADD_SCHOOL_DIALOG_STATE.CLOSED;
      this.createReviewDialogOpen = false;
    }
    updateModel(property, value) {
      this.model.set(property, value);
    }
    handleEditSection(sectionName) {
      this.editingSection = sectionName;
    }
    handleCloseEditing() {
      this.editingSection = null;
    }
    togglePictureEditing() {
      this.editingProfilePicture = !this.editingProfilePicture;
    }
    toggleInviteDialog() {
      this.inviteDialogOpen = !this.inviteDialogOpen;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "identity", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isEditing", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "addSchoolDialogState", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _addSchoolDialogState.ADD_SCHOOL_DIALOG_STATE.CLOSED;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createReviewDialogOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "editingSection", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "editingProfilePicture", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "inviteDialogOpen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "inviteToken", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "currentRouteName", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "currentRouteName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createReview", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "createReview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "assignSchoolFromReviewVote", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "assignSchoolFromReviewVote"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeDialogs", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "closeDialogs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateModel", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "updateModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleEditSection", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "handleEditSection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCloseEditing", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "handleCloseEditing"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePictureEditing", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "togglePictureEditing"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleInviteDialog", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "toggleInviteDialog"), _class.prototype)), _class));
  _exports.default = DrivingSchoolController;
});