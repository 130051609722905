define("soferuj/modules/driving-school/components/editable-dialog/address/component", ["exports", "@glimmer/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let EditAddress = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class EditAddress extends _component.default {
    get village() {
      return this.locations.find(location => location.value === this.args.model.address.villageId);
    }
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "locations", _descriptor2, this);
      _defineProperty(this, "restrictions", {
        country: 'sk'
      });
      this.init();
    }
    async init() {
      const villagesByRegion = await this.ajax.request('region-villages?schoolsPresent=true');
      this.locations = villagesByRegion.reduce(
      // Duplicate
      (locations, region) => {
        const villages = region.villages.map(village => ({
          label: village.name,
          value: village.id,
          slug: village.slug
        }));
        locations = locations.concat([{
          label: region.name,
          value: region.id,
          slug: region.slug,
          class: 'region',
          disabled: true
        }]).concat(villages);
        return locations;
      }, []);
    }
    villageChanged(option) {
      if (option.disabled) return;
      this.args.onUpdateModel('address.villageId', option.value);
    }
    addressChanged(_ref) {
      let {
        address_components,
        geometry
      } = _ref;
      if (!address_components || !geometry) return;
      const streetNumber = _lodash.default.find(address_components, address => _lodash.default.includes(address.types, 'street_number'));
      const zipCode = _lodash.default.find(address_components, address => _lodash.default.includes(address.types, 'postal_code'));
      const villageName = _lodash.default.find(address_components, address => _lodash.default.intersection(address.types, ['sublocality', 'locality']).length).long_name;
      const village = this.locations.find(location => location.label === villageName);
      this.args.onUpdateModel('address', {
        villageId: village ? village.value : null,
        street: _lodash.default.find(address_components, address => _lodash.default.includes(address.types, 'route')).long_name,
        zipCode: zipCode ? zipCode.long_name : '',
        streetNumber: streetNumber ? streetNumber.long_name : '',
        location: {
          x: geometry.location.lat(),
          y: geometry.location.lng()
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "locations", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "init", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "villageChanged", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "villageChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addressChanged", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "addressChanged"), _class.prototype)), _class));
  _exports.default = EditAddress;
});