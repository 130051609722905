define("ember-paper/templates/components/paper-subheader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5Hu5Nxn7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"md-subheader-inner\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-subheader-content\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-subheader.hbs"
    }
  });
  _exports.default = _default;
});