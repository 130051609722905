define("soferuj/models/driving-school/contact", ["exports", "@ember-data/model", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _model, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    phone: (0, _model.attr)('string'),
    phoneSecondary: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    website: (0, _model.attr)('string'),
    socialLinks: (0, _attributes.fragment)('drivingSchool.social', {
      defaultValue: {}
    }) // TODO: fix naming, + driving school social linkss
  });
  _exports.default = _default;
});