define("soferuj/helpers/expand-line-breaks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.expandLineBreaks = expandLineBreaks;
  function expandLineBreaks(_ref) {
    let [text] = _ref;
    if (!text) return text;
    return new Ember.String.htmlSafe(text.replace(/\n/g, '<br>'));
  }
  var _default = Ember.Helper.helper(expandLineBreaks);
  _exports.default = _default;
});