define("soferuj/modules/driving-school/pricing/components/create-course/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yELOOmWr",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[1,[30,[36,6],null,[[\"course\",\"isSubmitted\"],[[35,5],[35,4]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[6,[37,3],null,[[\"primary\",\"raised\",\"class\",\"onClick\"],[true,true,\"green\",[30,[36,2],[[32,0],[35,1]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"plus\"],null]],[2,\" Pridať kurz\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"onEdit\",\"action\",\"paper-button\",\"isSubmitted\",\"course\",\"driving-school/pricing/components/course-form\",\"isEditing\",\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/pricing/components/create-course/template.hbs"
    }
  });
  _exports.default = _default;
});