define("soferuj/modules/contact/controller", ["exports", "soferuj/modules/contact/validations", "soferuj/modules/statics"], function (_exports, _validations, _statics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_validations.default, {
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    contact: _statics.default.contact,
    sending: false,
    showConsentError: false,
    email: {},
    actions: {
      onCaptchaResolved(code) {
        this.set('email.captcha', code);
      },
      sendEmail() {
        if (!this.get('email.consent')) {
          this.set('showConsentError', true);
          return;
        }
        if (!this.get('email.captcha')) {
          return;
        }
        this.toggleProperty('sending');
        this.ajax.post('send-email', {
          data: this.email
        }).then(() => {
          this.paperToaster.show('Správa odoslaná');
          this.toggleProperty('sending');
          this.set('email', {});
        });
      },
      changeConsent(agree) {
        this.set('showConsentError', !agree);
        this.set('email.consent', agree);
      }
    }
  });
  _exports.default = _default;
});