define("soferuj/modules/test/training/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actualQuestion: 0,
    testSettings: Ember.inject.controller('test.index'),
    metrics: Ember.inject.service(),
    //TODO: to a mixin? common fn
    isValidated: Ember.computed('model.evaluation', function () {
      let evaluation = this.get('model.evaluation');
      return !_lodash.default.isEmpty(evaluation);
    }),
    visibleQuestions: Ember.computed('actualQuestion', 'model.questions', 'isValidated', function () {
      let isValidated = this.isValidated;
      let questions = this.get('model.questions');
      let actualQuestion = this.actualQuestion;
      if (isValidated) {
        return questions.map(function (question, index) {
          question.index = index;
          return question;
        });
      }
      if (actualQuestion) {
        questions = questions.slice(actualQuestion - 1, actualQuestion + 1);
        Ember.set(questions[0], 'index', actualQuestion - 1);
        Ember.set(questions[1], 'index', actualQuestion);
      } else {
        questions = [questions[0]];
        Ember.set(questions[0], 'index', 0);
      }
      return questions.reverse();
    }),
    actions: {
      markQuestion(index, value) {
        let model = this.model;
        let questions = model.get('questions');
        let question = questions[index];
        if (question.answer) {
          return;
        }
        Ember.set(question, 'answer', value);
        model.save().then(() => {
          let actualQuestion = this.actualQuestion;
          if (questions.length !== actualQuestion + 1) {
            Ember.run.later(() => {
              this.set('actualQuestion', ++index);
            }, 1200);
          }
        });
      },
      changeQuestion(index) {
        this.set('actualQuestion', index);
      },
      startNextTest() {
        this.metrics.trackEvent({
          category: 'Test',
          action: 'start-next'
        });
        this.testSettings.nextTest();
        this.send('refreshModel');
      },
      evaluate() {
        let model = this.model;
        model.set('evaluation', {});
        model.set('test', 'training');
        this.metrics.trackEvent({
          category: 'Test',
          action: 'completed'
        });
        model.save().then(() => {
          this.send('changeQuestion', 0);
        });
      }
    }
  });
  _exports.default = _default;
});