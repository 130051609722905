define("soferuj/modules/register/driving-school/route", ["exports", "query-string"], function (_exports, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    titleToken: 'Registrácia autoškoly',
    router: Ember.inject.service(),
    beforeModel() {
      const {
        inviteToken
      } = _queryString.default.parse(window.location ? window.location.search : '');
      if (!inviteToken && this.get('router.url') !== '/register/driving-school') {
        this.transitionTo('register.driving-school.basic-info');
      }
    },
    model() {
      return this.store.createRecord('driving-school');
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.model.rollbackAttributes();
      }
    },
    actions: {
      resetModel() {
        this.refresh();
      },
      willTransition() {
        this.controller.set('isCompleted', false);
      },
      didTransition: function () {
        Ember.run.next(() => {
          let currentStep = this.router.currentRouteName ? this.router.currentRouteName.split('.')[2] : 'basic-info';
          this.controller.set('currentStep', currentStep);
        });
      }
    }
  });
  _exports.default = _default;
});