define("ember-paper/components/paper-select/search/component", ["exports", "ember-power-select/components/power-select/before-options", "ember-paper/components/paper-select/search/template", "@ember-decorators/component"], function (_exports, _beforeOptions, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  let PaperSelectOptions = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class PaperSelectOptions extends _beforeOptions.default {}) || _class) || _class);
  var _default = PaperSelectOptions;
  _exports.default = _default;
});