define("soferuj/modules/test/training/route", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    titleToken: 'Cvičný test',
    beforeModel(transition) {
      let testSettings = this.controllerFor('test.index');
      const testNumber = transition.to.params.testNumber;

      // set default test properties if url contains test number
      if (testNumber && testSettings.get('options.number') != testNumber) {
        testSettings.set('type', 'training');
        testSettings.set('options.type', testNumber < 36 ? 'ab' : 'cdt');
        testSettings.set('options.number', {
          label: testNumber,
          value: Number(testNumber)
        });
      }
    },
    model(params, transition) {
      let testSettings = this.controllerFor('test.index');
      let {
        type,
        number,
        questionCount,
        questionType
      } = testSettings.get('options');
      let officialId = null;
      if (number.value !== 0) {
        // is official test
        officialId = number.value;
      } else if (questionCount.value !== 27 || questionType !== 'all') {
        officialId = undefined;
      } else {
        officialId = type === 'ab' ? _lodash.default.random(1, 35) : _lodash.default.random(36, 60);
      }
      this.store.unloadAll();

      // set test number in URL
      if (officialId && !transition.to.params.testNumber) {
        this.transitionTo('test.training.number', officialId);
        return;
      }
      return this.store.queryRecord('test', {
        type: officialId ? undefined : type,
        test_number: officialId,
        question_type: officialId ? undefined : questionType,
        question_count: officialId ? undefined : questionCount.value
      });
    },
    actions: {
      refreshModel() {
        this.refresh();
      },
      willTransition() {
        this.controller.set('actualQuestion', 0);
      }
    }
  });
  _exports.default = _default;
});