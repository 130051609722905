define("soferuj/modules/driving-school/components/editable-dialog/location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "O6mKmS6M",
    "block": "{\"symbols\":[\"@model\",\"@onUpdateModel\"],\"statements\":[[8,\"driving-school/contact/components/map\",[],[[\"@model\",\"@editable\",\"@onUpdateModel\"],[[32,1],true,[32,2]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/components/editable-dialog/location/template.hbs"
    }
  });
  _exports.default = _default;
});