define("soferuj/modules/test/statistics/components/not-found-tests/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xODlwSlc",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"chart-bar\"],[[\"class\"],[\"icon\"]]]],[2,\"\\n\"],[10,\"p\"],[12],[2,\"Neboli nájdené žiadne testy.\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fa-icon\"]}",
    "meta": {
      "moduleName": "soferuj/modules/test/statistics/components/not-found-tests/template.hbs"
    }
  });
  _exports.default = _default;
});