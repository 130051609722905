define("soferuj/modules/admin/reviews/controller", ["exports", "soferuj/modules/driving-school/types/review-status.enum"], function (_exports, _reviewStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  var RejectType;
  (function (RejectType) {
    RejectType["REVIEW"] = "review";
    RejectType["RESPONSE"] = "response";
  })(RejectType || (RejectType = {}));
  let AdminReviews = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class AdminReviews extends Ember.Controller.extend() {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "filters", [{
        type: _reviewStatus.REVIEW_STATUS.PENDING_APPROVAL,
        name: 'Nové hodnotenia'
      }, {
        type: _reviewStatus.REVIEW_STATUS.REJECTED,
        name: 'Zamietnuté hodnotenia'
      }, {
        type: _reviewStatus.REVIEW_STATUS.PUBLISHED,
        name: 'Schválené hodnotenia'
      }]);
      _defineProperty(this, "RejectType", RejectType);
      _initializerDefineProperty(this, "filterBy", _descriptor2, this);
      _initializerDefineProperty(this, "reject", _descriptor3, this);
    }
    changeFilter(filter) {
      this.filterBy = filter;
      this.send('refreshModel');
    }
    approveReview(review) {
      this.ajax.patch(`/admin/reviews/${review.id}/change-status`, {
        data: {
          status: _reviewStatus.REVIEW_STATUS.PUBLISHED
        }
      }).then(data => this.store.pushPayload(data));
    }
    rejectReview(review) {
      if (review && !this.reject.review) {
        this.openRejectDialog(review, RejectType.REVIEW);
        return;
      }
      if (this.reject.review) {
        this.ajax.patch(`/admin/reviews/${this.reject.review.id}/change-status`, {
          data: {
            status: _reviewStatus.REVIEW_STATUS.REJECTED,
            rejectReason: this.reject.reason
          }
        }).then(data => {
          this.store.pushPayload(data);
          this.closeRejectDialog();
        });
      }
    }
    approveResponse(review) {
      this.ajax.patch(`/admin/reviews/${review.id}/response/change-status`, {
        data: {
          status: _reviewStatus.REVIEW_STATUS.PUBLISHED
        }
      }).then(data => this.store.pushPayload(data));
    }
    openRejectDialog(review, type) {
      this.reject = {
        dialogOpen: true,
        review,
        type
      };
    }
    closeRejectDialog() {
      this.reject = {
        reason: '',
        review: null,
        dialogOpen: false
      };
    }
    rejectResponse(review) {
      if (review && !this.reject.review) {
        this.openRejectDialog(review, RejectType.RESPONSE);
        return;
      }
      if (this.reject.review) {
        this.ajax.patch(`/admin/reviews/${this.reject.review.id}/response/change-status`, {
          data: {
            status: _reviewStatus.REVIEW_STATUS.REJECTED,
            rejectReason: this.reject.reason
          }
        }).then(data => {
          this.store.pushPayload(data);
          this.closeRejectDialog();
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filterBy", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.filters[0];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "reject", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        reason: '',
        review: null,
        dialogOpen: false,
        type: RejectType.REVIEW
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeFilter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "changeFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approveReview", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "approveReview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rejectReview", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "rejectReview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approveResponse", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "approveResponse"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openRejectDialog", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "openRejectDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeRejectDialog", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "closeRejectDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rejectResponse", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "rejectResponse"), _class.prototype)), _class));
  _exports.default = AdminReviews;
});