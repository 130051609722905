define("soferuj/modules/driving-school/components/create-review-dialog/validations", ["exports", "ember-changeset-validations/validators", "soferuj/validations/truthy"], function (_exports, _validators, _truthy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-expect-error
  var _default = {
    question1Rating: (0, _validators.validateNumber)({
      gt: 0,
      message: 'Potrebné vyplniť'
    }),
    question2Rating: (0, _validators.validateNumber)({
      gt: 0,
      message: 'Potrebné vyplniť'
    }),
    question3Rating: (0, _validators.validateNumber)({
      gt: 0,
      message: 'Potrebné vyplniť'
    }),
    text: (0, _validators.validateLength)({
      presence: true,
      min: 30
    }),
    consent: (0, _truthy.default)({
      message: 'Bez potvrdenia súhlasu nie je možné pridať hodnotenie'
    })
  };
  _exports.default = _default;
});