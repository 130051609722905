define("soferuj/modules/driving-school/pricing/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const typesOrder = ['standard', 'training', 'express'];
  var _default = Ember.Controller.extend({
    drivingSchool: Ember.inject.controller(),
    courses: Ember.computed.sort('model.courses', function (a, b) {
      const indexA = typesOrder.indexOf(a.type);
      const indexB = typesOrder.indexOf(b.type);

      // when creating a new course
      if (!a.category || !b.category) {
        return 0;
      }

      // sort by category name
      if (a.category.name > b.category.name) {
        return 1;
      } else if (a.category.name < b.category.name) {
        return -1;
      }

      // then sort by types order
      if (indexA > indexB) {
        return 1;
      } else if (indexA < indexB) {
        return -1;
      }
      return 0;
    }),
    updatedDates: Ember.computed.mapBy('model.courses', 'updatedAt'),
    lastUpdated: Ember.computed.max('updatedDates')
  });
  _exports.default = _default;
});