define("soferuj/modules/driving-school/reviews/response-dialog/validations", ["exports", "ember-changeset-validations/validators", "soferuj/validations/truthy"], function (_exports, _validators, _truthy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-expect-error
  var _default = {
    text: (0, _validators.validateLength)({
      presence: true,
      min: 20
    }),
    consent: (0, _truthy.default)({
      message: 'Bez potvrdenia súhlasu nie je možné pridať reakciu na hodnoteni'
    })
  };
  _exports.default = _default;
});