define("soferuj/modules/driving-school/types/review-status.enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REVIEW_STATUS = void 0;
  let REVIEW_STATUS;
  _exports.REVIEW_STATUS = REVIEW_STATUS;
  (function (REVIEW_STATUS) {
    REVIEW_STATUS["PENDING_APPROVAL"] = "pending-approval";
    REVIEW_STATUS["REJECTED"] = "rejected";
    REVIEW_STATUS["PUBLISHED"] = "published";
  })(REVIEW_STATUS || (_exports.REVIEW_STATUS = REVIEW_STATUS = {}));
});