define("ember-composable-helpers/helpers/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toggle = toggle;
  function nextIndex(length, currentIdx) {
    if (currentIdx === -1 || currentIdx + 1 === length) {
      return 0;
    }
    return currentIdx + 1;
  }
  function toggle(_ref) {
    let [prop, obj, ...values] = _ref;
    return function () {
      let currentValue = Ember.get(obj, prop);
      if (Ember.isPresent(values)) {
        let currentIdx = values.indexOf(currentValue);
        let nextIdx = nextIndex(Ember.get(values, 'length'), currentIdx);
        return Ember.set(obj, prop, values[nextIdx]);
      }
      return Ember.set(obj, prop, !currentValue);
    };
  }
  var _default = Ember.Helper.helper(toggle);
  _exports.default = _default;
});