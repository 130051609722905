define("soferuj/helpers/wrong-questions-by-type-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.wrongQuestionByTypeText = wrongQuestionByTypeText;
  function wrongQuestionByTypeText(_ref) {
    let [questions, type] = _ref;
    let text = '';
    const count = type ? questions.filter(question => question.question_type.name === type).length : questions.length;
    switch (true) {
      case count === 1:
        text = 'otázka';
        break;
      case count > 1 && count <= 4:
        text = 'otázky';
        break;
      default:
        text = 'otázok';
        break;
    }
    return `${count} ${text}`;
  }
  var _default = Ember.Helper.helper(wrongQuestionByTypeText);
  _exports.default = _default;
});