define("soferuj/modules/not-found/route", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    beforeModel() {
      const url = this.get('router.url');

      // old routes
      if ((0, _lodash.includes)(url, 'autoskoly') || (0, _lodash.includes)(url, 'vyhladavanie') || (0, _lodash.includes)(url, 'autoskola')) this.transitionTo('driving-schools');
      if ((0, _lodash.includes)(url, 'testy')) this.transitionTo('test');
      if ((0, _lodash.includes)(url, 'informacie') || (0, _lodash.includes)(url, 'teoria')) this.transitionTo('info');

      // new routes
      if ((0, _lodash.includes)(url, 'driving-schools')) this.transitionTo('driving-schools');
      if ((0, _lodash.includes)(url, 'test')) this.transitionTo('test');
      if ((0, _lodash.includes)(url, 'contact')) this.transitionTo('contact');
      if ((0, _lodash.includes)(url, 'login')) this.transitionTo('login');
      if ((0, _lodash.includes)(url, 'register')) this.transitionTo('register');
      if ((0, _lodash.includes)(url, 'info')) this.transitionTo('info');
      if ((0, _lodash.includes)(url, 'pages/conditions')) this.transitionTo('pages/conditions');
      if ((0, _lodash.includes)(url, 'pages')) this.transitionTo('pages');
    }
  });
  _exports.default = _default;
});