define("soferuj/models/review/response", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    text: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    rejectReason: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    publishedAt: (0, _model.attr)('date')
  });
  _exports.default = _default;
});