define("ember-google-maps/components/g-map/waypoint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WaypointAPI = WaypointAPI;
  _exports.default = void 0;
  function WaypointAPI(source) {
    return {
      get location() {
        return Ember.get(source, 'location');
      }
    };
  }

  /**
   * A utility component to add waypoints to the directions component.
   *
   * @class Waypoint
   * @namespace GMap
   * @module ember-google-maps/components/g-map/waypoint
   * @extends GMap.MapComponent
   */
  var _default = Ember.Component.extend({
    tagName: '',
    _type: 'waypoint',
    init() {
      this._super(...arguments);
      this.publicAPI = WaypointAPI(this);
      this._internalAPI._registerComponent(this.publicAPI);
    },
    willDestroyElement() {
      this._super(...arguments);
      this._internalAPI._unregisterComponent(this.publicAPI);
    }
  });
  _exports.default = _default;
});