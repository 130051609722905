define("soferuj/modules/test/official/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    testSettings: Ember.inject.controller('test.index'),
    metrics: Ember.inject.service(),
    //TODO: to a mixin? common fn
    isValidated: Ember.computed('model.evaluation', function () {
      let evaluation = this.get('model.evaluation');
      return !_lodash.default.isEmpty(evaluation);
    }),
    actions: {
      markQuestion(index, value) {
        let model = this.model;
        let question = model.get('questions')[index];
        Ember.set(question, 'answer', value);
      },
      startNextTest() {
        this.metrics.trackEvent({
          category: 'Test',
          action: 'start-next'
        });
        this.testSettings.nextTest();
        this.send('refreshModel');
      },
      evaluate() {
        let model = this.model;
        model.set('evaluation', {});
        model.set('test', 'official');
        const top = document.getElementById('main-toolbar');
        if (!!top && top.scrollIntoView) {
          top.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
        this.metrics.trackEvent({
          category: 'Test',
          action: 'completed'
        });
        model.save();
      }
    }
  });
  _exports.default = _default;
});