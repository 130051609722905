define("soferuj/modules/driving-school/types/add-school-dialog-state.enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ADD_SCHOOL_DIALOG_STATE = void 0;
  let ADD_SCHOOL_DIALOG_STATE;
  _exports.ADD_SCHOOL_DIALOG_STATE = ADD_SCHOOL_DIALOG_STATE;
  (function (ADD_SCHOOL_DIALOG_STATE) {
    ADD_SCHOOL_DIALOG_STATE[ADD_SCHOOL_DIALOG_STATE["CLOSED"] = 0] = "CLOSED";
    ADD_SCHOOL_DIALOG_STATE["ADD_REVIEW"] = "add-review";
    ADD_SCHOOL_DIALOG_STATE["ADD_VOTE"] = "add-vote";
  })(ADD_SCHOOL_DIALOG_STATE || (_exports.ADD_SCHOOL_DIALOG_STATE = ADD_SCHOOL_DIALOG_STATE = {}));
});