define("soferuj/modules/settings/index/remove-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XcL7Ok7o",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"remove-account layout-row layout-align-space-between-center\"],[12],[2,\"\\n\\tZmazať účet so všetkými údajmi?\\n\"],[6,[37,1],null,[[\"class\",\"primary\",\"onClick\"],[\"button small\",\"true\",[30,[36,0],[[32,0],\"openDialog\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\\tZmazať účet\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,7],[[32,0,[\"showDialog\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,6],null,[[\"class\",\"onClose\",\"clickOutsideToClose\"],[\"flex-gt-xs-40 flex-gt-md-30\",[30,[36,0],[[32,0],\"closeDialog\"],null],\"true\"]],[[\"default\"],[{\"statements\":[[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"Určite si praješ zmazať účet?\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\tPrídeš tak o všetky tvoje údaje zo šoféruj.sk a nebudeš sa už viac môcť prihlásiť.\\n\"]],\"parameters\":[]}]]],[6,[37,5],null,[[\"class\"],[\"layout-row\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[6,[37,1],null,[[\"onClick\"],[[30,[36,0],[[32,0],\"closeDialog\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Späť\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],null,[[\"raised\",\"primary\",\"onClick\"],[true,true,[30,[36,0],[[32,0],\"removeAccount\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\tZmazať účet\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"paper-button\",\"paper-toolbar-tools\",\"paper-toolbar\",\"paper-dialog-content\",\"paper-dialog-actions\",\"paper-dialog\",\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/settings/index/remove-account/template.hbs"
    }
  });
  _exports.default = _default;
});