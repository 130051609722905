define("ember-google-maps/templates/components/g-map/directions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IXfAI06Z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,3],null,[[\"directions\",\"route\",\"waypoint\"],[[35,5],[30,[36,4],[\"g-map/route\"],[[\"map\",\"_internalAPI\",\"gMap\",\"directions\"],[[35,8],[35,7],[35,6],[35,5]]]],[30,[36,4],[\"g-map/waypoint\"],[[\"_internalAPI\"],[[30,[36,3],null,[[\"_registerComponent\",\"_unregisterComponent\"],[[30,[36,1],[[32,0],[35,2]],null],[30,[36,1],[[32,0],[35,0]],null]]]]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"_unregisterWaypoint\",\"action\",\"_registerWaypoint\",\"hash\",\"component\",\"directions\",\"gMap\",\"_internalAPI\",\"map\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/directions.hbs"
    }
  });
  _exports.default = _default;
});