define("soferuj/modules/test/statistics/components/not-found-questions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KshE4U45",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[\"list-ul\"],[[\"class\"],[\"icon\"]]]],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"V kategórii \"],[10,\"b\"],[12],[1,[34,0]],[13],[2,\" sme nenašli žiadne chybné otázky.\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"Výborne! Nenašli sme žiadne chybné otázky.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"questionType\",\"fa-icon\",\"type\",\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/test/statistics/components/not-found-questions/template.hbs"
    }
  });
  _exports.default = _default;
});