define("ember-paper/components/paper-card", ["exports", "ember-paper/templates/components/paper-card"], function (_exports, _paperCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class PaperCard
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _paperCard.default,
    tagName: 'md-card'
  });
  _exports.default = _default;
});