define("soferuj/modules/test/components/user-ranking/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['user-ranking', 'flex', 'layout', 'layout-align-center-center'],
    medalRanks: [1, 2, 3]
  });
  _exports.default = _default;
});