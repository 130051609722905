define("soferuj/modules/test/history/test/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oompGKor",
    "block": "{\"symbols\":[\"question\",\"index\"],\"statements\":[[10,\"div\"],[14,0,\"route-test-training\"],[12],[2,\"\\n\"],[6,[37,12],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,13],null,[[\"title\",\"subtitle\",\"backLink\"],[[30,[36,12],[[30,[36,11],[[35,5,[\"test\"]],\"official\"],null],\"Oficiálny test\",\"Cvičný test\"],null],[30,[36,10],[\"č. \",[35,5,[\"number\"]]],null],\"test.history\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,9],[[35,5,[\"created-at\"]],\"D.M.YYYY HH:mm\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],null,[[\"class\"],[\"validated\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center\"],[12],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"evaluation\",\"isTrainingType\",\"class\",\"hideControls\"],[[35,5,[\"evaluation\"]],false,\"test-evaluation layout-row layout-wrap layout-padding flex\",true]]]],[2,\"\\n\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,5,[\"questions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,4],null,[[\"index\",\"questionNumber\",\"question\",\"class\",\"onQuestionMark\"],[[32,2],[30,[36,3],[[32,2],1],null],[32,1],\"test-question\",[30,[36,2],[[32,0],\"markQuestion\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"test/history/test/components/not-found\",\"page/page-content\",\"action\",\"add\",\"test/components/question-item\",\"test\",\"test/components/test-evaluation\",\"-track-array\",\"each\",\"moment-format\",\"concat\",\"eq\",\"if\",\"page/page-header\"]}",
    "meta": {
      "moduleName": "soferuj/modules/test/history/test/template.hbs"
    }
  });
  _exports.default = _default;
});