define("soferuj/modules/components/centered-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b4GsXUTe",
    "block": "{\"symbols\":[\"@class\",\"@icon\",\"@title\",\"@description\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"centered-message \",[32,1]]]],[12],[2,\"\\n  \"],[8,\"fa-icon\",[[24,0,\"top-icon\"]],[[\"@icon\"],[[32,2]]],null],[2,\"\\n\\n  \"],[10,\"h3\"],[12],[1,[32,3]],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,[32,4]],[13],[2,\"\\n\\n  \"],[18,5,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "soferuj/modules/components/centered-message/template.hbs"
    }
  });
  _exports.default = _default;
});