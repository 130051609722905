define("soferuj/modules/test/history/test/components/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TeWnYFlr",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"class\"],[\"test-history-not-found\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content layout-row layout-xs-column flex\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"layout layout-align-xs-center-center flex-40\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"file-alt\"],[[\"class\"],[\"icon\"]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"História tohto testu nebola nájdená\"],[13],[2,\"\\n\"],[6,[37,2],null,[[\"route\"],[\"test.history\"]],[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"raised\",\"primary\"],[true,true]],[[\"default\"],[{\"statements\":[[2,\"          Zobraziť moje testy\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"paper-button\",\"fa-icon\",\"link-to\",\"page/page-card\"]}",
    "meta": {
      "moduleName": "soferuj/modules/test/history/test/components/not-found/template.hbs"
    }
  });
  _exports.default = _default;
});