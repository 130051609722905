define("soferuj/models/driving-school/address", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    city: (0, _model.attr)('string'),
    villageId: (0, _model.attr)('number'),
    street: (0, _model.attr)('string'),
    streetNumber: (0, _model.attr)('number'),
    zipCode: (0, _model.attr)('string'),
    location: (0, _model.attr)(),
    description: (0, _model.attr)('string')
  });
  _exports.default = _default;
});