define("ember-paper/components/paper-ripple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "itQo+W5C",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"_parentFinder\"]]],null]],[2,\"\\n\\n\"],[11,\"div\"],[24,0,\"md-ripple-container\"],[4,[38,1],[[32,0,[\"setupContainer\"]]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"unbound\",\"did-insert\"]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-ripple/template.hbs"
    }
  });
  _exports.default = _default;
});