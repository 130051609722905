define("soferuj/adapters/test-history", ["exports", "soferuj/modules/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _adapter.default.extend({
    pathForType() {
      return 'tests/history';
    }
  });
  _exports.default = _default;
});