define("soferuj/modules/components/ember-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    chart: null,
    didInsertElement() {
      var ctx = document.getElementById(this.elementId + '-chart');
      var chart = new Chart(ctx, {
        type: this.type,
        data: this.data,
        options: this.options
      });

      // solves tooltip overflow (not fixed issue on github)
      chart.options.tooltips.bodyFontSize = 10;
      chart.options.tooltips.displayColors = false;
      this.set('chart', chart);
    },
    didUpdateAttrs() {
      this._super(...arguments);
      var chart = this.chart;
      chart.data = this.data;
      chart.update();
    }
  });
  _exports.default = _default;
});