define("soferuj/modules/contact/route", ["exports", "soferuj/modules/statics"], function (_exports, _statics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    titleToken: 'Kontaktné informácie',
    headTags: [{
      type: 'meta',
      tagId: 'description',
      attrs: {
        name: 'description',
        content: `${_statics.default.contact.email} | ${_statics.default.contact.phone} | ${_statics.default.contact.address} | ${_statics.default.contact.facebook}`
      }
    }]
  });
  _exports.default = _default;
});