define("soferuj/modules/pages/personal-data/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PagesPersonalData extends Ember.Route.extend({
    // anything which *must* be merged to prototype here
  }) {
    // normal class body definition here
  }
  _exports.default = PagesPersonalData;
});