define("soferuj/modules/test/components/question-answer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['answer'],
    classNameBindings: ['isMarked:marked-answer', 'isRight:right-answer', 'isWrong:wrong-answer'],
    isClickable: true,
    didReceiveAttrs() {
      this._super(...arguments);
      let question = this.question;

      // question is evaluated
      if (question.correct_answer) {
        this.set('isClickable', false);
      }
    },
    isMarked: Ember.computed('question.answer', function () {
      let answerNumber = this.answerNumber;
      let question = this.question;

      // Do not cut the ripple effect when item is focused
      // remove this, check focused prop and remove it too
      //Ember.run.later(() => {
      //	if (question.answer) { this.set('isClickable', false); }
      //}, 380);

      return question.answer === answerNumber && !question.correct_answer;
    }),
    isRight: Ember.computed('question.correct_answer', function () {
      let answerNumber = this.answerNumber;
      let question = this.question;
      return question.correct_answer === answerNumber;
    }),
    isWrong: Ember.computed('question.correct_answer', function () {
      let answerNumber = this.answerNumber;
      let question = this.question;
      return question.answer === answerNumber && question.answer !== question.correct_answer;
    }),
    questionAnswer: Ember.computed(function () {
      let answerNumber = this.answerNumber;
      return this.question[`answer_${answerNumber}`];
    })
  });
  _exports.default = _default;
});