define("ember-paper/templates/components/paper-radio-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "E+GOPShL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"md-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-off\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-on\"],[12],[13],[2,\"\\n  \"],[8,\"paper-ripple\",[],[[\"@center\",\"@fitRipple\"],[true,true]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"md-label\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"md-label\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"if\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-radio-base.hbs"
    }
  });
  _exports.default = _default;
});