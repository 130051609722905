define("ember-google-maps/components/g-map/overlay", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/overlay", "ember-google-maps/utils/options-and-events", "ember-google-maps/utils/helpers"], function (_exports, _mapComponent, _overlay, _optionsAndEvents, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    READY
  } = _mapComponent.MapComponentLifecycleEnum;

  /**
   * A wrapper for the google.maps.Overlay class.
   *
   * @class Overlay
   * @namespace GMap
   * @module ember-google-maps/components/g-map/overlay
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    layout: _overlay.default,
    _type: 'overlay',
    position: Ember.computed('lat', 'lng', _helpers.position),
    paneName: 'overlayMouseTarget',
    zIndex: 'auto',
    _targetPane: null,
    _contentId: Ember.computed(function () {
      return `ember-google-maps-overlay-${Ember.guidFor(this)}`;
    }),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([..._optionsAndEvents.ignoredOptions, 'paneName', 'zIndex']),
    init() {
      this._super(arguments);

      // Remove for 4.0
      (false && Ember.warn(`
The \`innerContainerStyle\` option has been removed. See the docs for examples of how to offset overlays relative to their coordinates.
https://ember-google-maps.sandydoo.me/docs/overlays/`, typeof this.innerContainerStyle === 'undefined', {
        id: 'inner-container-style-removed'
      }));
    },
    _addComponent() {
      let isFinishedDrawing = Ember.RSVP.defer();
      let _contentContainer = document.createElement('div');
      _contentContainer.setAttribute('id', Ember.get(this, '_contentId'));
      Ember.set(this, '_contentContainer', _contentContainer);
      let Overlay = new google.maps.OverlayView();

      // Google Maps runs these setup methods asynchronously. This makes it
      // impossible to schedule all of the DOM operations within one runloop.
      // That's why we provide noops and then run the setup process properly,
      // within a runloop, ourselves.
      Overlay.onAdd = () => {};
      Overlay.onRemove = Ember.run.bind(this, 'onRemove');
      Overlay.draw = () => Ember.run.join(this, setupOverlay);
      Ember.set(this, 'mapComponent', Overlay);
      Overlay.setMap(this.map);
      function setupOverlay() {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.onAdd();
        Ember.run.schedule('render', this, 'draw');

        // Set the normal draw function.
        Overlay.draw = () => Ember.run.join(this, () => Ember.run.scheduleOnce('render', this, 'draw'));
        Ember.run.schedule('afterRender', this, () => isFinishedDrawing.resolve(Overlay));
      }
      return isFinishedDrawing.promise;
    },
    _didAddComponent(_, options, events) {
      let payload = {
        map: this.map,
        publicAPI: this.publicAPI
      };
      (0, _optionsAndEvents.addEventListeners)(this._contentContainer, events, payload).forEach(_ref => {
        let {
          name,
          remove
        } = _ref;
        return this._eventListeners.set(name, remove);
      });
      return Ember.RSVP.resolve();
    },
    _updateComponent() {
      if (this.mapComponentLifecycle === READY) {
        this.mapComponent.draw();
      }
    },
    onAdd() {
      let panes = this.mapComponent.getPanes();
      Ember.set(this, '_targetPane', panes[this.paneName]);

      // Schedule to append the overlay container to the map pane.
      Ember.run.schedule('render', this, () => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this._targetPane.appendChild(this._contentContainer);
      });
    },
    draw() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      let overlayProjection = this.mapComponent.getProjection(),
        position = Ember.get(this, 'position'),
        point = overlayProjection.fromLatLngToDivPixel(position),
        zIndex = Ember.get(this, 'zIndex');
      this._contentContainer.style.cssText = `
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      z-index: ${zIndex};
      transform: translateX(${point.x}px) translateY(${point.y}px);
    `;
    },
    onRemove() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      let parentNode = this._contentContainer.parentNode;
      if (parentNode) {
        parentNode.removeChild(this._contentContainer);
      }
      this._contentContainer = null;
    }
  });
  _exports.default = _default;
});