define("soferuj/validations/truthy", ["exports", "ember-changeset-validations/utils/validation-errors"], function (_exports, _validationErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = truthyValidator;
  // @ts-expect-error

  // validators/custom.js
  function truthyValidator(options) {
    return (key, newValue) => {
      return newValue || (0, _validationErrors.default)(key, {
        type: 'truthy',
        value: newValue,
        context: options
      });
    };
  }
});