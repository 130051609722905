define("ember-math-helpers/helpers/fround", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fround = fround;
  /**
   * Executes `Math.fround` on the number passed to the helper.
   *
   * ```hbs
   * {{fround a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.fround`
   * @return {number} The fround of the passed number
   */
  function fround(_ref) {
    let [number] = _ref;
    return Math.fround(number);
  }
  var _default = Ember.Helper.helper(fround);
  _exports.default = _default;
});