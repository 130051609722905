define("soferuj/modules/register/user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7hFE0iuN",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"class\"],[\"route-register-user\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"login-register-container layout-column\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"header\"],[14,0,\"layout-row layout-align-start-center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"strong\"],[14,0,\"heading\"],[12],[2,\"Registrácia\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\\t\"],[1,[30,[36,1],null,[[\"model\",\"resetModel\"],[[35,0],\"resetModel\"]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"Už zaregistrovaný? \"],[6,[37,2],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Prihlásiť sa\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"register/user/components/user-form\",\"link-to\",\"page/page-content\"]}",
    "meta": {
      "moduleName": "soferuj/modules/register/user/template.hbs"
    }
  });
  _exports.default = _default;
});