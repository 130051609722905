define("soferuj/modules/statics", ["exports", "soferuj/config/environment", "crypto-js"], function (_exports, _environment, _cryptoJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ZAHMLENIE_FULL = void 0;
  const ZAHMLENIE_FULL = function () {
    return _cryptoJs.default.MD5(`${_environment.default.APP.ZAHMLENIE}So89fe:ruj`).toString();
  };
  _exports.ZAHMLENIE_FULL = ZAHMLENIE_FULL;
  var _default = {
    contact: {
      email: 'info@soferuj.sk',
      phone: '0951 025 308',
      address: 'Sídlo v Košiciach',
      facebook: 'https://www.facebook.com/soferuj.sk/',
      instagram: 'https://www.instagram.com/soferuj',
      twitter: 'https://twitter.com/soferuj'
    },
    questionsTypeLocal: {
      all: 'všetky',
      legal: 'legislatíva',
      sign: 'značky',
      crossing: 'križovatky'
    }
  };
  _exports.default = _default;
});