define("soferuj/modules/login/activation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C+dU/WGg",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"class\"],[\"route-login activation\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"login-register-container layout-column\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"header\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"activation-logo\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[30,[36,2],[[35,0,[\"icon\"]]],[[\"prefix\",\"size\",\"class\"],[[35,0,[\"iconPrefix\"]],\"5x\",[35,0,[\"iconClass\"]]]]]],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\\t\\t\"],[10,\"strong\"],[12],[1,[35,0,[\"title\"]]],[13],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[35,3],\"failed\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[10,\"p\"],[12],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"resendActivation\"],null],[12],[1,[35,0,[\"subtitle\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[10,\"p\"],[12],[1,[35,0,[\"subtitle\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"status\",\"action\",\"fa-icon\",\"state\",\"eq\",\"if\",\"page/page-content\"]}",
    "meta": {
      "moduleName": "soferuj/modules/login/activation/template.hbs"
    }
  });
  _exports.default = _default;
});