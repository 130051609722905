define("soferuj/modules/driving-school/components/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uQRwSpAE",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"class\"],[\"driving-school-not-found\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content layout-row layout-xs-column flex\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"layout layout-align-xs-center-center flex-40\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"car\"],[[\"class\"],[\"icon\"]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"Autoškola nebola nájdená\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"Autoškolu ktorú hľadáte sme nenašli. Skúste navštíviť náš \"],[6,[37,2],null,[[\"route\"],[\"driving-schools\"]],[[\"default\"],[{\"statements\":[[2,\"zoznam autoškôl\"]],\"parameters\":[]}]]],[2,\" a autoškolu vyhľadať.\"],[13],[2,\"\\n\"],[6,[37,2],null,[[\"route\"],[\"driving-schools\"]],[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"raised\",\"primary\"],[true,true]],[[\"default\"],[{\"statements\":[[2,\"          Vyhľadať autoškolu\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"paper-button\",\"fa-icon\",\"link-to\",\"page/page-card\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/components/not-found/template.hbs"
    }
  });
  _exports.default = _default;
});