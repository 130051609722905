define("soferuj/modules/test/official/route", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    titleToken(model) {
      return `Test č. ${model.get('number')}`;
    },
    beforeModel(transition) {
      let testSettings = this.controllerFor('test.index');
      const testNumber = transition.to.params.testNumber;

      // set default test properties if url contains test number
      if (testNumber && testSettings.get('options.number') != testNumber) {
        testSettings.set('type', 'official');
        testSettings.set('options.type', testNumber < 36 ? 'ab' : 'cdt');
        testSettings.set('options.number', {
          label: testNumber,
          value: Number(testNumber)
        });
      }
    },
    model(params, transition) {
      let testSettings = this.controllerFor('test.index');
      let {
        type,
        number
      } = testSettings.get('options');
      let officialId = null;
      if (number.value !== 0) {
        officialId = number.value;
      } else {
        officialId = type === 'ab' ? _lodash.default.random(1, 35) : _lodash.default.random(36, 60);
      }
      this.store.unloadAll();

      // set test number in URL
      if (!transition.to.params.testNumber) {
        this.transitionTo('test.official.number', officialId);
        return;
      }
      return this.store.queryRecord('test', {
        test_number: officialId
      });
    },
    actions: {
      refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});