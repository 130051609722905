define("soferuj/modules/driving-school/index/components/licence-type-item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  const TYPE_ICONS = {
    a: 'motorcycle',
    b: 'car-side',
    c: 'truck',
    d: 'bus-alt',
    t: 'tractor'
  };
  class LicenceTypeItem extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "mainType", this.args.licenceType.name.charAt(0).toLowerCase());
      _defineProperty(this, "icon", TYPE_ICONS[this.mainType]);
    }
  }
  _exports.default = LicenceTypeItem;
});