define("ember-paper/components/paper-speed-dial-actions", ["exports", "ember-paper/templates/components/paper-speed-dial-actions"], function (_exports, _paperSpeedDialActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _paperSpeedDialActions.default,
    tagName: 'md-fab-actions'
  });
  _exports.default = _default;
});