define("soferuj/modules/info/road-signs/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    roadSigns: [{
      category: 'Výstražné značky',
      id: 'vystrazne-znacky',
      description: `
        Výstražné značky upozorňujú účastníkov cestnej premávky na miesto, 
        kde môže hroziť nebezpečenstvo, ktoré je ťažko včas rozoznateľné aj pri 
        dodržaní primeranej opatrnosti.
      `,
      items: [{
        category: 'I. Všeobecné výstražné značky',
        id: 'vseobecne-vystrazne-znacky',
        items: [{
          tag: '101',
          name: 'Nebezpečenstvo',
          image: '101.png',
          description: `
                Značka upozorňuje na iné nebezpečenstvo ako na to, 
                na ktoré možno upozorniť vhodnou výstražnou značkou.
              `
        }]
      }, {
        category: 'I. 1. Stavebno-technické vlastnosti cesty',
        id: '',
        items: [{
          tag: '110',
          name: 'Zákruta',
          image: '110.png',
          description: `
                Značka upozorňuje na nebezpečnú zákrutu vo
                vyznačenom smere, ktorej bezpečný prejazd si
                vyžaduje výrazné zníženie rýchlosti jazdy.`
        }, {
          tag: '111',
          name: 'Dvojitá zákruta',
          image: '111.png',
          description: `
                Značka upozorňuje na dve alebo viac zákrut
                nasledujúcich bezprostredne za sebou vo vyznačenom
                smere, ktorých bezpečný prejazd si vyžaduje výrazné
                zníženie rýchlosti jazdy.
                Ak je zákrut viac, vyznačí sa dĺžka nebezpečného
                úseku na dodatkovej tabuľke Dĺžka úseku.
              `
        }, {
          tag: '112',
          name: 'Klesanie (Vzor)',
          image: '112.png',
          description: `Značka upozorňuje na nebezpečné klesanie.`
        }, {
          tag: '113',
          name: 'Stúpanie (Vzor)',
          image: '113.png',
          description: `Značka upozorňuje na nebezpečné stúpanie.`
        }, {
          tag: '114',
          name: 'Zúžená vozovka',
          image: '114.png',
          description: `Značka upozorňuje na zúženú vozovku z vyznačenej strany alebo strán.`
        }, {
          tag: '115',
          name: 'Nerovnosť vozovky',
          image: '115.png',
          description: `
                Značka upozorňuje na nerovnosti povrchu vozovky
                ako sú výtlky, výmole alebo vyjazdené koľaje.
              `
        }, {
          tag: '116',
          name: 'Nebezpečná krajnica',
          image: '116.png',
          description: `
                Značka upozorňuje na zníženú, nespevnenú alebo
                inak nebezpečnú krajnicu.
              `
        }]
      }, {
        category: 'I. 2. Stav vozovky a externé vplyvy',
        id: '',
        items: [{
          tag: '120',
          name: 'Nebezpečenstvo šmyku',
          image: '120.png',
          description: `Značka upozorňuje na zvýšené riziko vzniku šmyku vozidla aj na suchej ceste.`
        }, {
          tag: '121',
          name: 'Sneh alebo poľadovica',
          image: '121.png',
          description: `
                Značka upozorňuje na ujazdený alebo zľadovatený
                sneh alebo na poľadovicu.
              `
        }, {
          tag: '122',
          name: 'Štrk na vozovke',
          image: '122.png',
          description: `Značka upozorňuje na zvýšené riziko odletujúceho štrku spod kolies vozidla`
        }, {
          tag: '125',
          name: 'Padnuté kamene',
          image: '125.png',
          description: `
                Značka upozorňuje na zvýšené riziko výskytu
                kameňov v dôsledku zosuvu alebo padania kameňov na vozovku.
                Ak je riziko prevažne na ľavej strane vozovky, symbol sa vyobrazuje zrkadlovo.
              `
        }, {
          tag: '126',
          name: 'Bočný vietor',
          image: '126.png',
          description: `Značka upozorňuje na zvýšené riziko silného alebo nárazového vetra.`
        }]
      }, {
        category: 'I. 3. Organizácia a stav premávky',
        id: '',
        items: [{
          tag: '130',
          name: 'Kolóny',
          image: '130.png',
          description: `Značka upozorňuje na vytváranie kolóny vozidiel.`
        }, {
          tag: '131',
          name: 'Práca',
          image: '131.png',
          description: `
                Značka upozorňuje na prácu na ceste alebo v jej
                blízkosti, ktorá môže ohroziť bezpečnosť cestnej
                premávky alebo pri ktorej môžu byť ohrození
                pracovníci, ktorí túto činnosť vykonávajú
              `
        }, {
          tag: '132',
          name: 'Obojsmerná premávka',
          image: '132.png',
          description: `
                Značka upozorňuje, že na rozdiel od doterajšieho
                úseku je cestná premávka vedená v oboch smeroch.
              `
        }, {
          tag: '135',
          name: 'Svetelné signály',
          image: '135.png',
          description: `Značka upozorňuje na riadenie cestnej premávky svetelnými signálmi.`
        }, {
          tag: '136',
          name: 'Križovatka',
          image: '136.png',
          description: `
                Značka upozorňuje na križovatku, kde nie je prednosť
                v jazde upravená dopravnými značkami a uplatňujú sa
                všeobecné pravidlá o prednosti v jazde.
              `
        }]
      }, {
        category: 'I. 4. Pohyb na ceste',
        id: '',
        items: [{
          tag: '140',
          name: 'Chodci',
          image: '140.png',
          description: `Značka upozorňuje na zvýšený pohyb chodcov na vozovke.`
        }, {
          tag: '141',
          name: 'Deti',
          image: '141.png',
          description: `Značka upozorňuje na zvýšený pohyb detí a zvýšené riziko ich neočakávaného vstupu do vozovky.`
        }, {
          tag: '142',
          name: 'Priechod pre chodcov',
          image: '142.png',
          description: `Značka upozorňuje na priechod pre chodcov.`
        }, {
          tag: '143',
          name: 'Cyklisti',
          image: '143.png',
          description: `
                Značka upozorňuje na zvýšený pohyb cyklistov alebo
                na miesto, kde cyklisti prechádzajú cez cestu alebo na
                ňu vchádzajú.
              `
        }, {
          tag: '145',
          name: 'Zver',
          image: '145_1.png',
          description: `Značka upozorňuje na zvýšené nebezpečenstvo neočakávaného pohybu voľne žijúcej zveri cez cestu.`
        }, {
          tag: '145',
          name: 'Zver',
          image: '145_2.png',
          description: `Značka upozorňuje na zvýšené nebezpečenstvo neočakávaného pohybu voľne žijúcej zveri cez cestu.`
        }, {
          tag: '146',
          name: 'Hospodárske zvieratá',
          image: '146.png',
          description: `Značka upozorňuje na častý výskyt hnaných hospodárskych zvierat na ceste.`
        }]
      }, {
        category: 'II. Priecestné značky',
        id: '',
        items: [{
          tag: '151',
          name: 'Priecestie',
          image: '151.png',
          description: `Značka upozorňuje na priecestie.`
        }, {
          tag: '152',
          name: 'Návesť pred priecestím',
          image: '152.png',
          description: `
                Značka informuje o vzdialenosti k priecestiu. <br/>
                Značka s vyznačenými tromi pruhmi informuje o
                vzdialenosti 240 m k priecestiu, značka s dvoma
                pruhmi o vzdialenosti 160 m k priecestiu a značka
                s jedným pruhom o vzdialenosti 80 m k priecestiu.<br/>
                V prípade iných ako uvedených vzdialeností sa
                skutočná vzdialenosť uvedie v hornej časti značky.
              `
        }, {
          tag: '153',
          name: 'Výstražný kríž',
          image: '153.png',
          description: `
              Značka označuje priecestie.<br/>
              Ak je na značke v strede kríža vyobrazený symbol
              blesku, značka tiež informuje o tom, že dráha je
              elektrifikovaná a v jej priestore je vysoké napätie.<br/>
              Ak z priestorových dôvodov nie je vhodné výstražný
              kríž umiestniť zvisle, umiestni sa vodorovne.
              `
        }]
      }]
    }, {
      category: 'Regulačné značky',
      id: 'regulacne-znacky',
      description: `
        Regulačné značky ukladajú účastníkom cestnej premávky príkazy, 
        zákazy alebo obmedzenia alebo im umožňujú výnimky zo všeobecných pravidiel cestnej premávky.
      `,
      items: [{
        category: 'I. Značky o dávaní prednosti v jazde',
        id: '',
        items: [{
          tag: '201',
          name: 'Daj prednosť v jazde!',
          image: '201.png',
          description: `Značka ukladá vodičovi povinnosť dať prednosť v jazde.`
        }, {
          tag: '202',
          name: 'Stoj, daj prednosť v jazde!',
          image: '202.png',
          description: `Značka ukladá vodičovi povinnosť zastaviť vozidlo a dať prednosť v jazde.`
        }, {
          tag: '203',
          name: 'Prednosť protiidúcich vozidiel',
          image: '203.png',
          description: `Značka ukladá vodičovi povinnosť dať prednosť v jazde protiidúcim vozidlám.`
        }]
      }, {
        category: 'Príkazové a zákazové značky - II. 1. Smer jazdy',
        id: '',
        items: [{
          tag: '210',
          name: 'Prikázaný smer jazdy',
          image: '210.png',
          description: `Značka prikazuje jazdu vo vyznačenom smere alebo vyznačených smeroch.`
        }, {
          tag: '211',
          name: 'Prikázaný smer odbočenia',
          image: '211.png',
          description: `Značka prikazuje odbočenie vo vyznačenom smere.`
        }, {
          tag: '212',
          name: 'Prikázaný smer obchádzania',
          image: '212.png',
          description: `Značka prikazuje obchádzanie vo vyznačenom smere.`
        }, {
          tag: '213',
          name: 'Kruhový objazd',
          image: '213.png',
          description: `Značka označuje kruhový objazd a prikazuje smer jazdy v smere vyznačenom šípkami na značke.`
        }, {
          tag: '215',
          name: 'Zákaz odbočenia',
          image: '215.png',
          description: `Značka zakazuje odbočenie vo vyznačenom smere.`
        }, {
          tag: '216',
          name: 'Zákaz otáčania',
          image: '216.png',
          description: `Značka zakazuje otáčanie.`
        }]
      }, {
        category: 'Príkazové a zákazové značky - II. 2. Špeciálne cestičky alebo pruhy',
        id: '',
        items: [{
          tag: '220',
          name: 'Cestička pre chodcov',
          image: '220.png',
          description: `
                Značka označuje komunikáciu určenú pre chodcov
                a prikazuje chodcovi použiť takto označenú cestičku.
              `
        }, {
          tag: '221',
          name: 'Cyklistická komunikácia',
          image: '221.png',
          description: `
                Značka označuje cestičku pre cyklistov alebo
                cyklistický pruh a prikazuje cyklistovi použiť takto
                označenú cestičku alebo pruh.
              `
        }, {
          tag: '222',
          name: 'Spoločná cestička pre chodcov a cyklistov',
          image: '222.png',
          description: `
                Značka označuje spoločnú komunikáciu určenú pre
                chodcov a cyklistov a prikazuje im ju použiť. 
              `
        }, {
          tag: '223',
          name: 'Oddelená cestička pre chodcov a cyklistov',
          image: '223.png',
          description: `
                Značka označuje oddelenú komunikáciu určenú pre
                chodcov a cyklistov a vyznačuje, ktorý pruh je určený
                pre chodcov a ktorý pruh je určený pre cyklistov;
                cyklistom a chodcom prikazuje použiť časť pre nich
                určenú. 
              `
        }, {
          tag: '224',
          name: 'Vyhradený pruh pre verejnú dopravu',
          image: '224.png',
          description: `
                Značka označuje vyhradený pruh pre vozidlá
                pravidelnej verejnej dopravy osôb a prikazuje im ho
                prednostne použiť.
              `
        }, {
          tag: '225',
          name: 'Koniec špeciálnej cestičky alebo pruhu',
          image: '225.png',
          description: `
                Značka ukončuje špeciálnu cestičku alebo pruh.<br/>
                Na značke sa použije zhodný symbol z týchto značiek
                doplnený červeným šikmým pruhom.
              `
        }]
      }, {
        category: 'Príkazové a zákazové značky - II. 3. Zákazy vjazdu',
        id: '',
        items: [{
          tag: '230',
          name: 'Zákaz vjazdu',
          image: '230.png',
          description: `Značka zakazuje vjazd všetkými vozidlami`
        }, {
          tag: '231',
          name: 'Zákaz vjazdu pre všetky vozidlá',
          image: '231.png',
          description: `Značka zakazuje vjazd všetkými vozidlami v oboch smeroch jazdy. `
        }, {
          tag: '232',
          name: 'Zákaz vjazdu pre všetky motorové vozidlá',
          image: '232.png',
          description: `Značka zakazuje vjazd všetkými motorovými vozidlami.`
        }, {
          tag: '233',
          name: 'Zákaz vjazdu (vstupu) pre',
          image: '233.png',
          description: `
                Značka zakazuje vjazd alebo vstup vozidlami alebo
                účastníkmi cestnej premávky vyznačeným
                symbolom.
              `
        }, {
          tag: '234',
          name: 'Zákaz vjazdu pre vozidlá prepravujúce nebezpečné veci',
          image: '234.png',
          description: `
                Značka zakazuje vjazd vozidlami prepravujúcimi
                nebezpečné veci, ktoré musia byť označené podľa
                Európskej dohody o medzinárodnej cestnej preprave
                nebezpečných vecí (ADR)
              `
        }, {
          tag: '235',
          name: 'Zákaz vjazdu pre vozidlá prepravujúce náklad, ktorý môže spôsobiť znečistenie vody',
          image: '235.png',
          description: `
                Značka zakazuje vjazd vozidlami prepravujúcimi
                najmenej 60 litrov nákladu, ktorý môže spôsobiť
                znečistenie vody.
                Iné množstvo alebo druh nákladu možno vyznačiť na
                dodatkovej tabuľke.
              `
        }, {
          tag: '240',
          name: 'Maximálna hmotnosť',
          image: '240.png',
          description: `
                Značka zakazuje vjazd vozidlom alebo jazdnou
                súpravou, ktorých okamžitá hmotnosť vrátane
                nákladu, vodiča a cestujúcich presahuje vyznačenú
                hranicu na značke.<br/>
                Pri jazdnej súprave sa o súpravou, ktorých okamžitá hmotnosť vrátane
                nákladu, vodiča a cestujúcich presahuje vyznačenú
                hranicu na značke.
                Pri jazdnej súprave sa obmedzenie hmotnosti
                vzťahuje na každé vozidlo zapojenej súpravy
                osobitne.<br/>
                Pri jazdnej súprave tvorenej ťahačom a návesom sa
                maximálna hmotnosť vzťahuje na okamžitú hmotnosť
                ťahača a hmotnosť návesu pripadajúcu na ťahač a na
                zostatkovú hmotnosť návesu osobitne.
                Pri jazdnej súprave tvorenej ťahačom a návesom sa
                maximálna hmotnosť vzťahuje na okamžitú hmotnosť
                ťahača a hmotnosť návesu pripadajúcu na ťahač a na
                zostatkovú hmotnosť návesu osobitne.
              `
        }, {
          tag: '241',
          name: 'Maximálna hmotnosť na nápravu',
          image: '241.png',
          description: `
                Značka zakazuje vjazd vozidlom, ktorého okamžitá
                hmotnosť vrátane nákladu, vodiča a cestujúcich
                pripadajúca na najviac zaťaženú nápravu presahuje
                vyznačenú hranicu.
              `
        }, {
          tag: '242',
          name: 'Maximálna šírka',
          image: '242.png',
          description: `
                Značka zakazuje vjazd vozidlom, ktorého okamžitá
                šírka vrátane nákladu presahuje vyznačenú hranicu.
              `
        }, {
          tag: '243',
          name: 'Maximálna výška',
          image: '243.png',
          description: `
                Značka zakazuje vjazd vozidlom, ktorého okamžitá
                výška vrátane nákladu presahuje vyznačenú hranicu.
              `
        }, {
          tag: '244',
          name: 'Maximálna dĺžka',
          image: '244.png',
          description: `
                Značka zakazuje vjazd vozidlom alebo súpravou
                vozidiel, ktorých okamžitá dĺžka vrátane nákladu
                presahuje vyznačenú hranicu
              `
        }, {
          tag: '245',
          name: 'Povinné zastavenie vozidla',
          image: '245.png',
          description: `
                Značka zakazuje pokračovať v jazde bez zastavenia vozidla.<br/>
                Na značke sa stručne uvádza dôvod zastavenia.
              `
        }, {
          tag: '248',
          name: 'Nízkoemisná zóna',
          image: '248.png',
          description: `
                Značka označuje začiatok nízkoemisnej zóny, v ktorej
                je premávka vozidiel regulovaná podľa zákona č.
                137/2010 Z. z. o ovzduší v znení neskorších
                predpisov.
              `
        }, {
          tag: '249',
          name: 'Koniec nízkoemisnej zóny',
          image: '249.png',
          description: `Značka označuje koniec nízkoemisnej zóny`
        }]
      }, {
        category: 'Príkazové a zákazové značky - II. 4. Spôsob jazdy',
        id: '',
        items: [{
          tag: '250',
          name: 'Najnižšia dovolená rýchlosť',
          image: '250.png',
          description: `
                Značka prikazuje jazdiť rýchlosťou nie nižšou ako
                vyznačenou rýchlosťou v kilometroch za hodinu; to
                neplatí, ak je to potrebné z hľadiska bezpečnosti
                a plynulosti cestnej premávky.<br/>
                Značka zároveň ukončuje platnosť predchádzajúcej
                značky Najnižšia dovolená rýchlosť.
              `
        }, {
          tag: '251',
          name: 'Snehové reťaze',
          image: '251.png',
          description: `
                Značka prikazuje použiť pri jazde snehové reťaze
                alebo iné rovnocenné zariadenie minimálne na
                kolesách jednej hnacej nápravy.
              `
        }, {
          tag: '253',
          name: 'Najvyššia dovolená rýchlosť',
          image: '253.png',
          description: `
                Značka zakazuje jazdiť rýchlosťou vyššou ako
                vyznačenou rýchlosťou v kilometroch za hodinu.<br/>
                Značka zároveň ukončuje platnosť predchádzajúcej
                značky Najvyššia dovolená rýchlosť.
              `
        }, {
          tag: '254',
          name: 'Zákaz predchádzania',
          image: '254.png',
          description: `
                Značka zakazuje predchádzať vľavo motorové
                vozidlo okrem motocykla bez postranného vozíka.
              `
        }, {
          tag: '255',
          name: 'Zákaz predchádzania pre nákladné vozidlá',
          image: '255.png',
          description: `
                Značka zakazuje predchádzať vľavo s nákladným
                vozidlom s najväčšou prípustnou celkovou
                hmotnosťou prevyšujúcou 3,5 t motorové vozidlo
                okrem motocykla bez postranného vozíka.
              `
        }, {
          tag: '256',
          name: 'Minimálna vzdialenosť medzi vozidlami',
          image: '256.png',
          description: `
                Značka zakazuje jazdu vozidlami s najväčšou
                prípustnou celkovou hmotnosťou prevyšujúcou 3,5 t
                jazdiacimi za sebou vo vzdialenosti kratšej ako je
                vyznačená na značke.
              `
        }, {
          tag: '260',
          name: 'Koniec najnižšej dovolenej rýchlosti',
          image: '260.png',
          description: `
                Značka ukončuje príkaz vyplývajúci
                z predchádzajúcej značky Najnižšia dovolená rýchlosť 50.
              `
        }, {
          tag: '261',
          name: 'Koniec snehových reťazí',
          image: '261.png',
          description: `
                Značka ukončuje príkaz vyplývajúci
                z predchádzajúcej značky Snehové reťaze 
              `
        }, {
          tag: '263',
          name: 'Koniec najvyššej dovolenej rýchlosti',
          image: '263.png',
          description: `
                Značka ukončuje zákaz vyplývajúci
                z predchádzajúcej značky Najvyššia dovolená rýchlosť 80
              `
        }, {
          tag: '264',
          name: 'Koniec zákazu predchádzania',
          image: '264.png',
          description: `
              Značka ukončuje zákaz vyplývajúci
                z predchádzajúcej značky Zákaz predchádzania
              `
        }, {
          tag: '265',
          name: 'Koniec zákazu predchádzania pre nákladné vozidlá',
          image: '265.png',
          description: `
                Značka ukončuje zákaz vyplývajúci
                z predchádzajúcej značky Zákaz predchádzania
                pre nákladné vozidlá
              `
        }, {
          tag: '267',
          name: 'Koniec viacerých zákazov',
          image: '267.png',
          description: `
                Značka ukončuje zákazy vyplývajúce zo značiek
                Najvyššia dovolená rýchlosť, Zákaz
                predchádzania, Zákaz predchádzania pre
                nákladné vozidlá, Minimálna vzdialenosť medzi
                vozidlami a reguláciu vyplývajúcu z pruhových značiek.
              `
        }, {
          tag: '268',
          name: 'Zóna najvyššej dovolenej rýchlosti',
          image: '268.png',
          description: `
                Značka označuje začiatok zóny, v ktorej je zakázané
                jazdiť rýchlosťou vyššou ako vyznačenou rýchlosťou
                v kilometroch za hodinu.
              `
        }, {
          tag: '269',
          name: 'Koniec zóny najvyššej dovolenej rýchlosti',
          image: '269.png',
          description: `Značka označuje koniec zóny najvyššej dovolenej rýchlosti.`
        }]
      }, {
        category: 'Príkazové a zákazové značky - II. 5. Statická doprava',
        id: '',
        items: [{
          tag: '270',
          name: 'Zákaz zastavenia',
          image: '270.png',
          description: `
                Značka zakazuje zastavenie a státie.
                Zákaz platí aj na priľahlý chodník.
              `
        }, {
          tag: '271',
          name: 'Zákaz státia',
          image: '271.png',
          description: `
                Značka zakazuje státie.
                Zákaz platí aj na priľahlý chodník.
              `
        }, {
          tag: '272',
          name: 'Parkovanie',
          image: '272.png',
          description: `
                Značka označuje miesto alebo priestor, kde je
                dovolené zastavenie a státie vozidiel.<br/>
                Ak je pod značkou umiestnená dodatková tabuľka so
                symbolom osoby so zdravotným postihnutím
                , značka označuje vyhradené parkovanie, kde
                je dovolené zastavenie a státie len vozidiel
                s parkovacím preukazom.<br/>
                Vhodným spôsobom možno označiť parkovaciu
                garáž alebo parkovací dom.
              `
        }, {
          tag: '273',
          name: 'Parkovanie na chodníku',
          image: '273.png',
          description: `
                Značka umožňuje státie vozidiel s najväčšou
                prípustnou celkovou hmotnosťou neprevyšujúcou
                3,5 t na chodníku a vyznačeným symbolom označuje
                spôsob ich státia.<br/>
                Ak je pod značkou umiestnená dodatková tabuľka so
                symbolom osoby so zdravotným postihnutím
                , značka označuje vyhradené parkovanie, kde
                je dovolené zastavenie a státie len vozidiel
                s parkovacím preukazom.
              `
        }, {
          tag: '275',
          name: 'Zóna zákazu státia',
          image: '275.png',
          description: `
                Značka označuje začiatok zóny, v ktorej je zakázané
                státie okrem státia na vyznačených parkovacích
                miestach.
              `
        }, {
          tag: '276',
          name: 'Koniec zóny zákazu státia',
          image: '276.png',
          description: `Značka označuje koniec zóny zákazu státia.`
        }, {
          tag: '277',
          name: 'Parkovacia zóna',
          image: '277.png',
          description: `
                Značka označuje začiatok zóny, v ktorej je státie
                vozidiel dovolené len na vyznačených parkovacích
                miestach a len za určitých podmienok uvedených na
                dodatkovej tabuľke.
              `
        }, {
          tag: '278',
          name: 'Koniec parkovacej zóny',
          image: '278.png',
          description: `Značka označuje koniec parkovacej zóny.`
        }]
      }, {
        category: 'III. Značky o jazde po krajnici',
        id: '',
        items: [{
          tag: '280',
          name: 'Jazda po krajnici',
          image: '280.png',
          description: `
                Značka umožňuje používať krajnicu ako jazdný pruh.
                V úseku platnosti značky sa krajnica považuje za
                súčasť vozovky a značka Pozdĺžna súvislá čiara, ktorá
                vyznačuje okraj vozovky, má význam značky
                Pozdĺžna prerušovaná čiara.
              `
        }, {
          tag: '281',
          name: 'Opustite krajnicu',
          image: '281.png',
          description: `
                Značka prikazuje vodičovi krajnicu čo najskôr opustiť.
                V úseku platnosti značky sa krajnica považuje za
                súčasť vozovky a značka Pozdĺžna súvislá čiara,
                ktorá vyznačuje okraj vozovky, má význam značky
                Pozdĺžna súvislá čiara doplnená prerušovanou čiarou
                (sprava).
              `
        }, {
          tag: '282',
          name: 'Koniec jazdy po krajnici',
          image: '282.png',
          description: `
                Značka končí platnosť predchádzajúcej značky Jazda
                po krajnici a Opustite krajnicu.
              `
        }, {
          tag: '283',
          name: 'Obchádzanie prekážky po krajnici',
          image: '283.png',
          description: `
                Značka prikazuje vodičovi jazdiacemu v pravom
                jazdnom pruhu použiť na obchádzanie prekážky
                krajnicu; vodičovi jazdiacemu v inom ako pravom
                jazdnom pruhu značka prikazuje prejsť do
                vedľajšieho jazdného pruhu spôsobom vyznačeným
                na značke.
              `
        }]
      }]
    }, {
      category: 'Informačné značky',
      id: 'informacne-znacky',
      description: `
        Informačné značky vymedzujú miesta, kde platia osobitné pravidlá cestnej premávky, 
        poskytujú účastníkom cestnej premávky informácie dopravného významu alebo slúžia 
        na ich orientáciu; z informačnej značky môže tiež vyplývať regulácia.
      `,
      items: [{
        category: 'I. Značky o prednosti v jazde',
        id: '',
        items: [{
          tag: '301',
          name: 'Križovatka s prednosťou v jazde',
          image: '301.png',
          description: `Značka informuje o prednosti v jazde v najbližšej križovatke.`
        }, {
          tag: '302',
          name: 'Hlavná cesta',
          image: '302.png',
          description: `Značka označuje začiatok alebo priebeh hlavnej cesty.`
        }, {
          tag: '303',
          name: 'Koniec hlavnej cesty',
          image: '303.png',
          description: `Značka informuje o ukončení hlavnej cesty.`
        }, {
          tag: '304',
          name: 'Prednosť pred protiidúcimi vozidlami',
          image: '304.png',
          description: `Značka informuje vodiča o prednosti v jazde pred protiidúcimi vozidlami.`
        }]
      }, {
        category: 'II. Značky o cestách s osobitnými pravidlami',
        id: '',
        items: [{
          tag: '305',
          name: 'Obec',
          image: '305.png',
          description: `
                Značka označuje miesto, od ktorého platia pravidlá
                cestnej premávky o správaní sa v obci a informuje
                o názve obce alebo miestnej časti obce tvoriacej
                samostatný sídelný útvar.
              `
        }, {
          tag: '306',
          name: 'Koniec obce',
          image: '306.png',
          description: `
                Značka označuje miesto, kde sa končí platnosť
                pravidiel cestnej premávky o správaní sa v obci
                a informuje o názve obce alebo miestnej časti obce
                tvoriacej samostatný sídelný útvar.
              `
        }, {
          tag: '307',
          name: 'Označenie obce v jazyku národnostnej menšiny',
          image: '307.png',
          description: `
                Značka informuje o názve obce v jazyku
                národnostnej menšiny na začiatku obce
              `
        }, {
          tag: '308',
          name: 'Označenie konca obce v jazyku národnostnej menšiny',
          image: '308.png',
          description: `
                Značka informuje o názve obce v jazyku
                národnostnej menšiny na konci obce.
              `
        }, {
          tag: '309',
          name: 'Diaľnica',
          image: '309.png',
          description: `
                Značka označuje miesto, od ktorého platia pravidlá
                cestnej premávky o správaní sa na diaľnici a miesto,
                od ktorého užívanie diaľnice podlieha úhrade
                diaľničnou známkou.
              `
        }, {
          tag: '310',
          name: 'Koniec diaľnice',
          image: '310.png',
          description: `Značka označuje koniec diaľnice.`
        }, {
          tag: '311',
          name: 'Cesta pre motorové vozidlá',
          image: '311.png',
          description: `
                Značka označuje miesto, od ktorého platia pravidlá
                cestnej premávky o správaní sa na ceste pre
                motorové vozidlá.
              `
        }, {
          tag: '312',
          name: 'Koniec cesty pre motorové vozidlá',
          image: '312.png',
          description: `Značka označuje koniec cesty pre motorové vozidlá`
        }, {
          tag: '313',
          name: 'Úsek diaľnice spoplatnený diaľničnou známkou',
          image: '313.png',
          description: `
                Značka označuje miesto, od ktorého užívanie
                diaľnice podlieha úhrade diaľničnou známkou.
              `
        }, {
          tag: '314',
          name: 'Úsek diaľnice nespoplatnený diaľničnou známkou',
          image: '314.png',
          description: `
                Značka označuje miesto, od ktorého užívanie
                diaľnice nepodlieha úhrade diaľničnou známkou.
              `
        }, {
          tag: '315',
          name: 'Obytná zóna',
          image: '315.png',
          description: `
                Značka označuje miesto, od ktorého platia osobitné
                ustanovenia o cestnej premávke v obytnej zóne.
              `
        }, {
          tag: '316',
          name: 'Koniec obytnej zóny',
          image: '316.png',
          description: `Značka označuje koniec obytnej zóny.`
        }, {
          tag: '317',
          name: 'Pešia zóna',
          image: '317.png',
          description: `
                Značka označuje miesto, od ktorého platia osobitné
                ustanovenia o cestnej premávke v pešej zóne.
              `
        }, {
          tag: '318',
          name: 'Koniec pešej zóny',
          image: '318.png',
          description: `Značka označuje koniec pešej zóny.`
        }, {
          tag: '319',
          name: 'Školská zóna',
          image: '319.png',
          description: `
                Značka označuje miesto, od ktorého platia osobitné
                ustanovenia o cestnej premávke v školskej zóne.
              `
        }, {
          tag: '320',
          name: 'Koniec školskej zóny',
          image: '320.png',
          description: `Značka označuje koniec školskej zóny.`
        }, {
          tag: '321',
          name: 'Jednosmerná cesta',
          image: '321.png',
          description: `
                Značka označuje cestu s jednosmernou premávkou
                a vyznačuje dovolený smer jazdy.
              `
        }, {
          tag: '322',
          name: 'Tunel',
          image: '322.png',
          description: `
                Značka označuje tunel, v ktorom platia pravidlá
                cestnej premávky pre správanie sa v tuneli.
                Ak ide o tunel dlhší ako 500 m, môže byť v spodnej
                časti značky alebo na dodatkovej tabuľke uvedený
                názov a dĺžka tunela.
              `
        }]
      }, {
        category: 'III. Návesti',
        id: '',
        items: [{
          tag: '325',
          name: 'Priechod pre chodcov',
          image: '325.png',
          description: `Značka označuje priechod pre chodcov.`
        }, {
          tag: '326',
          name: 'Priechod pre cyklistov',
          image: '326.png',
          description: `Značka označuje priechod pre cyklistov.`
        }, {
          tag: '327',
          name: 'Podchod alebo nadchod',
          image: '327.png',
          description: `Značka označuje podchod alebo nadchod.`
        }, {
          tag: '328',
          name: 'Slepá cesta',
          image: '328.png',
          description: `Značka označuje cestu, ktorá sa ďalej končí alebo ktorou nemožno ďalej pokračovať v jazde.`
        }, {
          tag: '330',
          name: 'Služby',
          image: '330.png',
          description: `
                Značka vhodným symbolom označuje miesto, na
                ktorom sa poskytuje služba významná pre verejnosť.
              `
        }, {
          tag: '331',
          name: 'Zastávka',
          image: '331.png',
          description: `
                Značka vhodným symbolom označuje zastávku
                vozidiel pravidelnej verejnej dopravy osôb.
              `
        }, {
          tag: '332',
          name: 'Stanovište taxi',
          image: '332.png',
          description: `Značka označuje stanovište vozidiel taxislužby.`
        }, {
          tag: '333',
          name: 'Polícia',
          image: '333.png',
          description: `
                Značka informuje o blízkosti stanovišťa alebo útvaru
                Policajného zboru s nepretržitou službou.
              `
        }, {
          tag: '334',
          name: 'Kontrola rýchlosti',
          image: '334.png',
          description: `
                Značka označuje miesto, kde sa vykonáva kontrola
                najvyššej dovolenej rýchlosti vozidiel technickým
                prostriedkom konštrukčne pevne spojeným so
                zemou.
              `
        }, {
          tag: '335',
          name: 'Núdzová odstavná plocha',
          image: '335.png',
          description: `Značka označuje miesto vyhradené na núdzové státie vozidla.`
        }, {
          tag: '336',
          name: 'Miesto na núdzové brzdenie',
          image: '336.png',
          description: `Značka vo vyznačenom smere označuje miesto na núdzové brzdenie na prudkom klesaní.`
        }, {
          tag: '340',
          name: 'Miestna návesť',
          image: '340.png',
          description: `
                Značka označuje kraj, okres, časť obce, most a
                podobne.<br/>
                Ak značka označuje turisticky významný cieľ alebo
                turistickú trasu, má hnedú farbu
              `
        }, {
          tag: '341',
          name: 'Štátna hranica',
          image: '341.png',
          description: `Značka označuje hranicu štátu a uvádza jeho názov.`
        }, {
          tag: '342',
          name: 'Informácie o všeobecných pravidlách',
          image: '342.png',
          description: `
                Značka informuje vodičov vchádzajúcich na územie
                Slovenskej republiky o najvyššej dovolenej rýchlosti
                a o dennom svietení.
              `
        }, {
          tag: '343',
          name: 'Dopravné obmedzenia na horskom prechode',
          image: '343.png',
          description: `
                Značka vhodným textom alebo symbolom informuje
                o dopravných obmedzeniach na horskom prechode.
              `
        }, {
          tag: '344',
          name: 'Varovná tabuľa',
          image: '344.png',
          description: `Značka upozorňuje na vjazd do protismeru.`
        }]
      }, {
        category: 'IV. Orientačné značky -  1. Číslovanie',
        id: '',
        items: [{
          tag: '350',
          name: 'Číslo cesty I. alebo II. triedy',
          image: '350.png',
          description: `Značka informuje o čísle cesty I. triedy alebo o čísle cesty II. triedy.`
        }, {
          tag: '351',
          name: 'Číslo cesty III. triedy',
          image: '351.png',
          description: `Značka informuje o čísle cesty III. triedy.`
        }, {
          tag: '352',
          name: 'Číslo diaľnice alebo rýchlostnej cesty',
          image: '352.png',
          description: `Značka informuje o čísle diaľnice alebo o čísle rýchlostnej cesty.`
        }, {
          tag: '353',
          name: 'Číslo E-cesty',
          image: '353.png',
          description: `Značka informuje o čísle cesty zaradenej do medzinárodnej siete ciest kategórie E.`
        }, {
          tag: '355',
          name: 'Kilometrovník',
          image: '355.png',
          description: `
                Značka informuje o približnej vzdialenosti v
                kilometroch od začiatku cesty alebo od iného
                referenčného bodu na ceste.
              `
        }]
      }, {
        category: 'IV. Orientačné značky -  2. Smerové značky na cestách mimo diaľnic',
        id: '',
        items: [{
          tag: '630',
          name: 'Smerová predzvesť',
          image: '360.png',
          description: `
                Značka vopred informuje o smere jazdy k
                vyznačeným cieľom.
                Ak je značka umiestnená nad vozovkou so šípkami
                vyznačenými nad jednotlivými jazdnými pruhmi,
                informuje zároveň o potrebe včasného zaradenia sa
                do jazdných pruhov podľa jednotlivých cieľových
                blokov.
              `
        }, {
          tag: '361',
          name: 'Smerová a pruhová predzvesť',
          image: '361.png',
          description: `
                Značka vopred informuje o smere jazdy k
                vyznačeným cieľom a o radení do jazdných pruhov
                v priestore križovatky. 
              `
        }, {
          tag: '362',
          name: 'Smerová návesť',
          image: '362.png',
          description: `
                Značka informuje o smere jazdy k vyznačeným cieľom.
                Ak je značka umiestnená nad vozovkou so šípkami
                vyznačenými nad jednotlivými jazdnými pruhmi,
                informuje zároveň o radení do jazdných pruhov
                podľa jednotlivých cieľových blokov. <br/>
                Značka sa nachádza bezprostredne pred alebo
                bezprostredne za miestom odbočenia.
              `
        }, {
          tag: '363',
          name: 'Tabuľový smerník',
          image: '363.png',
          description: `
                Značka informuje o smere jazdy k vyznačeným
                cieľom; v prípade sídelných cieľov značka spravidla
                informuje aj o vzdialenosti do centra týchto cieľov.<br/>
                Značka sa nachádza bezprostredne pred alebo
                bezprostredne za miestom odbočenia.
              `
        }, {
          tag: '364',
          name: 'Šípový smerník',
          image: '364.png',
          description: `
                Značka informuje o odbočení k vyznačeným cieľom
                a v prípade sídelných cieľov spravidla aj o
                vzdialenosti do centra týchto cieľov.
              `
        }, {
          tag: '365',
          name: 'Šípový smerník k diaľnici',
          image: '365.png',
          description: `
                Značka informuje o odbočení k diaľnici alebo
                k diaľniciam a podľa potreby aj o diaľkových cieľoch.
              `
        }, {
          tag: '366',
          name: 'Šípový smerník k zvláštnemu alebo miestnemu cieľu',
          image: '366.png',
          description: `
                Značka informuje o odbočení k zvláštnym cieľom
                alebo v obci informuje o odbočení k miestnym cieľom.
              `
        }, {
          tag: '367',
          name: 'Šípový smerník k turistickému cieľu',
          image: '367.png',
          description: `Značka informuje o odbočení k turistickým cieľom.`
        }, {
          tag: '368',
          name: 'Nepriama návesť',
          image: '368.png',
          description: `
                Značka informuje o smere jazdy k vyznačeným
                zvláštnym alebo turistickým cieľom
                prostredníctvom smeru jazdy k sídelnému cieľu.<br/>
                Farebnosť vyznačených zvláštnych a turistických
                cieľov zodpovedá farebnosti príslušného šípového
                smerníka.
              `
        }]
      }, {
        category: 'IV. Orientačné značky -  3. Smerové značky na diaľniciach',
        id: '',
        items: [{
          tag: '370',
          name: 'Ohlasovacia tabuľa',
          image: '370.png',
          description: `
                Značka informuje o blízkosti výjazdu, diaľničného
                uzla alebo odpočívadla a uvádza základné
                informácie o ňom.
              `
        }, {
          tag: '371',
          name: 'Nepriama návesť na diaľnici',
          image: '371.png',
          description: `
                Značka informuje o doplnkových zvláštnych alebo
                miestnych cieľoch, ktoré sú dostupné
                prostredníctvom vyznačeného výjazdu.
              `
        }, {
          tag: '372',
          name: 'Smerová predzvesť na diaľnici',
          image: '372.png',
          description: `
                Značka vopred informuje o smere jazdy k
                vyznačeným cieľom.<br/>
                Ak je značka umiestnená nad vozovkou so šípkami
                vyznačenými nad jednotlivými jazdnými pruhmi,
                informuje zároveň o radení do jazdných pruhov
                podľa jednotlivých cieľových blokov. 
              `
        }, {
          tag: '373',
          name: 'Smerová návesť na diaľnici',
          image: '373.png',
          description: `
                Značka informuje o smere jazdy k vyznačeným cieľom.
                Ak je značka umiestnená nad vozovkou so šípkami
                vyznačenými nad jednotlivými jazdnými pruhmi,
                informuje zároveň o radení do jazdných pruhov
                podľa jednotlivých cieľových blokov. 
              `
        }, {
          tag: '374',
          name: 'Výjazdová tabuľa',
          image: '374.png',
          description: `
                Značka vhodným nápisom označuje miesto a smer
                výjazdu alebo odbočenia na odpočívadlo.
              `
        }, {
          tag: '376',
          name: 'Diaľková tabuľa',
          image: '376.png',
          description: `
                Značka informuje o vzdialenostiach k vyznačeným
                diaľkovým cieľom.<br/>
                V blízkosti odbočenia na inú diaľnicu uvádza značka
                pod čiarou informácie o diaľkových cieľoch tejto
                diaľnice.<br/>
                Značku možno použiť aj mimo diaľnice; základná
                plocha značky je v takom prípade modrej farby.
              `
        }, {
          tag: '377',
          name: 'Turistická tabuľa',
          image: '377.png',
          description: `
                Značka informuje o významnej atraktivite
                cestovného ruchu, ktorá je dostupná prostredníctvom
                nasledujúceho výjazdu.
              `
        }, {
          tag: '378',
          name: 'Tabuľový smerník na odpočívadle',
          image: '378.png',
          description: `
                Značka na odpočívadle informuje o smere jazdy
                k vyznačeným objektom služieb, k parkovacím
                miestam podľa druhu vozidiel alebo o smere jazdy
                z odpočívadla.
              `
        }]
      }, {
        category: 'IV. Orientačné značky -  4. Smerové značky pre cyklistickú premávku',
        id: '',
        items: [{
          tag: '380',
          name: 'Cyklistický smerník tabuľový',
          image: '380.png',
          description: `
                Značka informuje o smere jazdy k vyznačeným
                cieľom po cyklistickej trase a podľa potreby aj
                o vzdialenosti k týmto cieľom a o čísle alebo inom
                označení cyklotrasy.<br/>
                Ak sa na značke vyobrazujú turistické ciele, nápisy
                a symboly sú hnedej farby.
              `
        }, {
          tag: '381',
          name: 'Cyklistický smerník šípový',
          image: '381.png',
          description: `
                Značka informuje o smere odbočenia k vyznačeným
                cieľom po cyklistickej trase a podľa potreby aj
                o vzdialenosti k týmto cieľom a o čísle alebo inom
                označení cyklotrasy.<br/>
                Ak sa na značke vyobrazujú turistické ciele, nápisy
                a symboly sú hnedej farby
              `
        }, {
          tag: '382',
          name: 'Cyklistický medzismerník',
          image: '382.png',
          description: `Značka informuje o priebehu cyklotrasy cez križovatku.`
        }, {
          tag: '383',
          name: 'Číslo cyklotrasy',
          image: '383.png',
          description: `
                Značka v spojení s cyklistickým smerníkom
                informuje o čísle alebo inom označení cyklotrasy.
              `
        }]
      }, {
        category: 'V. Značky o náhradných a obchádzkových trasách - 1. Trvalé náhradné trasy pre diaľničnú premávku',
        id: '',
        items: [{
          tag: '390',
          name: 'Náhradná trasa',
          image: '390.png',
          description: `
                Značka informuje o smere jazdy po trvalo určenej
                náhradnej trase k diaľnici. Značka bez šípky
                informuje o začiatku náhradnej trasy a prečiarknutá
                značka bez šípky o konci náhradnej trasy.
              `
        }, {
          tag: '391',
          name: 'Zmena náhradnej trasy',
          image: '391.png',
          description: `Značka informuje o pokračovaní náhradnej trasy po inej náhradnej trase.`
        }]
      }, {
        category: 'V. Značky o náhradných a obchádzkových trasách - 2. Obchádzkové trasy',
        id: '',
        items: [{
          tag: '392',
          name: 'Uzávierková predzvesť',
          image: '392.png',
          description: `
                Značka vopred informuje o čiastočnej alebo úplnej
                uzávierke cesty a podľa potreby o obchádzkovej trase.
              `
        }, {
          tag: '393',
          name: 'Ohlasovacia tabuľa obchádzky',
          image: '393.png',
          description: `
                Značka informuje o blízkosti čiastočnej alebo úplnej
                uzávierky a k nej ustanovenej obchádzkovej trasy.<br/>
                Na značke môže byť namiesto nápisu „obchádzka“
                vyznačené číslo obchádzkovej trasy, diaľkový cieľ
                obchádzanej cesty alebo symbol druhu vozidiel, pre
                ktoré je obchádzka určená.
              `
        }, {
          tag: '394',
          name: 'Tabuľový smerník na vyznačenie obchádzky',
          image: '394.png',
          description: `
                Značka informuje o smere jazdy po obchádzkovej trase.<br/>
                Ak je obchádzková trasa číslovaná, uvádza sa jej
                číslo v hornej časti značky; značka bez šípky
                s uvedeným číslom obchádzkovej trasy informuje
                o začiatku obchádzkovej trasy a prečiarknutá značka
                bez šípky o konci náhradnej trasy.<br/>
                Ak je obchádzková trasa určená pre určitý druh
                vozidiel, vyznačuje sa symbol vozidla na
                samostatnej tabuľke nad značkou; takto môže byť
                pre lepšiu orientáciu uvedený tiež diaľkový cieľ
                obchádzanej cesty alebo jej číslo. Ak obchádzková
                trasa nie je číslovaná, môžu sa tieto informácie
                uvádzať v hornej časti značky. 
              `
        }, {
          tag: '395',
          name: 'Šípový smerník na vyznačenie obchádzky',
          image: '395.png',
          description: `
                Značka označuje miesto a smer odbočenia
                obchádzkovej trasy; nachádza sa bezprostredne za
                miestom odbočenia.<br/>
                Na značke môže byť namiesto nápisu „obchádzka“
                vyznačené číslo obchádzkovej trasy, diaľkový cieľ
                obchádzanej cesty alebo symbol druhu vozidiel, pre
                ktoré je obchádzka určená.
              `
        }, {
          tag: '396',
          name: 'Koniec obchádzky',
          image: '396.png',
          description: `
                Značka informuje o konci obchádzkovej trasy.<br/>
                Na značke môže byť namiesto nápisu „obchádzka“
                vyznačené číslo obchádzkovej trasy, diaľkový cieľ
                obchádzanej cesty alebo symbol druhu vozidiel, pre
                ktoré je obchádzka určená.
              `
        }]
      }, {
        category: 'V. Značky o náhradných a obchádzkových trasách - 3. Bloková obchádzka',
        id: '',
        items: [{
          tag: '397',
          name: 'Bloková obchádzka',
          image: '397.png',
          description: `
                Značka vhodným spôsobom informuje v obci
                o križovatke, na ktorej je zakázané odbočenie alebo
                prikázaný smer jazdy a vyznačuje smer obchádzania. 
              `
        }]
      }, {
        category: 'VI. Pruhové značky',
        id: '',
        items: [{
          tag: '401 - 409',
          name: 'Počet jazdných pruhov',
          image: '401-409.png',
          description: `Značka informuje o počte jazdných pruhov`
        }, {
          tag: '411 - 419',
          name: 'Zníženie počtu jazdných pruhov',
          image: '411-419.png',
          description: `Značka informuje o znížení počtu jazdných pruhov a o spôsobe jeho zníženia.`
        }, {
          tag: '421 - 429',
          name: 'Zvýšenie počtu jazdných pruhov',
          image: '421-429.png',
          description: `Značka informuje o zvýšení počtu jazdných pruhov a o spôsobe jeho zvýšenia.`
        }, {
          tag: '431 - 439',
          name: 'Bezkolízne pripojenie',
          image: '431-439.png',
          description: `Značka informuje o bezkolíznom spojení ciest a o spôsobe vedenia jazdných pruhov.`
        }, {
          tag: '441 - 449',
          name: 'Presmerovanie jazdných pruhov',
          image: '441-449.png',
          description: `
                Značka informuje o presmerovaní jazdných pruhov
                do protismerného jazdného pásu, k okraju vozovky
                alebo o inej zmene smerovania jazdných pruhov.
              `
        }, {
          tag: '451 - 459',
          name: 'Radenie do jazdných pruhov pred križovatkou',
          image: '451-459.png',
          description: `
                Značka informuje na viacpruhovej ceste o potrebe
                včasného zaradenia sa do jazdných pruhov pred
                križovatkou podľa jednotlivých smerov jazdy tam,
                kde je usporiadanie radiacich pruhov v križovatke
                neobvyklé alebo inak neočakávateľné.
              `
        }]
      }]
    }, {
      category: 'Dodatkové tabuľky',
      id: 'dodatkove-tabulky',
      description: `
        Dodatkové tabuľky sa používajú na spresnenie, doplnenie, obmedzenie alebo rozšírenie významu zvislej značky,
        pod ktorou sú umiestnené. Ak je nad sebou umiestnených viacero výstražných, regulačných alebo informačných
        značiek, dodatková tabuľka sa vzťahuje na tú výstražnú, regulačnú alebo informačnú značku, ktorá je umiestnená 
        najbližšie nad dodatkovou tabuľkou.
      `,
      items: [{
        category: 'I. Všeobecné dodatkové tabuľky',
        id: '',
        items: [{
          tag: '501',
          name: 'Vzdialenosť',
          image: '501.png',
          description: `
                Dodatková tabuľka vyznačuje vzdialenosť k<br/>
                a) nebezpečenstvu, na ktoré upozorňuje výstražná
                značka, pod ktorou je umiestnená,<br/>
                b) regulačnej značke, ktorou začína regulácia
                vyznačená regulačnou značkou, pod ktorou je
                umiestnená,<br/>
                c) miestu, o ktorom informuje informačná značka,
                pod ktorou je umiestnená.
              `
        }, {
          tag: '502',
          name: 'Dĺžka úseku',
          image: '502.png',
          description: `
                Dodatková tabuľka vyznačuje dĺžku<br/>
                a) nebezpečného úseku, ak je umiestnená pod
                výstražnou značkou,<br/>
                b) platnosti značky Snehové reťaze , Najvyššia
                dovolená rýchlosť , Zákaz predchádzania
                , Zákaz predchádzania pre nákladné vozidlá
                alebo Minimálna vzdialenosť medzi
                vozidlami , pod ktorou je umiestnená,<br/>
                c) platnosti pruhovej značky, pod ktorou je umiestnená.
              `
        }, {
          tag: '503',
          name: 'Smerová šípka',
          image: '503.png',
          description: `
                Dodatková tabuľka vyznačuje smer k<br/>
                a) nebezpečenstvu, na ktoré upozorňuje výstražná
                značka, pod ktorou je umiestnená,<br/>
                b) regulačnej značke, ktorou sa začína regulácia
                vyznačená regulačnou značkou, pod ktorou je
                umiestnená,<br/>
                c) miestu, o ktorom informuje informačná značka,
                pod ktorou je umiestnená.
              `
        }, {
          tag: '504',
          name: 'Smer platnosti',
          image: '504.png',
          description: `
                Dodatková tabuľka vyznačuje smer platnosti
                dopravnej značky, pod ktorou je umiestnená.<br/>
                Dodatková tabuľka môže byť doplnená údajom
                o dĺžke platnosti daným smerom v metroch.
              `
        }, {
          tag: '505',
          name: 'Časové obmedzenie',
          image: '505.png',
          description: `
                Dodatková tabuľka obmedzuje platnosť značky, pod
                ktorou je umiestnená, len na vyznačené časové obdobie.
              `
        }, {
          tag: '506',
          name: 'Platí pre',
          image: '506.png',
          description: `
                Dodatková tabuľka obmedzuje platnosť značky, pod
                ktorou je umiestnená, len na vyznačených
                účastníkov cestnej premávky
              `
        }, {
          tag: '507',
          name: 'Neplatí pre',
          image: '507.png',
          description: `
              Dodatková tabuľka vyníma z platnosti značky, pod
              ktorou je umiestnená, vyznačených účastníkov
              cestnej premávky.
              `
        }, {
          tag: '508',
          name: 'Hmotnosť',
          image: '508.png',
          description: `
                Dodatková tabuľka obmedzuje platnosť značky, pod
                ktorou je umiestnená, len na vozidlá a jazdné
                súpravy s celkovou hmotnosťou prevyšujúcou
                vyznačenú hranicu.
              `
        }, {
          tag: '509',
          name: 'Spresňujúce informácie',
          image: '509.png',
          description: `
                Dodatková tabuľka podrobnejšie informuje o povahe
                alebo iných charakteristikách nebezpečenstva,
                regulácie alebo informácie vyznačenej značkou, pod
                ktorou je umiestnená; v prípade potreby informuje
                o uvedených skutočnostiach v inom ako štátnom jazyku.
              `
        }]
      }, {
        category: 'II. Špecifické dodatkové tabuľky',
        id: '',
        items: [{
          tag: '510',
          name: 'Priebeh hlavnej cesty',
          image: '510.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou Daj
                prednosť v jazde! , Stoj, daj prednosť v jazde!
                alebo Hlavná cesta vyznačuje priebeh
                hlavnej cesty, ak hlavná cesta vedie cez križovatku
                inak ako priamo alebo je jej priebeh ťažko
                rozoznateľný.<br/>
                Hlavná cesta je vyznačená hrubšou čiarou
              `
        }, {
          tag: '511',
          name: 'Vzdialenosť k povinnému zastaveniu',
          image: '511.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou Daj
                prednosť v jazde! vyznačuje vzdialenosť k
                značke Stoj, daj prednosť v jazde! .
              `
        }, {
          tag: '512',
          name: 'Priečna jazda električky',
          image: '512.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou Daj
                prednosť v jazde! alebo Stoj, daj prednosť
                v jazde! ukladá vodičovi povinnosť dať
                prednosť v jazde a zároveň dať prednosť v jazde električke.
              `
        }, {
          tag: '513',
          name: 'Priečna jazda cyklistov',
          image: '513.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou Daj
                prednosť v jazde! alebo Stoj, daj prednosť
                v jazde! ukladá vodičovi povinnosť dať
                prednosť v jazde a zároveň dať prednosť v jazde
                cyklistom sprava aj zľava idúcim súbežne s cestou.
                Dodatková tabuľka umiestnená pod značkou
                Jednosmerná cesta umožňuje cyklistom
                jazdiť v oboch smeroch; vodič iného vozidla je
                povinný zvýšiť opatrnosť voči cyklistom pri
                odbočovaní na jednosmernú cestu a v jej priebehu.
              `
        }, {
          tag: '514',
          name: 'Obojsmerná jazda cyklistov',
          image: '514.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou
                Jednosmerná cesta umožňuje cyklistom jazdiť
                v oboch smeroch; vodič iného vozidla je povinný
                zvýšiť opatrnosť voči cyklistom v priebehu
                jednosmernej cesty
              `
        }, {
          tag: '515',
          name: 'Voľno',
          image: '515.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou
                označujúcou špeciálnu cestičku alebo pruh
                umožňuje použiť špeciálnu cestičku alebo pruh
                vyznačeným účastníkom cestnej premávky, avšak
                neprikazuje im ju použiť.
                Ak ide o špeciálnu cestičku alebo pruh určený pre
                chodcov alebo pre cyklistov, účastník cestnej
                premávky, ktorému sa umožní takúto cestičku alebo
                pruh použiť, nesmie pritom obmedziť ani ohroziť
                chodca alebo cyklistu.
              `
        }, {
          tag: '520',
          name: 'Tranzit',
          image: '520.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou
                o zákaze vjazdu umožňuje vjazd<br/>
                a) vozidlom, ktorého cieľ jazdy je dosiahnuteľný
                len cestou, na ktorú sa vzťahuje zákaz vjazdu,
                alebo len prostredníctvom cesty, na ktorú sa
                vzťahuje zákaz vjazdu,<br/>
                b) vozidlom, ktoré je oslobodené od platby mýta za
                vymedzené úseky ciest, alebo<br/>
                c) vozidlom autoškoly pri výcviku, skúške alebo
                preskúšaní.
              `
        }, {
          tag: '521',
          name: 'Kategória tunela',
          image: '521.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou Zákaz
                vjazdu pre vozidlá prepravujúce nebezpečné veci
                obmedzuje zákaz len na vozidlá prepravujúce
                určité nebezpečné veci, ktoré je zakázané
                prepravovať cez tunel vyznačenej kategórie. 
              `
        }, {
          tag: '522',
          name: 'Jediné vozidlo ',
          image: '522.png',
          description: `
                Dodatková tabuľka s vyznačenou hodnotou v tonách
                umiestnená pod značkou Maximálna hmotnosť
                umožňuje vjazd vozidlom, ktorého okamžitá
                hmotnosť vrátane nákladu, ak ide o jazdnú súpravu,
                okamžitá hmotnosť všetkých vozidiel súpravy, síce
                presahuje vyznačenú hranicu na značke, nie však
                hranicu na dodatkovej tabuľke.
              `
        }, {
          tag: '525',
          name: 'Predchádzanie zvláštnych vozidiel',
          image: '525.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou Zákaz
                predchádzania umožňuje predchádzať zvláštne
                motorové vozidlá.
              `
        }, {
          tag: '526',
          name: 'Rozšírenie zákazu predchádzania',
          image: '526.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou Zákaz
                predchádzania pre nákladné vozidlá rozširuje
                zákaz aj na autobusy a osobné vozidlá s prívesom.
              `
        }, {
          tag: '527',
          name: 'Emisná trieda',
          image: '527.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou
                Nízkoemisná zóna vyznačuje emisnú triedu,
                ktorá sa vzťahuje na reguláciu premávky vozidiel
                v nízkoemisnej zóne.
              `
        }, {
          tag: '530',
          name: 'Obmedzenie parkovania na krajnici',
          image: '530.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou Zákaz
                zastavenia alebo Zákaz státia rozširuje
                zákaz aj na krajnicu.
              `
        }, {
          tag: '531',
          name: 'Vyhradené parkovanie',
          image: '531.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou Zákaz
                zastavenia umožňuje zastaviť a stáť na
                zastavenia umožňuje zastaviť a stáť na
                parkovacom mieste len pre určité vozidlo alebo pre
                určitého držiteľa parkovacieho povolenia, pre
                ktorého je dané miesto vyhradené.
              `
        }, {
          tag: '532',
          name: 'Časovo obmedzené parkovanie',
          image: '532.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou
                o parkovaní obmedzuje možnosť parkovania
                najdlhšie na vyznačenú dobu.
              `
        }, {
          tag: '533',
          name: 'Platené parkovanie',
          image: '533.png',
          description: `
                Dodatková tabuľka umiestnená pod značkou
                o parkovaní obmedzuje možnosť parkovania len za
                úhradu.
              `
        }]
      }]
    }, {
      category: 'Vodorovné značky',
      id: 'vodorovne-znacky',
      description: `Vodorovné značky sa členia na a) pozdĺžne čiary, b) priečne čiary, c) plošné značky, d) symboly.`,
      items: [{
        category: 'I. Pozdĺžne čiary',
        id: '',
        items: [{
          tag: '601',
          name: 'Pozdĺžna súvislá čiara',
          image: '601.png',
          description: `
                Značka vyznačuje jazdný pruh alebo okraj vozovky.<br/>
                Ak značka vyznačuje jazdný pruh, je zakázané ju
                prechádzať alebo nákladom presahovať, ak to nie je
                potrebné na obchádzanie prekážky cestnej
                premávky.<br/>
                Ak značka vyznačuje vyhradený pruh pre verejnú
                dopravu, je značka širšia a vnútri pruhu je v
                pravidelných vzdialenostiach doplnená symbolom
                BUS.<br/>
                Ak značka vyznačuje cyklistický pruh, je značka
                širšia a vnútri pruhu je v pravidelných
                vzdialenostiach doplnená symbolom bicykla.<br/>
                Ak značka vyznačuje vyhradený pruh pre verejnú
                dopravu alebo cyklistický pruh, má pre ostatných
                účastníkov cestnej premávky význam značky
                vyznačujúcej okraj vozovky.<br/>
                Ak značka oddeľuje protismerné jazdné pruhy, môže
                byť vyznačená ako dvojitá čiara. 
              `
        }, {
          tag: '602',
          name: 'Pozdĺžna prerušovaná čiara',
          image: '602.png',
          description: `
                Značka vyznačuje jazdný pruh alebo okraj vozovky.<br/>
                Značku možno prechádzať pri dodržaní pravidiel
                cestnej premávky.<br/>
                Značka doplnená symbolom bicykla v pravidelných
                vzdialenostiach vyznačuje ochranný pruh pre
                cyklistov. Do ochranného pruhu pre cyklistov smie
                vodič motorového vozidla vojsť len v prípade
                nevyhnutnej potreby, pričom nesmie ohroziť
                cyklistov.<br/>
                Ak je značka vyznačená ako dvojitá čiara, vyznačuje
                jazdný pruh používaný striedavo pre jeden aj druhý
                smer jazdy.
              `
        }, {
          tag: '603',
          name: 'Pozdĺžna súvislá čiara doplnená prerušovanou čiarou',
          image: '603.png',
          description: `Značka vyznačuje jazdný pruh. <br/>Vodič je povinný riadiť sa významom bližšej čiary.`
        }]
      }, {
        category: 'II. Priečne čiary',
        id: '',
        items: [{
          tag: '604',
          name: 'STOP čiara',
          image: '604.png',
          description: `
                Značka vyznačuje miesto, kde je vodič povinný
                zastaviť na príkaz značky Stoj, daj prednosť v jazde!
                alebo pred križovatkou s riadenou premávkou.<br/>
                Značka môže byť doplnená nápisom STOP.<br/>
                Značkou možno vyznačiť predsunutý priestor určený
                pre cyklistov čakajúcich na svetelný signál zelenej
                farby, pričom cyklista je povinný zastaviť až pred
                vzdialenejšou čiarou.
              `
        }, {
          tag: '605',
          name: 'Čakacia čiara',
          image: '605.png',
          description: `
                Značka vyznačuje miesto, kde sa vodičovi odporúča
                zastaviť vozidlo, ak dáva prednosť v jazde.
                Značka môže byť vyznačená aj prerušovanou čiarou
                tvorenou malými plnými trojuholníkmi vrcholmi
                orientovanými proti smeru jazdy.
              `
        }, {
          tag: '610',
          name: 'Priechod pre chodcov',
          image: '610.png',
          description: `Značka vyznačuje priechod pre chodcov.`
        }, {
          tag: '611',
          name: 'Miesto na prechádzanie',
          image: '611.png',
          description: `Značka vyznačuje miesto prispôsobené na prechádzanie chodcov a cyklistov. `
        }, {
          tag: '612',
          name: 'Priechod pre cyklistov',
          image: '612.png',
          description: `
                Značka vyznačuje priechod pre cyklistov.<br/>
                Ak je priechod pre cyklistov primknutý k priechodu
                pre chodcov, vyznačuje sa len jednou čiarou.
              `
        }]
      }, {
        category: 'III. Plošné značky',
        id: '',
        items: [{
          tag: '620',
          name: 'Vyšrafovaný priestor',
          image: '620.png',
          description: `
                Značka vyznačuje priestor, do ktorého je zakázané
                vchádzať alebo doň nákladom presahovať, ak to nie
                je potrebné na obchádzanie prekážky cestnej
                premávky.
              `
        }, {
          tag: '621',
          name: 'Cikcak čiara',
          image: '621.png',
          description: `
                Značka vyznačuje priestor, kde je zakázané státie vozidiel.<br/>
                Značka doplnená symbolom BUS alebo TRAM
                vyznačuje zastávku vozidiel pravidelnej verejnej
                dopravy osôb, na ktorej je zastavenie a státie
                dovolené len pre vozidlá pravidelnej verejnej
                dopravy osôb.<br/>
                Značka doplnená symbolom TAXI vyznačuje
                stanovište taxíkov, na ktorom je zastavenie a státie
                dovolené len pre vozidlá taxislužby na vykonávanie
                zmluvnej prepravy osôb.
              `
        }, {
          tag: '622',
          name: 'Parkovacie miesta',
          image: '622.png',
          description: `
                Značka vhodným spôsobom vyznačuje miesto, kde
                je dovolené zastavenie a státie a určený spôsob státia.<br/>
                Ak značka vyznačuje priestor parkovacieho miesta s
                vyhradeným státím, môže byť doplnená vhodným
                nápisom alebo symbolom
              `
        }]
      }, {
        category: 'IV. Symboly',
        id: '',
        items: [{
          tag: '630',
          name: 'Smerové šípky',
          image: '630.png',
          description: `
                Smerové šípky vyznačené medzi pozdĺžnymi
                čiarami vyjadrujú spôsob radenia do jazdných
                pruhov a prikázaný smer jazdy cez nasledujúcu
                križovatku alebo odbočenie vo vyznačenom smere.
                Značka doplnená symbolom BUS vyznačuje
                prikázaný smer jazdy cez nasledujúcu križovatku
                alebo odbočenie vo vyznačenom smere len pre
                vozidlá pravidelnej verejnej dopravy osôb.
              `
        }, {
          tag: '631',
          name: 'Predbežné šípky',
          image: '631.png',
          description: `
                Značka umiestnená v osi priebežného jazdného
                pruhu vyznačuje jeho blížiace sa skončenie.<br/>
                Značka umiestnená v osi Pozdĺžnej prerušovanej
                čiary na ceste s obojsmernou premávkou vyznačuje
                blížiaci sa prechod Pozdĺžnej prerušovanej čiary do
                Pozdĺžnej súvislej čiary.
              `
        }, {
          tag: '635',
          name: 'Koridor pre cyklistov',
          image: '635.png',
          description: `
                Značka vyznačuje smer jazdy cyklistov.
                Vodičov motorových vozidiel upozorňuje, že sa
                nachádzajú na vozovke so zvýšeným pohybom
                cyklistov.
              `
        }]
      }]
    }, {
      category: 'Dopravné zariadenia',
      id: 'dopravne-zariadenia',
      description: `
        Dopravné zariadenia sa používajú aj na dávanie svetelných signálov, 
        zobrazovanie premenných dopravných značiek, vynucovanie dodržiavania pravidiel cestnej premávky a obdobné účely
        priamo súvisiace s cestnou premávkou.
      `,
      items: [{
        category: 'I. Vodiace zariadenia -  1. Zariadenia pre vyznačenie dočasných prekážok',
        id: '',
        items: [{
          tag: '701',
          name: 'Zábrana na označenie uzávierky',
          image: '701.png',
          description: `Zábrana na označenie uzávierky vyznačuje uzávierku celej šírky cesty alebo jej časti.`
        }, {
          tag: '702',
          name: 'Smerovacia doska (šípová a šrafovaná)',
          image: '702.png',
          description: `
                Smerovacie dosky usmerňujú cestnú premávku v
                smere v smere šípky alebo v smere sklonu šikmých
                pruhov.<br/>
                Smerovacie dosky môžu byť umiestnené na žltých
                vodiacich prahoch, ktoré majú význam Pozdĺžnej
                súvislej čiary žltej farby.
              `
        }, {
          tag: '703',
          name: 'Dopravný kužeľ',
          image: '703.png',
          description: `
                Dopravné kužele umiestnené v rade za sebou
                vymedzujú priestor, do ktorého je zakázané vchádzať.
              `
        }]
      }, {
        category: 'I. Vodiace zariadenia -  2. Zariadenia pre vyznačenie trvalých prekážok',
        id: '',
        items: [{
          tag: '705',
          name: 'Smerovacia tabuľa',
          image: '705.png',
          description: `Smerovacie tabule usmerňujú cestnú premávku v smere šípky alebo šípok.`
        }, {
          tag: '706',
          name: 'Vodiaca tabuľa',
          image: '706.png',
          description: `
                Vodiaca tabuľa označuje trvalú prekážku
                a usmerňuje cestnú premávku v smere sklonu
                šikmých pruhov.
              `
        }, {
          tag: '707',
          name: 'Červené a biele pruhy',
          image: '707.png',
          description: `Červené a biele pruhy vyznačujú spravidla trvalé objekty obmedzujúce cestnú premávku. `
        }]
      }, {
        category: 'I. Vodiace zariadenia -  3. Zariadenia pre vymedzenie smerového vedenia a voľnej šírky cesty',
        id: '',
        items: [{
          tag: '710',
          name: 'Smerový stĺpik (ľavý a pravý)',
          image: '710.png',
          description: `
                Smerové stĺpiky vymedzujú smerové vedenie cesty
                a jej šírku, prípadne čiastočnú voľnú šírku smerovo
                rozdelenej cesty.<br/>
                Smerový stĺpik na moste, v jeho blízkosti alebo na
                iných miestach so zvýšeným nebezpečenstvom
                tvorby námrazy môže byť doplnený modrou
                plochou s odrazkou.
              `
        }, {
          tag: '711',
          name: 'Stĺpik s výstrahou (ľavý a pravý)',
          image: '711.png',
          description: `
                Stĺpiky s výstrahou vymedzujú šírku cesty alebo
                čiastočnú voľnú šírku cesty na moste, v jeho
                blízkosti alebo na iných miestach so zvýšeným
                nebezpečenstvom tvorby námrazy.
              `
        }]
      }, {
        category: 'II. Výstražné zariadenia',
        id: '',
        items: [{
          tag: '720',
          name: 'Pojazdná uzávierková tabuľa',
          image: '720.png',
          description: `
                Pojazdná uzávierková tabuľa vhodnou kombináciou
                zvislých značiek a svetelných signálov najmä
                krátkodobo označuje prekážku cestnej premávky,
                uzávierku jazdného pruhu alebo statické alebo
                pohyblivé pracovné miesto na ceste.
              `
        }]
      }]
    }]
  });
  _exports.default = _default;
});