define("ember-google-maps/utils/options-and-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEventListener = addEventListener;
  _exports.addEventListeners = addEventListeners;
  _exports.ignoredOptions = void 0;
  _exports.isEvent = isEvent;
  _exports.isIgnored = isIgnored;
  _exports.parseOptionsAndEvents = parseOptionsAndEvents;
  _exports.watch = watch;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  const ignoredOptions = ['map', 'lat', 'lng', '_internalAPI', 'gMap', 'options', 'events', '_name'];
  _exports.ignoredOptions = ignoredOptions;
  function parseOptionsAndEvents() {
    let ignored = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : r => r;
    let ignoredSet = new Set(ignored);
    return Ember.computed('attrs', function () {
      return callback(parseAttrs(ignoredSet, this.attrs));
    });
  }
  function parseAttrs() {
    let ignored = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Set();
    let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let events = _objectSpread({}, args.events);
    let options = _objectSpread({}, args.options);
    let entries = Object.entries(args);
    entries.forEach(_ref => {
      let [k, v] = _ref;
      if (isIgnored(k, ignored)) {
        // Pass
      } else if (isEvent(k)) {
        events[k] = v;
      } else {
        options[k] = extractValue(v);
      }
    });
    return {
      events,
      options
    };
  }
  function isEvent(key) {
    return key.slice(0, 2) === 'on';
  }
  function isIgnored(key, ignored) {
    return ignored.has(key);
  }
  function extractValue(cell) {
    if (cell && cell.constructor && Object.keys(cell).includes('value')) {
      return cell.value;
    }
    return cell;
  }
  function watch(target) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return Object.entries(options).map(_ref2 => {
      let [key, callback] = _ref2;
      return addObserver(target, key, callback);
    });
  }
  function addObserver(obj, key, callback) {
    let listener = obj.addObserver(key, callback);
    return {
      name: key,
      listener,
      remove: () => obj.removeObserver(key, callback)
    };
  }

  /* Events */

  function addEventListener(target, originalEventName, action) {
    let payload = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    let eventName = Ember.String.decamelize(originalEventName).slice(3);
    function callback(googleEvent) {
      let params = _objectSpread({
        event: window.event,
        googleEvent,
        eventName,
        target
      }, payload);
      Ember.run.join(target, action, params);
    }
    let listener = google.maps.event.addDomListener(target, eventName, callback);
    return {
      name: eventName,
      listener,
      remove: () => listener.remove()
    };
  }

  /**
   * Add event listeners on a target object using the cross-browser event
   * listener library provided by the Google Maps API.
   *
   * @param {Object} target
   * @param {Events} events
   * @param {[Object]} payload = {} An optional object of additional parameters
   *     to include with the event payload.
   * @return {google.maps.MapsEventListener[]} An array of bound event listeners
   *     that should be used to remove the listeners when no longer needed.
   */
  function addEventListeners(target, events) {
    let payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return Object.entries(events).map(_ref3 => {
      let [originalEventName, action] = _ref3;
      return addEventListener(target, originalEventName, action, payload);
    });
  }
});