define("soferuj/modules/info/road-signs/road-sign/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0TnEyljd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"item layout-xs-column layout layout-align-start-start flex-gt-sm-50 flex-xs-100\"],[12],[2,\"\\n\\t\"],[10,\"img\"],[15,\"src\",[31,[\"/images/signs/\",[32,0,[\"sign\",\"image\"]]]]],[15,\"alt\",[31,[[32,0,[\"sign\",\"tag\"]],\": \",[32,0,[\"sign\",\"name\"]]]]],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"description\"],[12],[2,\"\\n\\t\\t\"],[10,\"h4\"],[12],[10,\"span\"],[12],[1,[32,0,[\"sign\",\"tag\"]]],[2,\":\"],[13],[1,[32,0,[\"sign\",\"name\"]]],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[1,[34,0]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"description\"]}",
    "meta": {
      "moduleName": "soferuj/modules/info/road-signs/road-sign/template.hbs"
    }
  });
  _exports.default = _default;
});