define("soferuj/modules/driving-school/components/editable-dialog/languages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "09f0XUaw",
    "block": "{\"symbols\":[\"language\",\"controls\",\"@model\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"languages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"paper-item\",[[24,0,\"service-list-item\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"md-secondary-container\"],[12],[2,\"\\n      \"],[8,[32,2,[\"switch\"]],[],[[\"@value\",\"@onChange\"],[[30,[36,0],[[32,1],[32,3,[\"languages\"]]],null],[30,[36,1],[[32,0,[\"toggleLanguage\"]],[32,1]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"contains\",\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/components/editable-dialog/languages/template.hbs"
    }
  });
  _exports.default = _default;
});