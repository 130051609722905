define("soferuj/modules/info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "16TpkarN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-info\"],[12],[2,\"\\n\\t\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "soferuj/modules/info/template.hbs"
    }
  });
  _exports.default = _default;
});