define("ember-concurrency/-private/task-factory", ["exports", "ember-concurrency/-private/external/scheduler/policies/unbounded-policy", "ember-concurrency/-private/external/scheduler/policies/enqueued-policy", "ember-concurrency/-private/external/scheduler/policies/drop-policy", "ember-concurrency/-private/external/scheduler/policies/keep-latest-policy", "ember-concurrency/-private/external/scheduler/policies/restartable-policy", "ember-concurrency/-private/task", "ember-concurrency/-private/task-properties", "ember-concurrency/-private/task-group", "ember-concurrency/-private/scheduler/ember-scheduler"], function (_exports, _unboundedPolicy, _enqueuedPolicy, _dropPolicy, _keepLatestPolicy, _restartablePolicy, _task, _taskProperties, _taskGroup, _emberScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TaskGroupFactory = _exports.TaskFactory = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  let handlerCounter = 0;
  function assertModifiersNotMixedWithGroup(obj) {
    (false && !(!obj._hasUsedModifier || !obj._taskGroupPath) && Ember.assert(`ember-concurrency does not currently support using both 'group' with other task modifiers (e.g. 'drop', 'enqueue', 'restartable')`, !obj._hasUsedModifier || !obj._taskGroupPath));
  }
  function assertUnsetBufferPolicy(obj) {
    (false && !(!obj._hasSetBufferPolicy) && Ember.assert(`Cannot set multiple buffer policies on a task or task group. ${obj._schedulerPolicyClass} has already been set for task or task group '${obj.name}'`, !obj._hasSetBufferPolicy));
  }
  function registerOnPrototype(addListenerOrObserver, proto, names, taskName, taskMethod, once) {
    if (names) {
      for (let i = 0; i < names.length; ++i) {
        let name = names[i];
        let handlerName = `__ember_concurrency_handler_${handlerCounter++}`;
        proto[handlerName] = makeTaskCallback(taskName, taskMethod, once);
        addListenerOrObserver(proto, name, null, handlerName);
      }
    }
  }
  function makeTaskCallback(taskName, method, once) {
    return function () {
      let task = Ember.get(this, taskName);
      if (once) {
        Ember.run.scheduleOnce("actions", task, method, ...arguments);
      } else {
        task[method].apply(task, arguments);
      }
    };
  }
  const ensureArray = possibleArr => Array.isArray(possibleArr) ? possibleArr : [possibleArr];
  const optionRegistry = {
    cancelOn: (factory, eventNames) => factory.addCancelEvents(...ensureArray(eventNames)),
    enqueue: factory => factory.setBufferPolicy(_enqueuedPolicy.default),
    evented: factory => factory.setEvented(true),
    debug: factory => factory.setDebug(true),
    drop: factory => factory.setBufferPolicy(_dropPolicy.default),
    group: (factory, groupName) => factory.setGroup(groupName),
    keepLatest: factory => factory.setBufferPolicy(_keepLatestPolicy.default),
    maxConcurrency: (factory, maxConcurrency) => factory.setMaxConcurrency(maxConcurrency),
    observes: (factory, propertyPaths) => factory.addObserverKeys(...ensureArray(propertyPaths)),
    on: (factory, eventNames) => factory.addPerformEvents(...ensureArray(eventNames)),
    onState: (factory, onStateCallback) => factory.setOnState(onStateCallback),
    restartable: factory => factory.setBufferPolicy(_restartablePolicy.default)
  };
  class TaskFactory {
    constructor() {
      let name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "<unknown>";
      let taskDefinition = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      _defineProperty(this, "_cancelEventNames", []);
      _defineProperty(this, "_debug", null);
      _defineProperty(this, "_eventNames", []);
      _defineProperty(this, "_hasUsedModifier", false);
      _defineProperty(this, "_hasSetBufferPolicy", false);
      _defineProperty(this, "_hasEnabledEvents", false);
      _defineProperty(this, "_maxConcurrency", null);
      _defineProperty(this, "_observes", []);
      _defineProperty(this, "_onStateCallback", (state, taskable) => taskable.setState(state));
      _defineProperty(this, "_schedulerPolicyClass", _unboundedPolicy.default);
      _defineProperty(this, "_taskGroupPath", null);
      this.name = name;
      this.taskDefinition = taskDefinition;
      this._processOptions(options);
    }
    createTask(context) {
      (false && !(this.taskDefinition) && Ember.assert(`Cannot create task if a task definition is not provided as generator function or encapsulated task.`, this.taskDefinition));
      let options = this._sharedTaskProperties(context);
      if (typeof this.taskDefinition === "object") {
        return new _task.EncapsulatedTask(Object.assign({
          taskObj: this.taskDefinition
        }, options));
      } else {
        return new _task.Task(Object.assign({
          generatorFactory: args => this.taskDefinition.apply(context, args)
        }, options));
      }
    }
    addCancelEvents() {
      this._cancelEventNames.push(...arguments);
      return this;
    }
    addObserverKeys() {
      this._observes.push(...arguments);
      return this;
    }
    addPerformEvents() {
      this._eventNames.push(...arguments);
      return this;
    }
    setBufferPolicy(policy) {
      assertUnsetBufferPolicy(this);
      this._hasSetBufferPolicy = true;
      this._hasUsedModifier = true;
      this._schedulerPolicyClass = policy;
      assertModifiersNotMixedWithGroup(this);
      return this;
    }
    setDebug(debug) {
      this._debug = debug;
      return this;
    }
    setEvented(evented) {
      this._hasEnabledEvents = evented;
      return this;
    }
    setMaxConcurrency(maxConcurrency) {
      (false && !(Number.isInteger(maxConcurrency)) && Ember.assert(`maxConcurrency must be an integer (Task '${this.name}')`, Number.isInteger(maxConcurrency)));
      this._hasUsedModifier = true;
      this._maxConcurrency = maxConcurrency;
      assertModifiersNotMixedWithGroup(this);
      return this;
    }
    setGroup(group) {
      this._taskGroupPath = group;
      assertModifiersNotMixedWithGroup(this);
      return this;
    }
    setName(name) {
      this.name = name;
      return this;
    }
    setOnState(onStateCallback) {
      this._onStateCallback = onStateCallback;
      return this;
    }
    setTaskDefinition(taskDefinition) {
      (false && !(typeof taskDefinition === "function" || typeof taskDefinition === "object" && typeof taskDefinition.perform === "function") && Ember.assert(`Task definition must be a generator function or encapsulated task.`, typeof taskDefinition === "function" || typeof taskDefinition === "object" && typeof taskDefinition.perform === "function"));
      this.taskDefinition = taskDefinition;
      return this;
    }
    _processOptions(options) {
      for (let key of Object.keys(options)) {
        let value = options[key];
        if (optionRegistry[key]) {
          optionRegistry[key].call(null, this, value);
        } else if (typeof _taskProperties.TaskProperty.prototype[key] === "function") {
          // Shim for compatibility with user-defined TaskProperty prototype
          // extensions. To be removed when replaced with proper public API.
          _taskProperties.TaskProperty.prototype[key].call(this, value);
        } else {
          (false && !(false) && Ember.assert(`Task option '${key}' is not recognized as a supported option.`, false));
        }
      }
    }
    _setupEmberKVO(proto) {
      // TODO: Does this make sense in a post-Ember object world?

      registerOnPrototype(Ember.addListener, proto, this._eventNames, this.name, "perform", false);
      registerOnPrototype(Ember.addListener, proto, this._cancelEventNames, this.name, "cancelAll", false);
      registerOnPrototype(Ember.addObserver, proto, this._observes, this.name, "perform", true);
    }
    _sharedTaskProperties(context) {
      let group, scheduler;
      let onStateCallback = this._onStateCallback;
      if (this._taskGroupPath) {
        group = context[this._taskGroupPath];
        (false && !(group instanceof _taskGroup.TaskGroup) && Ember.assert(`ember-concurrency: Expected group '${this._taskGroupPath}' to be defined but was not found.`, group instanceof _taskGroup.TaskGroup));
        scheduler = group.scheduler;
      } else {
        let schedulerPolicy = new this._schedulerPolicyClass(this._maxConcurrency);
        scheduler = new _emberScheduler.default(schedulerPolicy, onStateCallback);
      }
      return {
        context,
        debug: this._debug,
        name: this.name,
        group,
        scheduler,
        hasEnabledEvents: this._hasEnabledEvents,
        onStateCallback
      };
    }

    // Provided for compatibility with ember-concurrency TaskProperty extension
    // methods
    get taskFn() {
      return this.taskDefinition;
    }
    set taskFn(fn) {
      this.setTaskDefinition(fn);
    }
  }
  _exports.TaskFactory = TaskFactory;
  class TaskGroupFactory extends TaskFactory {
    createTaskGroup(context) {
      (false && !(!this.taskDefinition) && Ember.assert(`A task definition is not expected for a task group.`, !this.taskDefinition));
      let options = this._sharedTaskProperties(context);
      return new _taskGroup.TaskGroup(options);
    }
  }
  _exports.TaskGroupFactory = TaskGroupFactory;
});