define("soferuj/modules/driving-school/components/editable-dialog/location/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { action } from '@ember/object';
  // import { tracked } from '@glimmer/tracking';
  class EditLocation extends _component.default {}
  _exports.default = EditLocation;
});