define("soferuj/modules/login/recovery/index/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: (0, _emberCpValidations.validator)('presence', true)
  });
  var _default = Validations;
  _exports.default = _default;
});