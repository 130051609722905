define("soferuj/modules/review/adapter", ["exports", "soferuj/modules/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _adapter.default.extend({
    urlForQuery(_ref, modelName) {
      let {
        drivingSchoolId,
        admin
      } = _ref;
      let baseUrl = this.buildURL();
      if (drivingSchoolId) {
        return `${baseUrl}/driving-schools/${drivingSchoolId}/${modelName}s`;
      }
      if (admin) {
        return `${baseUrl}/admin/reviews`;
      }
      return this._super(...arguments);
    }
  });
  _exports.default = _default;
});