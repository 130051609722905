define("ember-google-maps/templates/components/g-map/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1PiEuIRM",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,1,[34,0]],[16,0,[34,1]],[16,5,[34,2]],[17,1],[12],[18,2,null],[13]],\"hasEval\":false,\"upvars\":[\"id\",\"computedClasses\",\"style\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/canvas.hbs"
    }
  });
  _exports.default = _default;
});