define("soferuj/modules/login/index/controller", ["exports", "soferuj/modules/login/index/validations", "soferuj/config/environment"], function (_exports, _validations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_validations.default, {
    API_URL: _environment.default.APP.API_URL,
    identity: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    metrics: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    user: null,
    actions: {
      onEmailBlur() {
        this.ajax.request(`users?email=${this.username}`).then(_ref => {
          let {
            data
          } = _ref;
          this.set('user', {
            profilePicture: data.attributes['profile-picture']
          }); // TODO: serialize
        });
      },

      facebookLogin() {
        this.metrics.trackEvent({
          category: 'User',
          action: 'login',
          label: 'facebook'
        });
      },
      authenticate() {
        let {
          username,
          password
        } = this;
        this.set('isSubmitted', true);
        if (this.get('validations.isValid')) {
          this.metrics.trackEvent({
            category: 'User',
            action: 'login',
            label: 'website'
          });
          this.identity.login(username, password).then(() => {
            this.identity.load();
            this.set('username', '');
            this.set('password', '');
            this.transitionToRoute('/');
          }).catch(() => {
            this.set('password', '');
            // TODO: show error on form input
            this.paperToaster.show('Nesprávne prihlasovacie údaje', {
              toastClass: 'warning'
            });
          });
        }
      }
    }
  });
  _exports.default = _default;
});