define("soferuj/modules/driving-school/index/components/licence-type-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9TseZfEL",
    "block": "{\"symbols\":[\"@licenceType\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"licence-type-item licence-\",[32,0,[\"mainType\"]]]]],[12],[2,\"\\n  \"],[10,\"b\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n  \"],[8,\"fa-icon\",[[24,0,\"icon\"]],[[\"@icon\"],[[32,0,[\"icon\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/index/components/licence-type-item/template.hbs"
    }
  });
  _exports.default = _default;
});