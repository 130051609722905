define('ember-router-helpers/helpers/is-active', ['exports', 'ember-router-helpers/utils/handle-query-params'], function (exports, _handleQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),

    init() {
      this._super(...arguments);
      this.addObserver('router.currentURL', this, 'recompute');
    },

    compute(_params) {
      let params = (0, _handleQueryParams.default)(_params);
      return this.get('router').isActive(...params);
    }
  });
});