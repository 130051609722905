define("soferuj/modules/test/components/question-nav-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "whloSDne",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"iconButton\",\"raised\",\"primary\",\"noink\",\"onClick\"],[true,[30,[36,5],[[35,4],[35,3]],null],[30,[36,5],[[35,4],[35,3]],null],[35,2],[35,1]]],[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"questionNumber\",\"onQuestionChange\",\"isRight\",\"index\",\"actualQuestion\",\"eq\",\"paper-button\"]}",
    "meta": {
      "moduleName": "soferuj/modules/test/components/question-nav-button/template.hbs"
    }
  });
  _exports.default = _default;
});