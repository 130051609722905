define("ember-paper/templates/components/paper-card-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5W1655S7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"text\",\"media\"],[[30,[36,0],[\"paper-card-title-text\"],null],[30,[36,0],[\"paper-card-title-media\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-card-title.hbs"
    }
  });
  _exports.default = _default;
});