define("soferuj/modules/components/page/page-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6yWDKfWW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,8],null,[[\"class\"],[\"page-sub-header\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"layout-row layout-align-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"flex-sm-90 flex-gt-sm-80 flex\"],[12],[2,\"\\n\"],[6,[37,7],null,null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"iconButton\",\"class\",\"onClick\"],[true,\"backlink\",[30,[36,2],[[32,0],\"redirect\",[35,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"chevron-left\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\\t\"],[10,\"h1\"],[14,0,\"title\"],[12],[1,[34,5]],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"p\"],[14,0,\"subtitle\"],[12],[1,[34,6]],[13],[2,\"\\n\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"flex\"],[12],[13],[2,\"\\n\\n\\t\\t\\t\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"backLink\",\"action\",\"paper-button\",\"if\",\"title\",\"subtitle\",\"paper-toolbar-tools\",\"paper-toolbar\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/page/page-header/template.hbs"
    }
  });
  _exports.default = _default;
});