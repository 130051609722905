define("soferuj/services/ajax", ["exports", "ember-ajax/services/ajax", "lodash", "soferuj/config/environment"], function (_exports, _ajax, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    host: _environment.default.APP.API_URL,
    //namespace: '/api/',
    contentType: 'application/json; charset=utf-8',
    options() {
      const result = this._super(...arguments);
      const attributes = _lodash.default.transform(result.data, function (result, value, key) {
        result[_lodash.default.kebabCase(key)] = value;
      });
      result.data = {
        data: {
          attributes
        }
      };
      result.xhrFields = {
        withCredentials: true
      };
      // result.crossDomain = true

      return result;
    }
  });
  _exports.default = _default;
});