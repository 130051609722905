define("soferuj/modules/driving-school/components/editable-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AzXAoYcu",
    "block": "{\"symbols\":[\"@onClose\",\"@onUpdateModel\",\"@model\",\"@sectionName\"],\"statements\":[[2,\" \"],[8,\"paper-dialog\",[],[[\"@class\",\"@onClose\"],[[31,[\"driving-school-edit-dialog \",[32,0,[\"props\",\"sizeClass\"]]]],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"props\",\"title\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"h2\"],[12],[2,\"Upraviť \"],[1,[32,0,[\"props\",\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[30,[36,2],[[30,[36,1],[\"driving-school/components/editable-dialog/\",[32,4]],null]],[[\"model\",\"onUpdateModel\"],[[32,3],[32,2]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"paper-dialog-actions\",[],[[\"@class\"],[\"layout-row\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@onClick\"],[[32,0,[\"editCancel\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Zrušiť\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@class\",\"@primary\",\"@raised\",\"@onClick\"],[\"green\",true,true,[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Uložiť\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"component\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/components/editable-dialog/template.hbs"
    }
  });
  _exports.default = _default;
});