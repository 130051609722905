define("soferuj/modules/test/components/test-countdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LHXDfFSr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n\\t\"],[1,[34,0]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"time\"]}",
    "meta": {
      "moduleName": "soferuj/modules/test/components/test-countdown/template.hbs"
    }
  });
  _exports.default = _default;
});