define("ember-router-scroll/index", ["exports", "ember-app-scheduler", "ember-router-scroll/utils/scrollbar-width"], function (_exports, _emberAppScheduler, _scrollbarWidth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ATTEMPTS = 0;
  const MAX_ATTEMPTS = 100; // rAF runs every 16ms ideally, so 60x a second
  let requestId;
  let scrollBarWidth = 0;

  /**
   * By default, we start checking to see if the document height is >= the last known `y` position
   * we want to scroll to.  This is important for content heavy pages that might try to scrollTo
   * before the content has painted
   *
   * @method tryScrollRecursively
   * @param {Function} fn
   * @param {Object} scrollHash
   * @void
   */
  function tryScrollRecursively(fn, scrollHash) {
    const body = document.body;
    const html = document.documentElement;
    // read DOM outside of rAF
    const documentWidth = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const {
      innerHeight,
      innerWidth
    } = window;
    requestId = window.requestAnimationFrame(() => {
      // write DOM (scrollTo causes reflow)
      if (documentWidth + scrollBarWidth - innerWidth >= scrollHash.x && documentHeight + scrollBarWidth - innerHeight >= scrollHash.y || ATTEMPTS >= MAX_ATTEMPTS) {
        ATTEMPTS = 0;
        fn.call(null, scrollHash.x, scrollHash.y);
      } else {
        ATTEMPTS++;
        tryScrollRecursively(fn, scrollHash);
      }
    });
  }
  let RouterScrollMixin = Ember.Mixin.create({
    service: Ember.inject.service('router-scroll'),
    isFastBoot: Ember.computed(function () {
      const fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }),
    init() {
      this._super(...arguments);
      (0, _emberAppScheduler.setupRouter)(this);
      if (true) {
        this.on('routeWillChange', () => {
          this._routeWillChange();
        });
        this.on('routeDidChange', transition => {
          this._routeDidChange(transition);
        });
      }
      if (!Ember.get(this, 'isFastBoot')) {
        scrollBarWidth = (0, _scrollbarWidth.getScrollBarWidth)();
      }
    },
    destroy() {
      (0, _emberAppScheduler.reset)();
      if (requestId) {
        window.cancelAnimationFrame(requestId);
      }
      this._super(...arguments);
    },
    /**
     * Updates the scroll position
     * it will be a single transition
     * @method updateScrollPosition
     * @param {transition|transition[]} transition If before Ember 3.6, this will be an array of transitions, otherwise
     * @param {Boolean} recursiveCheck -  if "true", check until document height is >= y. `y` is the last coordinate the target page was on
     */
    updateScrollPosition(transition, recursiveCheck) {
      const url = Ember.get(this, 'currentURL');
      const hashElement = url ? document.getElementById(url.split('#').pop()) : null;
      if (Ember.get(this, 'service.isFirstLoad')) {
        Ember.get(this, 'service').unsetFirstLoad();
      }
      let scrollPosition;
      if (url && url.indexOf('#') > -1 && hashElement) {
        scrollPosition = {
          x: hashElement.offsetLeft,
          y: hashElement.offsetTop
        };
      } else {
        scrollPosition = Ember.get(this, 'service.position');
      }
      let preserveScrollPosition;
      if (true) {
        preserveScrollPosition = Ember.getWithDefault(transition, 'router.currentRouteInfos', []).some(routeInfo => Ember.get(routeInfo, 'route.controller.preserveScrollPosition'));
      } else {
        preserveScrollPosition = transition.some(t => Ember.get(t, 'handler.controller.preserveScrollPosition'));
      }

      // If `preserveScrollPosition` was not set on the controller, attempt fallback to `preserveScrollPosition` which was set on the router service.
      if (!preserveScrollPosition) {
        preserveScrollPosition = Ember.get(this, 'service.preserveScrollPosition');
      }
      if (!preserveScrollPosition) {
        const scrollElement = Ember.get(this, 'service.scrollElement');
        const targetElement = Ember.get(this, 'service.targetElement');
        if (targetElement || 'window' === scrollElement) {
          if (recursiveCheck) {
            // our own implementation
            tryScrollRecursively(window.scrollTo, scrollPosition);
          } else {
            // using ember-app-scheduler
            window.scrollTo(scrollPosition.x, scrollPosition.y);
          }
        } else if ('#' === scrollElement.charAt(0)) {
          const element = document.getElementById(scrollElement.substring(1));
          if (element) {
            element.scrollLeft = scrollPosition.x;
            element.scrollTop = scrollPosition.y;
          }
        }
      }
    },
    _routeWillChange() {
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }
      Ember.get(this, 'service').update();
    },
    _routeDidChange(transition) {
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }
      const delayScrollTop = Ember.get(this, 'service.delayScrollTop');
      const scrollWhenPainted = Ember.get(this, 'service.scrollWhenPainted');
      const scrollWhenIdle = Ember.get(this, 'service.scrollWhenIdle');
      if (!delayScrollTop && !scrollWhenPainted && !scrollWhenIdle) {
        // out of the 3 options, this happens on the tightest schedule
        Ember.run.scheduleOnce('render', this, () => this.updateScrollPosition(transition, true));
      } else if (scrollWhenPainted) {
        // as described in ember-app-scheduler, this addon can be used to delay rendering until after First Meaningful Paint.
        // If you loading your routes progressively, this may be a good option to delay scrollTop until the remaining DOM elements are painted.
        (0, _emberAppScheduler.whenRoutePainted)().then(() => {
          this.updateScrollPosition(transition);
        });
      } else {
        // as described in ember-app-scheduler, this addon can be used to delay rendering until after the route is idle
        (0, _emberAppScheduler.whenRouteIdle)().then(() => {
          this.updateScrollPosition(transition);
        });
      }
    }
  });
  if (!true) {
    RouterScrollMixin = Ember.Mixin.create(RouterScrollMixin, {
      willTransition() {
        this._super(...arguments);
        this._routeWillChange();
      },
      didTransition(transitions) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        this._super(transitions, ...args);
        this._routeDidChange(transitions);
      }
    });
  }
  var _default = RouterScrollMixin;
  _exports.default = _default;
});