define("ember-paper/components/paper-contact-chips/component", ["exports", "ember-paper/components/paper-contact-chips/template", "@ember-decorators/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  let PaperContactChips = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class PaperContactChips extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "requireMatch", true);
      _defineProperty(this, "searchField", 'email');
      _defineProperty(this, "emailField", 'email');
      _defineProperty(this, "nameField", 'name');
      _defineProperty(this, "imageField", 'image');
    }
  }) || _class) || _class);
  var _default = PaperContactChips;
  _exports.default = _default;
});