define("ember-app-scheduler/scheduler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USE_REQUEST_IDLE_CALLBACK = _exports.SIMPLE_CALLBACK = void 0;
  _exports._getScheduleFn = _getScheduleFn;
  _exports._setCapabilities = _setCapabilities;
  _exports.beginTransition = beginTransition;
  _exports.didTransition = didTransition;
  _exports.endTransition = endTransition;
  _exports.reset = reset;
  _exports.routeSettled = routeSettled;
  _exports.setupRouter = setupRouter;
  _exports.whenRouteIdle = whenRouteIdle;
  _exports.whenRoutePainted = whenRoutePainted;
  const APP_SCHEDULER_LABEL = 'ember-app-scheduler';
  const APP_SCHEDULER_HAS_SETUP = '__APP_SCHEDULER_HAS_SETUP__';
  let _whenRouteDidChange;
  let _whenRoutePainted;
  let _whenRoutePaintedScheduleFn;
  let _whenRouteIdle;
  let _whenRouteIdleScheduleFn;
  let _activeScheduledTasks = 0;
  const CAPABILITIES = {
    requestAnimationFrameEnabled: typeof requestAnimationFrame === 'function',
    requestIdleCallbackEnabled: typeof requestIdleCallback === 'function'
  };
  let _capabilities = CAPABILITIES;
  const USE_REQUEST_IDLE_CALLBACK = true;
  _exports.USE_REQUEST_IDLE_CALLBACK = USE_REQUEST_IDLE_CALLBACK;
  const SIMPLE_CALLBACK = callback => callback();
  _exports.SIMPLE_CALLBACK = SIMPLE_CALLBACK;
  reset();
  function beginTransition() {
    if (_whenRouteDidChange.isResolved) {
      _whenRouteDidChange = _defer(APP_SCHEDULER_LABEL);
      _whenRoutePainted = _whenRouteDidChange.promise.then(() => _afterNextPaint(_whenRoutePaintedScheduleFn));
      _whenRouteIdle = _whenRoutePainted.then(() => _afterNextPaint(_whenRouteIdleScheduleFn));
    }
  }
  function endTransition() {
    _whenRouteDidChange.resolve();
  }
  function setupRouter(router) {
    if (router[APP_SCHEDULER_HAS_SETUP]) {
      return;
    }
    router[APP_SCHEDULER_HAS_SETUP] = true;
    if (true) {
      router.on('routeWillChange', beginTransition);
      router.on('routeDidChange', endTransition);
    } else {
      router.on('willTransition', beginTransition);
      router.on('didTransition', endTransition);
    }
  }
  function reset() {
    _whenRouteDidChange = _defer(APP_SCHEDULER_LABEL);
    _whenRoutePainted = _whenRouteDidChange.promise.then();
    _whenRouteIdle = _whenRoutePainted.then();
    _whenRouteDidChange.resolve();
    _activeScheduledTasks = 0;
  }

  /**
   * Top level promise that represents the entry point for deferred work.
   * Subsequent promises are chained off this promise, successively composing
   * them together to approximate when painting has occurred.
   *
   * @public
   */
  function didTransition() {
    return _whenRouteDidChange.promise;
  }

  /**
   * This promise, when resolved, approximates after the route is first painted.
   * This can be used to schedule work to occur that is lower priority than initial
   * work (content outside of the viewport, rendering non-critical content).
   *
   * @public
   */
  function whenRoutePainted() {
    return _whenRoutePainted;
  }

  /**
   * This promise, when resolved, approximates after content is painted.
   *
   * @public
   */
  function whenRouteIdle() {
    return _whenRouteIdle;
  }

  /**
   * Used for testing
   */
  function routeSettled() {
    return _whenRouteIdle;
  }
  function _getScheduleFn() {
    let useRequestIdleCallback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (useRequestIdleCallback && _capabilities.requestIdleCallbackEnabled) {
      return requestIdleCallback;
    } else if (_capabilities.requestAnimationFrameEnabled) {
      return requestAnimationFrame;
    } else {
      return SIMPLE_CALLBACK;
    }
  }
  function _setCapabilities() {
    let newCapabilities = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : CAPABILITIES;
    _capabilities = newCapabilities;
  }
  _whenRoutePaintedScheduleFn = _getScheduleFn();
  _whenRouteIdleScheduleFn = _getScheduleFn(USE_REQUEST_IDLE_CALLBACK);
  function _afterNextPaint(scheduleFn) {
    let promise = new Ember.RSVP.Promise(resolve => {
      if (false /* DEBUG */) {
        _activeScheduledTasks++;
      }
      scheduleFn(() => {
        Ember.run.later(resolve, 0);
      });
    });
    if (false /* DEBUG */) {
      promise = promise.finally(() => {
        _activeScheduledTasks--;
      });
    }
    return promise;
  }
  if (false /* DEBUG */) {
    // wait until no active rafs
    Ember.Test.registerWaiter(() => _activeScheduledTasks === 0);
  }
  function _defer(label) {
    let _isResolved = false;
    let _resolve;
    let _reject;
    const promise = new Ember.RSVP.Promise((resolve, reject) => {
      _resolve = () => {
        _isResolved = true;
        resolve();
      };
      _reject = reject;
    }, label);
    return {
      promise,
      resolve: _resolve,
      reject: _reject,
      get isResolved() {
        return _isResolved;
      }
    };
  }
});