define("soferuj/modules/application/adapter", ["exports", "@ember-data/adapter/json-api", "soferuj/config/environment"], function (_exports, _jsonApi, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _jsonApi.default.extend({
    //namespace: '/api',
    host: _environment.default.APP.API_URL,
    ajax: function (url, method, hash) {
      // hash.crossDomain = true
      hash = hash || {};
      hash.xhrFields = {
        withCredentials: true
      };
      return this._super(url, method, hash);
    },
    // to user adapter?
    urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return `${this.host}/me`;
      }
      return this._super(...arguments);
    }
  });
  _exports.default = _default;
});