define("soferuj/modules/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      scrollDown() {
        const content = document.getElementById('home-content');
        if (!!content && content.scrollIntoView) {
          content.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }
    }
  });
  _exports.default = _default;
});