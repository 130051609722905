define('@ember-data/record-data/-private', ['exports', '@ember-data/store/-private', '@ember/ordered-set'], function (exports, Private, EmberOrderedSet) { 'use strict';

  EmberOrderedSet = EmberOrderedSet && Object.prototype.hasOwnProperty.call(EmberOrderedSet, 'default') ? EmberOrderedSet['default'] : EmberOrderedSet;

  // Used by the store to normalize IDs entering the store.  Despite the fact
  // that developers may provide IDs as numbers (e.g., `store.findRecord('person', 1)`),
  // it is important that internally we use strings, since IDs may be serialized
  // and lose type information.  For example, Ember's router may put a record's
  // ID into the URL, and if we later try to deserialize that URL and find the
  // corresponding record, we will not know if it is a string or a number.
  function coerceId(id) {
    if (id === null || id === undefined || id === '') {
      return null;
    }
    if (typeof id === 'string') {
      return id;
    }
    if (typeof id === 'symbol') {
      return id.toString();
    }
    return '' + id;
  }

  /*
    This method normalizes a link to an "links object". If the passed link is
    already an object it's returned without any modifications.

    See http://jsonapi.org/format/#document-links for more information.

    @method _normalizeLink
    @internal
    @param {String} link
    @return {Object|null}
  */

  function _normalizeLink(link) {
    switch (typeof link) {
      case 'object':
        return link;
      case 'string':
        return {
          href: link
        };
    }
    return null;
  }

  class EmberDataOrderedSet extends EmberOrderedSet {
    static create() {
      return new this();
    }
    addWithIndex(obj, idx) {
      var guid = Ember.guidFor(obj);
      var presenceSet = this.presenceSet;
      var list = this.list;
      if (presenceSet[guid] === true) {
        return;
      }
      presenceSet[guid] = true;
      if (idx === undefined || idx === null) {
        list.push(obj);
      } else {
        list.splice(idx, 0, obj);
      }
      this.size += 1;
      return this;
    }
    deleteWithIndex(obj, idx) {
      var guid = Ember.guidFor(obj);
      var presenceSet = this.presenceSet;
      var list = this.list;
      if (presenceSet[guid] === true) {
        delete presenceSet[guid];
        var index = idx !== undefined ? idx : list.indexOf(obj);
        if (index > -1) {
          list.splice(index, 1);
        }
        this.size = list.length;
        return true;
      } else {
        return false;
      }
    }
  }

  function relationshipsFor(instance) {
    var recordData = Private.recordDataFor(instance) || instance;
    return recordData._relationships;
  }
  function relationshipStateFor(instance, propertyName) {
    return relationshipsFor(instance).get(propertyName);
  }
  function implicitRelationshipsFor(instance) {
    var recordData = Private.recordDataFor(instance) || instance;
    return recordData._implicitRelationships;
  }
  function implicitRelationshipStateFor(instance, propertyName) {
    return implicitRelationshipsFor(instance)[propertyName];
  }

  /**
    @module @ember-data/store
  */

  class Relationship {
    constructor(store, inverseKey, relationshipMeta, recordData, inverseIsAsync) {
      this.inverseIsAsync = void 0;
      this.kind = void 0;
      this.recordData = void 0;
      this.members = void 0;
      this.canonicalMembers = void 0;
      this.store = void 0;
      this.key = void 0;
      this.inverseKey = void 0;
      this.isAsync = void 0;
      this.isPolymorphic = void 0;
      this.relationshipMeta = void 0;
      this.inverseKeyForImplicit = void 0;
      this.meta = void 0;
      this.__inverseMeta = void 0;
      this._tempModelName = void 0;
      this.shouldForceReload = false;
      this.relationshipIsStale = void 0;
      this.hasDematerializedInverse = void 0;
      this.hasAnyRelationshipData = void 0;
      this.relationshipIsEmpty = void 0;
      this.hasFailedLoadAttempt = false;
      this.links = void 0;
      this.willSync = void 0;
      this.inverseIsAsync = inverseIsAsync;
      this.kind = relationshipMeta.kind;
      var async = relationshipMeta.options.async;
      var polymorphic = relationshipMeta.options.polymorphic;
      this.recordData = recordData;
      this.members = new EmberDataOrderedSet();
      this.canonicalMembers = new EmberDataOrderedSet();
      this.store = store;
      this.key = relationshipMeta.key || null;
      this.inverseKey = inverseKey;
      this.isAsync = typeof async === 'undefined' ? true : async;
      this.isPolymorphic = typeof polymorphic === 'undefined' ? false : polymorphic;
      this.relationshipMeta = relationshipMeta;
      //This probably breaks for polymorphic relationship in complex scenarios, due to
      //multiple possible modelNames
      this.inverseKeyForImplicit = this._tempModelName + this.key;
      this.meta = null;
      this.__inverseMeta = undefined;

      /*
       This flag forces fetch. `true` for a single request once `reload()`
         has been called `false` at all other times.
      */
      // this.shouldForceReload = false;

      /*
         This flag indicates whether we should
          re-fetch the relationship the next time
          it is accessed.
           The difference between this flag and `shouldForceReload`
          is in how we treat the presence of partially missing data:
            - for a forced reload, we will reload the link or EVERY record
            - for a stale reload, we will reload the link (if present) else only MISSING records
           Ideally these flags could be merged, but because we don't give the
          request layer the option of deciding how to resolve the data being queried
          we are forced to differentiate for now.
           It is also possible for a relationship to remain stale after a forced reload; however,
          in this case `hasFailedLoadAttempt` ought to be `true`.
         false when
          => recordData.isNew() on initial setup
          => a previously triggered request has resolved
          => we get relationship data via push
         true when
          => !recordData.isNew() on initial setup
          => an inverse has been unloaded
          => we get a new link for the relationship
         TODO @runspired unskip the acceptance tests and fix these flags
       */
      this.relationshipIsStale = false;

      /*
       This flag indicates whether we should
        **partially** re-fetch the relationship the
        next time it is accessed.
       false when
        => initial setup
        => a previously triggered request has resolved
       true when
        => an inverse has been unloaded
      */
      this.hasDematerializedInverse = false;

      /*
        This flag indicates whether we should consider the content
         of this relationship "known".
         If we have no relationship knowledge, and the relationship
         is `async`, we will attempt to fetch the relationship on
         access if it is also stale.
        Snapshot uses this to tell the difference between unknown
        (`undefined`) or empty (`null`). The reason for this is that
        we wouldn't want to serialize  unknown relationships as `null`
        as that might overwrite remote state.
         All relationships for a newly created (`store.createRecord()`) are
         considered known (`hasAnyRelationshipData === true`).
         true when
          => we receive a push with either new data or explicit empty (`[]` or `null`)
          => the relationship is a belongsTo and we have received data from
               the other side.
         false when
          => we have received no signal about what data belongs in this relationship
          => the relationship is a hasMany and we have only received data from
              the other side.
       */
      this.hasAnyRelationshipData = false;

      /*
        Flag that indicates whether an empty relationship is explicitly empty
          (signaled by push giving us an empty array or null relationship)
          e.g. an API response has told us that this relationship is empty.
         Thus far, it does not appear that we actually need this flag; however,
          @runspired has found it invaluable when debugging relationship tests
          to determine whether (and why if so) we are in an incorrect state.
         true when
          => we receive a push with explicit empty (`[]` or `null`)
          => we have received no signal about what data belongs in this relationship
          => on initial create (as no signal is known yet)
         false at all other times
       */
      this.relationshipIsEmpty = true;

      /*
        Flag def here for reference, defined as getter in has-many.js / belongs-to.js
         true when
          => hasAnyRelationshipData is true
          AND
          => members (NOT canonicalMembers) @each !isEmpty
         TODO, consider changing the conditional here from !isEmpty to !hiddenFromRecordArrays
      */

      // TODO do we want this anymore? Seems somewhat useful
      //   especially if we rename to `hasUpdatedLink`
      //   which would tell us slightly more about why the
      //   relationship is stale
      // this.updatedLink = false;
    }

    get isNew() {
      return this.recordData.isNew();
    }
    _inverseIsAsync() {
      return !!this.inverseIsAsync;
    }
    _inverseIsSync() {
      return !!(this.inverseKey && !this.inverseIsAsync);
    }
    _hasSupportForImplicitRelationships(recordData) {
      return recordData._implicitRelationships !== undefined && recordData._implicitRelationships !== null;
    }
    _hasSupportForRelationships(recordData) {
      return recordData._relationships !== undefined && recordData._relationships !== null;
    }
    get _inverseMeta() {
      if (this.__inverseMeta === undefined) {
        var inverseMeta = null;
        if (this.inverseKey) {
          // We know we have a full inverse relationship
          var type = this.relationshipMeta.type;
          var inverseModelClass = this.store.modelFor(type);
          var inverseRelationships = Ember.get(inverseModelClass, 'relationshipsByName');
          inverseMeta = inverseRelationships.get(this.inverseKey);
        }
        this.__inverseMeta = inverseMeta;
      }
      return this.__inverseMeta;
    }
    recordDataDidDematerialize() {
      var inverseKey = this.inverseKey;
      if (!inverseKey) {
        return;
      }

      // we actually want a union of members and canonicalMembers
      // they should be disjoint but currently are not due to a bug
      this.forAllMembers(inverseRecordData => {
        if (!this._hasSupportForRelationships(inverseRecordData)) {
          return;
        }
        var relationship = relationshipStateFor(inverseRecordData, inverseKey);
        var belongsToRelationship = inverseRecordData.getBelongsTo(inverseKey)._relationship;

        // For canonical members, it is possible that inverseRecordData has already been associated to
        // to another record. For such cases, do not dematerialize the inverseRecordData
        if (!belongsToRelationship || !belongsToRelationship.inverseRecordData || this.recordData === belongsToRelationship.inverseRecordData) {
          relationship.inverseDidDematerialize(this.recordData);
        }
      });
    }
    forAllMembers(callback) {
      var seen = Object.create(null);
      for (var i = 0; i < this.members.list.length; i++) {
        var inverseInternalModel = this.members.list[i];
        var id = Ember.guidFor(inverseInternalModel);
        if (!seen[id]) {
          seen[id] = true;
          callback(inverseInternalModel);
        }
      }
      for (var _i = 0; _i < this.canonicalMembers.list.length; _i++) {
        var _inverseInternalModel = this.canonicalMembers.list[_i];
        var _id = Ember.guidFor(_inverseInternalModel);
        if (!seen[_id]) {
          seen[_id] = true;
          callback(_inverseInternalModel);
        }
      }
    }
    inverseDidDematerialize(inverseRecordData) {
      if (!this.isAsync || inverseRecordData && inverseRecordData.isNew()) {
        // unloading inverse of a sync relationship is treated as a client-side
        // delete, so actually remove the models don't merely invalidate the cp
        // cache.
        // if the record being unloaded only exists on the client, we similarly
        // treat it as a client side delete
        this.removeRecordDataFromOwn(inverseRecordData);
        this.removeCanonicalRecordDataFromOwn(inverseRecordData);
        this.setRelationshipIsEmpty(true);
      } else {
        this.setHasDematerializedInverse(true);
      }
    }
    updateMeta(meta) {
      this.meta = meta;
    }
    clear() {
      var members = this.members.list;
      while (members.length > 0) {
        var member = members[0];
        this.removeRecordData(member);
      }
      var canonicalMembers = this.canonicalMembers.list;
      while (canonicalMembers.length > 0) {
        var _member = canonicalMembers[0];
        this.removeCanonicalRecordData(_member);
      }
    }
    removeAllRecordDatasFromOwn() {
      this.setRelationshipIsStale(true);
      this.members.clear();
    }
    removeAllCanonicalRecordDatasFromOwn() {
      this.canonicalMembers.clear();
      this.flushCanonicalLater();
    }
    removeRecordDatas(recordDatas) {
      recordDatas.forEach(recordData => this.removeRecordData(recordData));
    }
    addRecordDatas(recordDatas, idx) {
      recordDatas.forEach(recordData => {
        this.addRecordData(recordData, idx);
        if (idx !== undefined) {
          idx++;
        }
      });
    }
    addCanonicalRecordData(recordData, idx) {
      if (!this.canonicalMembers.has(recordData)) {
        this.canonicalMembers.add(recordData);
        this.setupInverseRelationship(recordData);
      }
      this.flushCanonicalLater();
      this.setHasAnyRelationshipData(true);
    }
    setupInverseRelationship(recordData) {
      if (this.inverseKey) {
        if (!this._hasSupportForRelationships(recordData)) {
          return;
        }
        var relationship = relationshipStateFor(recordData, this.inverseKey);
        // if we have only just initialized the inverse relationship, then it
        // already has this.recordData in its canonicalMembers, so skip the
        // unnecessary work.  The exception to this is polymorphic
        // relationships whose members are determined by their inverse, as those
        // relationships cannot efficiently find their inverse payloads.
        relationship.addCanonicalRecordData(this.recordData);
      } else {
        if (!this._hasSupportForImplicitRelationships(recordData)) {
          return;
        }
        var relationships = recordData._implicitRelationships;
        var _relationship = relationships[this.inverseKeyForImplicit];
        if (!_relationship) {
          _relationship = relationships[this.inverseKeyForImplicit] = new Relationship(this.store, this.key, {
            options: {
              async: this.isAsync
            }
          }, recordData);
        }
        _relationship.addCanonicalRecordData(this.recordData);
      }
    }
    removeCanonicalRecordDatas(recordDatas, idx) {
      for (var i = 0; i < recordDatas.length; i++) {
        if (idx !== undefined) {
          this.removeCanonicalRecordData(recordDatas[i], i + idx);
        } else {
          this.removeCanonicalRecordData(recordDatas[i]);
        }
      }
    }
    removeCanonicalRecordData(recordData, idx) {
      if (this.canonicalMembers.has(recordData)) {
        this.removeCanonicalRecordDataFromOwn(recordData, idx);
        if (this.inverseKey) {
          this.removeCanonicalRecordDataFromInverse(recordData);
        } else {
          if (this._hasSupportForImplicitRelationships(recordData) && recordData._implicitRelationships[this.inverseKeyForImplicit]) {
            recordData._implicitRelationships[this.inverseKeyForImplicit].removeCanonicalRecordData(this.recordData);
          }
        }
      }
      this.flushCanonicalLater();
    }
    addRecordData(recordData, idx) {
      if (!this.members.has(recordData)) {
        this.members.addWithIndex(recordData, idx);
        this.notifyRecordRelationshipAdded(recordData, idx);
        if (this._hasSupportForRelationships(recordData) && this.inverseKey) {
          relationshipStateFor(recordData, this.inverseKey).addRecordData(this.recordData);
        } else {
          if (this._hasSupportForImplicitRelationships(recordData)) {
            if (!recordData._implicitRelationships[this.inverseKeyForImplicit]) {
              recordData._implicitRelationships[this.inverseKeyForImplicit] = new Relationship(this.store, this.key, {
                options: {
                  async: this.isAsync
                }
              }, recordData, this.isAsync);
            }
            recordData._implicitRelationships[this.inverseKeyForImplicit].addRecordData(this.recordData);
          }
        }
      }
      this.setHasAnyRelationshipData(true);
    }
    removeRecordData(recordData) {
      if (this.members.has(recordData)) {
        this.removeRecordDataFromOwn(recordData);
        if (this.inverseKey) {
          this.removeRecordDataFromInverse(recordData);
        } else {
          if (this._hasSupportForImplicitRelationships(recordData) && recordData._implicitRelationships[this.inverseKeyForImplicit]) {
            recordData._implicitRelationships[this.inverseKeyForImplicit].removeRecordData(this.recordData);
          }
        }
      }
    }
    removeRecordDataFromInverse(recordData) {
      if (!this._hasSupportForRelationships(recordData)) {
        return;
      }
      if (this.inverseKey) {
        var inverseRelationship = relationshipStateFor(recordData, this.inverseKey);
        //Need to check for existence, as the record might unloading at the moment
        if (inverseRelationship) {
          inverseRelationship.removeRecordDataFromOwn(this.recordData);
        }
      }
    }
    removeRecordDataFromOwn(recordData, idx) {
      this.members.delete(recordData);
    }
    removeCanonicalRecordDataFromInverse(recordData) {
      if (!this._hasSupportForRelationships(recordData)) {
        return;
      }
      if (this.inverseKey) {
        var inverseRelationship = relationshipStateFor(recordData, this.inverseKey);
        //Need to check for existence, as the record might unloading at the moment
        if (inverseRelationship) {
          inverseRelationship.removeCanonicalRecordDataFromOwn(this.recordData);
        }
      }
    }
    removeCanonicalRecordDataFromOwn(recordData, idx) {
      this.canonicalMembers.deleteWithIndex(recordData, idx);
      this.flushCanonicalLater();
    }

    /*
      Call this method once a record deletion has been persisted
      to purge it from BOTH current and canonical state of all
      relationships.
       @method removeCompletelyFromInverse
      @private
     */
    removeCompletelyFromInverse() {
      if (!this.inverseKey && !this.inverseKeyForImplicit) {
        return;
      }

      // we actually want a union of members and canonicalMembers
      // they should be disjoint but currently are not due to a bug
      var seen = Object.create(null);
      var recordData = this.recordData;
      var unload;
      if (this.inverseKey) {
        unload = inverseRecordData => {
          var id = Ember.guidFor(inverseRecordData);
          if (this._hasSupportForRelationships(inverseRecordData) && seen[id] === undefined) {
            if (this.inverseKey) {
              var relationship = relationshipStateFor(inverseRecordData, this.inverseKey);
              relationship.removeCompletelyFromOwn(recordData);
            }
            seen[id] = true;
          }
        };
      } else {
        unload = inverseRecordData => {
          var id = Ember.guidFor(inverseRecordData);
          if (this._hasSupportForImplicitRelationships(inverseRecordData) && seen[id] === undefined) {
            var relationship = implicitRelationshipStateFor(inverseRecordData, this.inverseKeyForImplicit);
            relationship.removeCompletelyFromOwn(recordData);
            seen[id] = true;
          }
        };
      }
      this.members.forEach(unload);
      this.canonicalMembers.forEach(unload);
      if (!this.isAsync) {
        this.clear();
      }
    }

    /*
      Removes the given RecordData from BOTH canonical AND current state.
       This method is useful when either a deletion or a rollback on a new record
      needs to entirely purge itself from an inverse relationship.
     */
    removeCompletelyFromOwn(recordData) {
      this.canonicalMembers.delete(recordData);
      this.members.delete(recordData);
    }
    flushCanonical() {
      var list = this.members.list;
      this.willSync = false;
      //a hack for not removing new RecordDatas
      //TODO remove once we have proper diffing
      var newRecordDatas = [];
      for (var i = 0; i < list.length; i++) {
        // TODO Igor deal with this
        if (list[i].isNew()) {
          newRecordDatas.push(list[i]);
        }
      }

      //TODO(Igor) make this less abysmally slow
      this.members = this.canonicalMembers.copy();
      for (var _i2 = 0; _i2 < newRecordDatas.length; _i2++) {
        this.members.add(newRecordDatas[_i2]);
      }
    }
    flushCanonicalLater() {
      if (this.willSync) {
        return;
      }
      this.willSync = true;
      // Reaching back into the store to use ED's runloop
      this.store._updateRelationshipState(this);
    }
    updateLinks(links) {
      this.links = links;
    }
    updateRecordDatasFromAdapter(recordDatas) {
      this.setHasAnyRelationshipData(true);
      //TODO(Igor) move this to a proper place
      //TODO Once we have adapter support, we need to handle updated and canonical changes
      this.computeChanges(recordDatas);
    }
    computeChanges(recordDatas) {}
    notifyRecordRelationshipAdded(recordData, idxs) {}
    setHasAnyRelationshipData(value) {
      this.hasAnyRelationshipData = value;
    }
    setHasDematerializedInverse(value) {
      this.hasDematerializedInverse = value;
    }
    setRelationshipIsStale(value) {
      this.relationshipIsStale = value;
    }
    setRelationshipIsEmpty(value) {
      this.relationshipIsEmpty = value;
    }
    setShouldForceReload(value) {
      this.shouldForceReload = value;
    }
    setHasFailedLoadAttempt(value) {
      this.hasFailedLoadAttempt = value;
    }

    /*
     `push` for a relationship allows the store to push a JSON API Relationship
     Object onto the relationship. The relationship will then extract and set the
     meta, data and links of that relationship.
      `push` use `updateMeta`, `updateData` and `updateLink` to update the state
     of the relationship.
     */
    push(payload) {
      var hasRelationshipDataProperty = false;
      var hasLink = false;
      if (payload.meta) {
        this.updateMeta(payload.meta);
      }
      if (payload.data !== undefined) {
        hasRelationshipDataProperty = true;
        this.updateData(payload.data);
      } else if (this.isAsync === false && !this.hasAnyRelationshipData) {
        hasRelationshipDataProperty = true;
        var data = this.kind === 'hasMany' ? [] : null;
        this.updateData(data);
      }
      if (payload.links) {
        var originalLinks = this.links;
        this.updateLinks(payload.links);
        if (payload.links.related) {
          var relatedLink = _normalizeLink(payload.links.related);
          var currentLink = originalLinks && originalLinks.related ? _normalizeLink(originalLinks.related) : null;
          var currentLinkHref = currentLink ? currentLink.href : null;
          if (relatedLink && relatedLink.href && relatedLink.href !== currentLinkHref) {
            hasLink = true;
          }
        }
      }

      /*
       Data being pushed into the relationship might contain only data or links,
       or a combination of both.
        IF contains only data
       IF contains both links and data
        relationshipIsEmpty -> true if is empty array (has-many) or is null (belongs-to)
        hasAnyRelationshipData -> true
        hasDematerializedInverse -> false
        relationshipIsStale -> false
        allInverseRecordsAreLoaded -> run-check-to-determine
        IF contains only links
        relationshipIsStale -> true
       */
      this.setHasFailedLoadAttempt(false);
      if (hasRelationshipDataProperty) {
        var relationshipIsEmpty = payload.data === null || Array.isArray(payload.data) && payload.data.length === 0;
        this.setHasAnyRelationshipData(true);
        this.setRelationshipIsStale(false);
        this.setHasDematerializedInverse(false);
        this.setRelationshipIsEmpty(relationshipIsEmpty);
      } else if (hasLink) {
        this.setRelationshipIsStale(true);
        var recordData = this.recordData;
        var storeWrapper = this.recordData.storeWrapper;
        {
          storeWrapper.notifyPropertyChange(recordData.modelName, recordData.id, recordData.clientId,
          // We know we are not an implicit relationship here
          this.key);
        }
      }
    }
    localStateIsEmpty() {}
    updateData(payload) {}
    destroy() {}
  }

  class BelongsToRelationship extends Relationship {
    constructor(store, inverseKey, relationshipMeta, recordData, inverseIsAsync) {
      super(store, inverseKey, relationshipMeta, recordData, inverseIsAsync);
      this.inverseRecordData = void 0;
      this.canonicalState = void 0;
      this.key = void 0;
      this.key = relationshipMeta.key;
      this.inverseRecordData = null;
      this.canonicalState = null;
      this.key = relationshipMeta.key;
    }
    setRecordData(recordData) {
      if (recordData) {
        this.addRecordData(recordData);
      } else if (this.inverseRecordData) {
        this.removeRecordData(this.inverseRecordData);
      }
      this.setHasAnyRelationshipData(true);
      this.setRelationshipIsStale(false);
      this.setRelationshipIsEmpty(false);
    }
    setCanonicalRecordData(recordData) {
      if (recordData) {
        this.addCanonicalRecordData(recordData);
      } else if (this.canonicalState) {
        this.removeCanonicalRecordData(this.canonicalState);
      }
      this.flushCanonicalLater();
    }
    addCanonicalRecordData(recordData) {
      if (this.canonicalMembers.has(recordData)) {
        return;
      }
      if (this.canonicalState) {
        this.removeCanonicalRecordData(this.canonicalState);
      }
      this.canonicalState = recordData;
      super.addCanonicalRecordData(recordData);
      this.setHasAnyRelationshipData(true);
      this.setRelationshipIsEmpty(false);
    }
    inverseDidDematerialize() {
      super.inverseDidDematerialize(this.inverseRecordData);
      this.notifyBelongsToChange();
    }
    removeCompletelyFromOwn(recordData) {
      super.removeCompletelyFromOwn(recordData);
      if (this.canonicalState === recordData) {
        this.canonicalState = null;
      }
      if (this.inverseRecordData === recordData) {
        this.inverseRecordData = null;
        this.notifyBelongsToChange();
      }
    }
    removeCompletelyFromInverse() {
      super.removeCompletelyFromInverse();
      this.inverseRecordData = null;
    }
    flushCanonical() {
      //temporary fix to not remove newly created records if server returned null.
      //TODO remove once we have proper diffing
      if (this.inverseRecordData && this.inverseRecordData.isNew() && !this.canonicalState) {
        this.willSync = false;
        return;
      }
      if (this.inverseRecordData !== this.canonicalState) {
        this.inverseRecordData = this.canonicalState;
        this.notifyBelongsToChange();
      }
      super.flushCanonical();
    }
    addRecordData(recordData) {
      if (this.members.has(recordData)) {
        return;
      }

      // TODO Igor cleanup

      if (this.inverseRecordData) {
        this.removeRecordData(this.inverseRecordData);
      }
      this.inverseRecordData = recordData;
      super.addRecordData(recordData);
      this.notifyBelongsToChange();
    }
    removeRecordDataFromOwn(recordData) {
      if (!this.members.has(recordData)) {
        return;
      }
      this.inverseRecordData = null;
      super.removeRecordDataFromOwn(recordData);
      this.notifyBelongsToChange();
    }
    removeAllRecordDatasFromOwn() {
      super.removeAllRecordDatasFromOwn();
      this.inverseRecordData = null;
      this.notifyBelongsToChange();
    }
    notifyBelongsToChange() {
      var recordData = this.recordData;
      var storeWrapper = this.recordData.storeWrapper;
      storeWrapper.notifyBelongsToChange(recordData.modelName, recordData.id, recordData.clientId, this.key);
    }
    removeCanonicalRecordDataFromOwn(recordData, idx) {
      if (!this.canonicalMembers.has(recordData)) {
        return;
      }
      this.canonicalState = null;
      this.setHasAnyRelationshipData(true);
      this.setRelationshipIsEmpty(true);
      super.removeCanonicalRecordDataFromOwn(recordData, idx);
    }
    removeAllCanonicalRecordDatasFromOwn() {
      super.removeAllCanonicalRecordDatasFromOwn();
      this.canonicalState = null;
    }
    getData() {
      var data;
      var payload = {};
      if (this.inverseRecordData) {
        data = this.inverseRecordData.getResourceIdentifier();
      }
      if (this.inverseRecordData === null && this.hasAnyRelationshipData) {
        data = null;
      }
      if (this.links) {
        payload.links = this.links;
      }
      if (data !== undefined) {
        payload.data = data;
      }
      if (this.meta) {
        payload.meta = this.meta;
      }
      payload._relationship = this;
      return payload;
    }
    updateData(data) {
      var recordData;
      if (Ember.isNone(data)) {
        recordData = null;
      }
      if (recordData !== null) {
        recordData = this.recordData.storeWrapper.recordDataFor(data.type, data.id);
      }
      this.setCanonicalRecordData(recordData);
    }
  }

  /**
    @module @ember-data/record-data
  */

  class ManyRelationship extends Relationship {
    constructor(store, inverseKey, relationshipMeta, recordData, inverseIsAsync) {
      super(store, inverseKey, relationshipMeta, recordData, inverseIsAsync);
      // persisted state
      this.canonicalState = void 0;
      this.currentState = void 0;
      this._willUpdateManyArray = void 0;
      this._pendingManyArrayUpdates = void 0;
      this.key = void 0;
      this.canonicalState = [];
      // local client state
      this.currentState = [];
      this._willUpdateManyArray = false;
      this._pendingManyArrayUpdates = null;
      this.key = relationshipMeta.key;
    }
    addCanonicalRecordData(recordData, idx) {
      if (this.canonicalMembers.has(recordData)) {
        return;
      }
      if (idx !== undefined) {
        this.canonicalState.splice(idx, 0, recordData);
      } else {
        this.canonicalState.push(recordData);
      }
      super.addCanonicalRecordData(recordData, idx);
    }
    inverseDidDematerialize(inverseRecordData) {
      super.inverseDidDematerialize(inverseRecordData);
      if (this.isAsync) {
        this.notifyManyArrayIsStale();
      }
    }
    addRecordData(recordData, idx) {
      if (this.members.has(recordData)) {
        return;
      }

      // TODO Type this

      super.addRecordData(recordData, idx);
      // make lazy later
      if (idx === undefined) {
        idx = this.currentState.length;
      }
      this.currentState.splice(idx, 0, recordData);
      // TODO Igor consider making direct to remove the indirection
      // We are not lazily accessing the manyArray here because the change is coming from app side
      // this.manyArray.flushCanonical(this.currentState);
      this.notifyHasManyChange();
    }
    removeCanonicalRecordDataFromOwn(recordData, idx) {
      var i = idx;
      if (!this.canonicalMembers.has(recordData)) {
        return;
      }
      if (i === undefined) {
        i = this.canonicalState.indexOf(recordData);
      }
      if (i > -1) {
        this.canonicalState.splice(i, 1);
      }
      super.removeCanonicalRecordDataFromOwn(recordData, idx);
      //TODO(Igor) Figure out what to do here
    }

    removeAllCanonicalRecordDatasFromOwn() {
      super.removeAllCanonicalRecordDatasFromOwn();
      this.canonicalMembers.clear();
      this.canonicalState.splice(0, this.canonicalState.length);
      super.removeAllCanonicalRecordDatasFromOwn();
    }

    //TODO(Igor) DO WE NEED THIS?
    removeCompletelyFromOwn(recordData) {
      super.removeCompletelyFromOwn(recordData);

      // TODO SkEPTICAL
      var canonicalIndex = this.canonicalState.indexOf(recordData);
      if (canonicalIndex !== -1) {
        this.canonicalState.splice(canonicalIndex, 1);
      }
      this.removeRecordDataFromOwn(recordData);
    }
    flushCanonical() {
      var toSet = this.canonicalState;

      //a hack for not removing new records
      //TODO remove once we have proper diffing
      var newRecordDatas = this.currentState.filter(
      // only add new internalModels which are not yet in the canonical state of this
      // relationship (a new internalModel can be in the canonical state if it has
      // been 'acknowleged' to be in the relationship via a store.push)

      //TODO Igor deal with this
      recordData => recordData.isNew() && toSet.indexOf(recordData) === -1);
      toSet = toSet.concat(newRecordDatas);

      /*
      if (this._manyArray) {
        this._manyArray.flushCanonical(toSet);
      }
      */
      this.currentState = toSet;
      super.flushCanonical();
      // Once we clean up all the flushing, we will be left with at least the notifying part
      this.notifyHasManyChange();
    }

    //TODO(Igor) idx not used currently, fix
    removeRecordDataFromOwn(recordData, idx) {
      super.removeRecordDataFromOwn(recordData, idx);
      var index = idx || this.currentState.indexOf(recordData);

      //TODO IGOR DAVID INVESTIGATE
      if (index === -1) {
        return;
      }
      this.currentState.splice(index, 1);
      // TODO Igor consider making direct to remove the indirection
      // We are not lazily accessing the manyArray here because the change is coming from app side
      this.notifyHasManyChange();
      // this.manyArray.flushCanonical(this.currentState);
    }

    notifyRecordRelationshipAdded() {
      this.notifyHasManyChange();
    }
    computeChanges(recordDatas) {
      if (recordDatas === void 0) {
        recordDatas = [];
      }
      var members = this.canonicalMembers.toArray();
      for (var i = members.length - 1; i >= 0; i--) {
        this.removeCanonicalRecordData(members[i], i);
      }
      for (var _i = 0, l = recordDatas.length; _i < l; _i++) {
        this.addCanonicalRecordData(recordDatas[_i], _i);
      }
    }

    /*
      This is essentially a "sync" version of
        notifyHasManyChange. We should work to unify
        these worlds
         - @runspired
    */
    notifyManyArrayIsStale() {
      var recordData = this.recordData;
      var storeWrapper = recordData.storeWrapper;
      {
        storeWrapper.notifyPropertyChange(recordData.modelName, recordData.id, recordData.clientId, this.key);
      }
    }
    notifyHasManyChange() {
      var recordData = this.recordData;
      var storeWrapper = recordData.storeWrapper;
      storeWrapper.notifyHasManyChange(recordData.modelName, recordData.id, recordData.clientId, this.key);
    }
    getData() {
      var payload = {};
      if (this.hasAnyRelationshipData) {
        payload.data = this.currentState.map(recordData => recordData.getResourceIdentifier());
      }
      if (this.links) {
        payload.links = this.links;
      }
      if (this.meta) {
        payload.meta = this.meta;
      }

      // TODO @runspired: the @igor refactor is too limiting for relationship state
      //   we should reconsider where we fetch from.
      payload._relationship = this;
      return payload;
    }
    updateData(data) {
      var recordDatas;
      if (Ember.isNone(data)) {
        recordDatas = undefined;
      } else {
        recordDatas = new Array(data.length);
        for (var i = 0; i < data.length; i++) {
          recordDatas[i] = this.recordData.storeWrapper.recordDataFor(data[i].type, data[i].id, data[i].lid);
        }
      }
      this.updateRecordDatasFromAdapter(recordDatas);
    }
  }

  function createRelationshipFor(relationshipMeta, store, recordData, key) {
    var inverseKey = recordData.storeWrapper.inverseForRelationship(recordData.modelName, key);
    var inverseIsAsync = recordData.storeWrapper.inverseIsAsyncForRelationship(recordData.modelName, key);
    if (relationshipMeta.kind === 'hasMany') {
      return new ManyRelationship(store, inverseKey, relationshipMeta, recordData, inverseIsAsync);
    } else {
      return new BelongsToRelationship(store, inverseKey, relationshipMeta, recordData, inverseIsAsync);
    }
  }
  class Relationships {
    constructor(recordData) {
      this._store = void 0;
      this._storeWrapper = void 0;
      this.initializedRelationships = void 0;
      this.recordData = recordData;
      this.initializedRelationships = Object.create(null);
      this._storeWrapper = Private.upgradeForInternal(recordData.storeWrapper);
      this._store = this._storeWrapper._store;
    }
    has(key) {
      return !!this.initializedRelationships[key];
    }
    forEach(cb) {
      var rels = this.initializedRelationships;
      Object.keys(rels).forEach(name => {
        cb(name, rels[name]);
      });
    }
    get(key) {
      var relationships = this.initializedRelationships;
      var relationship = relationships[key];
      if (!relationship) {
        var _recordData = this.recordData;
        var rel = this.recordData.storeWrapper.relationshipsDefinitionFor(this.recordData.modelName)[key];
        if (rel) {
          // lazily instantiate relationship
          relationship = relationships[key] = createRelationshipFor(rel, this._store, _recordData, key);
        }
      }
      return relationship;
    }
  }

  /**
    @module @ember-data/record-data
  */
  var nextBfsId = 1;
  class RecordDataDefault {
    constructor(identifier, storeWrapper) {
      this._errors = void 0;
      this.__relationships = void 0;
      this.__implicitRelationships = void 0;
      this.modelName = void 0;
      this.clientId = void 0;
      this.id = void 0;
      this.isDestroyed = void 0;
      this._isNew = void 0;
      this._bfsId = void 0;
      this.__attributes = void 0;
      this.__inFlightAttributes = void 0;
      this.__data = void 0;
      this._scheduledDestroy = void 0;
      this._isDeleted = void 0;
      this._isDeletionCommited = void 0;
      /**
        Iterates over the set of internal models reachable from `this` across exactly one
        relationship.
      */
      this._directlyRelatedRecordDatasIterable = () => {
        var initializedRelationships = this._relationships.initializedRelationships;
        var relationships = Object.keys(initializedRelationships).map(key => initializedRelationships[key]);
        var i = 0;
        var j = 0;
        var k = 0;
        var findNext = () => {
          while (i < relationships.length) {
            while (j < 2) {
              var members = j === 0 ? relationships[i].members.list : relationships[i].canonicalMembers.list;
              while (k < members.length) {
                return members[k++];
              }
              k = 0;
              j++;
            }
            j = 0;
            i++;
          }
          return undefined;
        };
        return {
          iterator() {
            return {
              next: () => {
                var value = findNext();
                return {
                  value,
                  done: value === undefined
                };
              }
            };
          }
        };
      };
      this.identifier = identifier;
      this.storeWrapper = storeWrapper;
      this.modelName = identifier.type;
      this.clientId = identifier.lid;
      this.id = identifier.id;
      this.__relationships = null;
      this.__implicitRelationships = null;
      this.isDestroyed = false;
      this._isNew = false;
      this._isDeleted = false;
      // Used during the mark phase of unloading to avoid checking the same internal
      // model twice in the same scan
      this._bfsId = 0;
      this.reset();
    }

    // PUBLIC API
    getResourceIdentifier() {
      return this.identifier;
    }
    pushData(data, calculateChange) {
      var changedKeys;
      if (this._isNew) {
        this._isNew = false;
        this.notifyStateChange();
      }
      if (calculateChange) {
        changedKeys = this._changedKeys(data.attributes);
      }
      Ember.assign(this._data, data.attributes);
      if (this.__attributes) {
        // only do if we have attribute changes
        this._updateChangedAttributes();
      }
      if (data.relationships) {
        this._setupRelationships(data);
      }
      if (data.id) {
        this.id = coerceId(data.id);
      }
      return changedKeys;
    }
    willCommit() {
      this._inFlightAttributes = this._attributes;
      this._attributes = null;
    }
    hasChangedAttributes() {
      return this.__attributes !== null && Object.keys(this.__attributes).length > 0;
    }
    _clearErrors() {
    }
    getErrors() {
      {
        return [];
      }
    }

    // this is a hack bc we don't have access to the state machine
    //   and relationships need this info and @runspired didn't see
    //   how to get it just yet from storeWrapper.
    isEmpty() {
      return this.__attributes === null && this.__inFlightAttributes === null && this.__data === null;
    }
    deleteRecord() {
      this._isDeleted = true;
      this.notifyStateChange();
    }
    isDeleted() {
      return this._isDeleted;
    }
    setIsDeleted(isDeleted) {
      this._isDeleted = isDeleted;
      if (this._isNew) {
        this._deletionConfirmed();
      }
      this.notifyStateChange();
    }
    isDeletionCommitted() {
      return this._isDeletionCommited;
    }
    reset() {
      this.__attributes = null;
      this.__inFlightAttributes = null;
      this.__data = null;
      this._errors = undefined;
    }
    _setupRelationships(data) {
      var relationships = this.storeWrapper.relationshipsDefinitionFor(this.modelName);
      var keys = Object.keys(relationships);
      for (var i = 0; i < keys.length; i++) {
        var relationshipName = keys[i];
        if (!data.relationships[relationshipName]) {
          continue;
        }

        // in debug, assert payload validity eagerly
        var relationshipData = data.relationships[relationshipName];
        var relationship = this._relationships.get(relationshipName);
        relationship.push(relationshipData);
      }
    }

    /*
      Checks if the attributes which are considered as changed are still
      different to the state which is acknowledged by the server.
       This method is needed when data for the internal model is pushed and the
      pushed data might acknowledge dirty attributes as confirmed.
       @method updateChangedAttributes
      @private
     */
    _updateChangedAttributes() {
      var changedAttributes = this.changedAttributes();
      var changedAttributeNames = Object.keys(changedAttributes);
      var attrs = this._attributes;
      for (var i = 0, length = changedAttributeNames.length; i < length; i++) {
        var attribute = changedAttributeNames[i];
        var data = changedAttributes[attribute];
        var oldData = data[0];
        var newData = data[1];
        if (oldData === newData) {
          delete attrs[attribute];
        }
      }
    }

    /*
      Returns an object, whose keys are changed properties, and value is an
      [oldProp, newProp] array.
       @method changedAttributes
      @private
    */
    changedAttributes() {
      var oldData = this._data;
      var currentData = this._attributes;
      var inFlightData = this._inFlightAttributes;
      var newData = Ember.assign({}, inFlightData, currentData);
      var diffData = Object.create(null);
      var newDataKeys = Object.keys(newData);
      for (var i = 0, length = newDataKeys.length; i < length; i++) {
        var _key = newDataKeys[i];
        diffData[_key] = [oldData[_key], newData[_key]];
      }
      return diffData;
    }
    isNew() {
      return this._isNew;
    }
    rollbackAttributes() {
      var dirtyKeys;
      this._isDeleted = false;
      if (this.hasChangedAttributes()) {
        dirtyKeys = Object.keys(this._attributes);
        this._attributes = null;
      }
      if (this.isNew()) {
        this.removeFromInverseRelationships(true);
        this._isDeleted = true;
        this._isNew = false;
      }
      this._inFlightAttributes = null;
      this._clearErrors();
      this.notifyStateChange();
      return dirtyKeys;
    }
    _deletionConfirmed() {
      this.removeFromInverseRelationships();
    }
    didCommit(data) {
      if (this._isDeleted) {
        this._deletionConfirmed();
        this._isDeletionCommited = true;
      }
      this._isNew = false;
      var newCanonicalAttributes = null;
      if (data) {
        // this.store._internalModelDidReceiveRelationshipData(this.modelName, this.id, data.relationships);
        if (data.relationships) {
          this._setupRelationships(data);
        }
        if (data.id) {
          // didCommit provided an ID, notify the store of it
          this.storeWrapper.setRecordId(this.modelName, data.id, this.clientId);
          this.id = coerceId(data.id);
        }
        newCanonicalAttributes = data.attributes || null;
      }
      var changedKeys = this._changedKeys(newCanonicalAttributes);
      Ember.assign(this._data, this.__inFlightAttributes, newCanonicalAttributes);
      this._inFlightAttributes = null;
      this._updateChangedAttributes();
      this._clearErrors();
      this.notifyStateChange();
      return changedKeys;
    }
    notifyStateChange() {
    }

    // get ResourceIdentifiers for "current state"
    getHasMany(key) {
      return this._relationships.get(key).getData();
    }

    // set a new "current state" via ResourceIdentifiers
    setDirtyHasMany(key, recordDatas) {
      var relationship = this._relationships.get(key);
      relationship.clear();
      relationship.addRecordDatas(recordDatas);
    }

    // append to "current state" via RecordDatas
    addToHasMany(key, recordDatas, idx) {
      this._relationships.get(key).addRecordDatas(recordDatas, idx);
    }

    // remove from "current state" via RecordDatas
    removeFromHasMany(key, recordDatas) {
      this._relationships.get(key).removeRecordDatas(recordDatas);
    }
    commitWasRejected(identifier, errors) {
      var keys = Object.keys(this._inFlightAttributes);
      if (keys.length > 0) {
        var attrs = this._attributes;
        for (var i = 0; i < keys.length; i++) {
          if (attrs[keys[i]] === undefined) {
            attrs[keys[i]] = this._inFlightAttributes[keys[i]];
          }
        }
      }
      this._inFlightAttributes = null;
    }
    getBelongsTo(key) {
      return this._relationships.get(key).getData();
    }
    setDirtyBelongsTo(key, recordData) {
      this._relationships.get(key).setRecordData(recordData);
    }
    setDirtyAttribute(key, value) {
      var originalValue;
      // Add the new value to the changed attributes hash
      this._attributes[key] = value;
      if (key in this._inFlightAttributes) {
        originalValue = this._inFlightAttributes[key];
      } else {
        originalValue = this._data[key];
      }
      // If we went back to our original value, we shouldn't keep the attribute around anymore
      if (value === originalValue) {
        delete this._attributes[key];
      }
    }

    // internal set coming from the model
    __setId(id) {
      if (this.id !== id) {
        this.id = id;
      }
    }
    getAttr(key) {
      if (key in this._attributes) {
        return this._attributes[key];
      } else if (key in this._inFlightAttributes) {
        return this._inFlightAttributes[key];
      } else {
        return this._data[key];
      }
    }
    hasAttr(key) {
      return key in this._attributes || key in this._inFlightAttributes || key in this._data;
    }
    unloadRecord() {
      if (this.isDestroyed) {
        return;
      }
      this._destroyRelationships();
      this.reset();
      if (!this._scheduledDestroy) {
        this._scheduledDestroy = Ember.run.backburner.schedule('destroy', this, '_cleanupOrphanedRecordDatas');
      }
    }
    _cleanupOrphanedRecordDatas() {
      var relatedRecordDatas = this._allRelatedRecordDatas();
      if (areAllModelsUnloaded(relatedRecordDatas)) {
        for (var i = 0; i < relatedRecordDatas.length; ++i) {
          var recordData = relatedRecordDatas[i];
          if (!recordData.isDestroyed) {
            recordData.destroy();
          }
        }
      }
      this._scheduledDestroy = null;
    }
    destroy() {
      this._relationships.forEach((name, rel) => rel.destroy());
      this.isDestroyed = true;
      this.storeWrapper.disconnectRecord(this.modelName, this.id, this.clientId);
    }
    isRecordInUse() {
      return this.storeWrapper.isRecordInUse(this.modelName, this.id, this.clientId);
    }
    /**
      Computes the set of internal models reachable from this internal model.
       Reachability is determined over the relationship graph (ie a graph where
      nodes are internal models and edges are belongs to or has many
      relationships).
       @return {Array} An array including `this` and all internal models reachable
      from `this`.
    */
    _allRelatedRecordDatas() {
      var array = [];
      var queue = [];
      var bfsId = nextBfsId++;
      queue.push(this);
      this._bfsId = bfsId;
      while (queue.length > 0) {
        var node = queue.shift();
        array.push(node);
        var iterator = this._directlyRelatedRecordDatasIterable().iterator();
        for (var obj = iterator.next(); !obj.done; obj = iterator.next()) {
          var recordData = obj.value;
          if (recordData instanceof RecordDataDefault) {
            if (recordData._bfsId < bfsId) {
              queue.push(recordData);
              recordData._bfsId = bfsId;
            }
          }
        }
      }
      return array;
    }
    isAttrDirty(key) {
      if (this._attributes[key] === undefined) {
        return false;
      }
      var originalValue;
      if (this._inFlightAttributes[key] !== undefined) {
        originalValue = this._inFlightAttributes[key];
      } else {
        originalValue = this._data[key];
      }
      return originalValue !== this._attributes[key];
    }
    get _attributes() {
      if (this.__attributes === null) {
        this.__attributes = Object.create(null);
      }
      return this.__attributes;
    }
    set _attributes(v) {
      this.__attributes = v;
    }
    get _relationships() {
      if (this.__relationships === null) {
        this.__relationships = new Relationships(this);
      }
      return this.__relationships;
    }
    get _data() {
      if (this.__data === null) {
        this.__data = Object.create(null);
      }
      return this.__data;
    }
    set _data(v) {
      this.__data = v;
    }

    /*
     implicit relationships are relationship which have not been declared but the inverse side exists on
     another record somewhere
     For example if there was
      ```app/models/comment.js
     import Model, { attr } from '@ember-data/model';
      export default Model.extend({
       name: attr()
     });
     ```
      but there is also
      ```app/models/post.js
     import Model, { attr, hasMany } from '@ember-data/model';
      export default Model.extend({
       name: attr(),
       comments: hasMany('comment')
     });
     ```
      would have a implicit post relationship in order to be do things like remove ourselves from the post
     when we are deleted
    */
    get _implicitRelationships() {
      if (this.__implicitRelationships === null) {
        var relationships = Object.create(null);
        this.__implicitRelationships = relationships;
        return relationships;
      }
      return this.__implicitRelationships;
    }
    get _inFlightAttributes() {
      if (this.__inFlightAttributes === null) {
        this.__inFlightAttributes = Object.create(null);
      }
      return this.__inFlightAttributes;
    }
    set _inFlightAttributes(v) {
      this.__inFlightAttributes = v;
    }

    /**
     * Receives options passed to `store.createRecord` and is given the opportunity
     * to handle them.
     *
     * The return value is an object of options to pass to `Record.create()`
     *
     * @param options
     * @private
     */
    _initRecordCreateOptions(options) {
      var createOptions = {};
      if (options !== undefined) {
        var {
          modelName,
          storeWrapper: _storeWrapper2
        } = this;
        var attributeDefs = _storeWrapper2.attributesDefinitionFor(modelName);
        var relationshipDefs = _storeWrapper2.relationshipsDefinitionFor(modelName);
        var relationships = this._relationships;
        var propertyNames = Object.keys(options);
        for (var i = 0; i < propertyNames.length; i++) {
          var name = propertyNames[i];
          var propertyValue = options[name];
          if (name === 'id') {
            this.id = propertyValue;
            continue;
          }
          var fieldType = relationshipDefs[name] || attributeDefs[name];
          var kind = fieldType !== undefined ? fieldType.kind : null;
          var relationship = void 0;
          switch (kind) {
            case 'attribute':
              this.setDirtyAttribute(name, propertyValue);
              break;
            case 'belongsTo':
              this.setDirtyBelongsTo(name, propertyValue);
              relationship = relationships.get(name);
              relationship.setHasAnyRelationshipData(true);
              relationship.setRelationshipIsEmpty(false);
              break;
            case 'hasMany':
              this.setDirtyHasMany(name, propertyValue);
              relationship = relationships.get(name);
              relationship.setHasAnyRelationshipData(true);
              relationship.setRelationshipIsEmpty(false);
              break;
            default:
              // reflect back (pass-thru) unknown properties
              createOptions[name] = propertyValue;
          }
        }
      }
      return createOptions;
    }

    /*
        TODO IGOR AND DAVID this shouldn't be public
     This method should only be called by records in the `isNew()` state OR once the record
     has been deleted and that deletion has been persisted.
      It will remove this record from any associated relationships.
      If `isNew` is true (default false), it will also completely reset all
      relationships to an empty state as well.
       @method removeFromInverseRelationships
      @param {Boolean} isNew whether to unload from the `isNew` perspective
      @private
     */
    removeFromInverseRelationships(isNew) {
      if (isNew === void 0) {
        isNew = false;
      }
      this._relationships.forEach((name, rel) => {
        rel.removeCompletelyFromInverse();
        if (isNew === true) {
          rel.clear();
        }
      });
      this.__relationships = null;
      var implicitRelationships = this._implicitRelationships;
      this.__implicitRelationships = null;
      Object.keys(implicitRelationships).forEach(key => {
        var rel = implicitRelationships[key];
        rel.removeCompletelyFromInverse();
        if (isNew === true) {
          rel.clear();
        }
      });
    }
    _destroyRelationships() {
      var relationships = this._relationships;
      relationships.forEach((name, rel) => destroyRelationship(rel));
      var implicitRelationships = this._implicitRelationships;
      this.__implicitRelationships = null;
      Object.keys(implicitRelationships).forEach(key => {
        var rel = implicitRelationships[key];
        destroyRelationship(rel);
      });
    }
    clientDidCreate() {
      this._isNew = true;
    }

    /*
      Ember Data has 3 buckets for storing the value of an attribute on an internalModel.
       `_data` holds all of the attributes that have been acknowledged by
      a backend via the adapter. When rollbackAttributes is called on a model all
      attributes will revert to the record's state in `_data`.
       `_attributes` holds any change the user has made to an attribute
      that has not been acknowledged by the adapter. Any values in
      `_attributes` are have priority over values in `_data`.
       `_inFlightAttributes`. When a record is being synced with the
      backend the values in `_attributes` are copied to
      `_inFlightAttributes`. This way if the backend acknowledges the
      save but does not return the new state Ember Data can copy the
      values from `_inFlightAttributes` to `_data`. Without having to
      worry about changes made to `_attributes` while the save was
      happenign.
        Changed keys builds a list of all of the values that may have been
      changed by the backend after a successful save.
       It does this by iterating over each key, value pair in the payload
      returned from the server after a save. If the `key` is found in
      `_attributes` then the user has a local changed to the attribute
      that has not been synced with the server and the key is not
      included in the list of changed keys.
    
      If the value, for a key differs from the value in what Ember Data
      believes to be the truth about the backend state (A merger of the
      `_data` and `_inFlightAttributes` objects where
      `_inFlightAttributes` has priority) then that means the backend
      has updated the value and the key is added to the list of changed
      keys.
       @method _changedKeys
      @private
    */
    /*
        TODO IGOR DAVID
        There seems to be a potential bug here, where we will return keys that are not
        in the schema
    */
    _changedKeys(updates) {
      var changedKeys = [];
      if (updates) {
        var original, i, value, _key2;
        var keys = Object.keys(updates);
        var length = keys.length;
        var hasAttrs = this.hasChangedAttributes();
        var attrs;
        if (hasAttrs) {
          attrs = this._attributes;
        }
        original = Ember.assign(Object.create(null), this._data, this.__inFlightAttributes);
        for (i = 0; i < length; i++) {
          _key2 = keys[i];
          value = updates[_key2];

          // A value in _attributes means the user has a local change to
          // this attributes. We never override this value when merging
          // updates from the backend so we should not sent a change
          // notification if the server value differs from the original.
          if (hasAttrs === true && attrs[_key2] !== undefined) {
            continue;
          }
          if (!Ember.isEqual(original[_key2], value)) {
            changedKeys.push(_key2);
          }
        }
      }
      return changedKeys;
    }
    toString() {
      return `<${this.modelName}:${this.id}>`;
    }
  }

  // Handle dematerialization for relationship `rel`.  In all cases, notify the
  // relationship of the dematerialization: this is done so the relationship can
  // notify its inverse which needs to update state
  //
  // If the inverse is sync, unloading this record is treated as a client-side
  // delete, so we remove the inverse records from this relationship to
  // disconnect the graph.  Because it's not async, we don't need to keep around
  // the internalModel as an id-wrapper for references and because the graph is
  // disconnected we can actually destroy the internalModel when checking for
  // orphaned models.
  function destroyRelationship(rel) {
    rel.recordDataDidDematerialize();
    if (rel._inverseIsSync()) {
      rel.removeAllRecordDatasFromOwn();
      rel.removeAllCanonicalRecordDatasFromOwn();
    }
  }
  function areAllModelsUnloaded(recordDatas) {
    for (var i = 0; i < recordDatas.length; ++i) {
      if (recordDatas[i].isRecordInUse()) {
        return false;
      }
    }
    return true;
  }

  exports.BelongsToRelationship = BelongsToRelationship;
  exports.ManyRelationship = ManyRelationship;
  exports.RecordData = RecordDataDefault;
  exports.Relationship = Relationship;
  exports.relationshipStateFor = relationshipStateFor;
  exports.relationshipsFor = relationshipsFor;

  Object.defineProperty(exports, '__esModule', { value: true });

});
