define("soferuj/modules/register/driving-school/components/register-stepper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    lastValidStep: null,
    didReceiveAttrs() {
      this._super(...arguments);
      let lastValidStep = this.lastValidStep;
      let currentStepIndex = this.currentStepIndex;
      if (currentStepIndex > lastValidStep) {
        this.set('lastValidStep', currentStepIndex);
      }
    }
  });
  _exports.default = _default;
});