define("soferuj/modules/driving-school/components/main-action-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KVp1c0ZX",
    "block": "{\"symbols\":[\"@drivingSchool\",\"@createReview\",\"@showRatingButton\",\"@toggleEditing\",\"@isEditing\",\"@isOwner\"],\"statements\":[[6,[37,0],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[30,[36,1],[[32,1,[\"publishedAt\"]],[32,1,[\"verified\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"paper-button\",[],[[\"@primary\",\"@onClick\"],[true,[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Ukončit upravovanie\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"paper-button\",[],[[\"@accent\",\"@raised\",\"@class\",\"@onClick\"],[true,true,\"hide show-gt-xs\",[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"pencil-alt\"]],null],[2,\" Upraviť\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,\"paper-button\",[],[[\"@raised\",\"@accent\",\"@mini\",\"@class\",\"@onClick\"],[true,true,true,\"green hide-gt-xs\",[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"pencil-alt\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"warning-alert\"],[12],[2,\"Čaká na overenie\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"driving-school/components/add-review-button\",[],[[\"@drivingSchool\",\"@createReview\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"and\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/components/main-action-button/template.hbs"
    }
  });
  _exports.default = _default;
});