define("soferuj/modules/register/driving-school/profile-picture/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7RaFytiO",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[6,[37,4],null,[[\"onSubmit\",\"class\"],[[30,[36,3],[[32,0],\"submit\"],null],\"layout-column layout-align-space-between flex\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"layout-column flex layout-align-center-center\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"Profilová fotka\"],[13],[2,\"\\n    \"],[10,\"small\"],[14,0,\"profile-picture-description\"],[12],[2,\"Profilovka dá vašej autoškole charakter a taktiež vás zviditeľní v zozname autoškôl.\"],[13],[2,\"\\n\\n    \"],[8,\"profile-picture-new\",[],[[\"@tempPicture\",\"@image\",\"@drivingSchool\",\"@class\"],[true,[32,0,[\"model\",\"profilePicture\"]],true,\"profile-picture\"]],null],[2,\"\\n\\n    \"],[8,\"paper-button\",[],[[\"@class\",\"@primary\",\"@raised\",\"@onClick\"],[\"green\",true,true,[32,0,[\"togglePictureEditing\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[[32,0,[\"model\",\"profilePicture\"]],\"Zmeniť profilovku\",\"Pridať profilovku\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"editingProfilePicture\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"edit-profile-picture-dialog\",[],[[\"@onlyCropp\",\"@model\",\"@isDrivingSchool\",\"@onCropp\",\"@closeDialog\"],[true,[32,0,[\"model\"]],true,[32,0,[\"setPicture\"]],[32,0,[\"togglePictureEditing\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[1,[30,[36,2],null,[[\"form\",\"registration\"],[[32,1],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"registration\",\"register/driving-school/components/register-footer\",\"action\",\"paper-form\"]}",
    "meta": {
      "moduleName": "soferuj/modules/register/driving-school/profile-picture/template.hbs"
    }
  });
  _exports.default = _default;
});