define("soferuj/modules/register/driving-school/profile-picture/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Controller.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    registration: Ember.inject.controller('register.drivingSchool'),
    ajax: Ember.inject.service(),
    isCropping: false,
    croppedImage: null,
    editingProfilePicture: false,
    togglePictureEditing() {
      this.toggleProperty('editingProfilePicture');
    },
    setPicture(editProperties) {
      this.togglePictureEditing();
      this.registration.send('setProfilePictureProperties', editProperties);
    },
    submit() {
      this.registration.send('nextStep');
    }
  }, (_applyDecoratedDescriptor(_obj, "togglePictureEditing", [_dec], Object.getOwnPropertyDescriptor(_obj, "togglePictureEditing"), _obj), _applyDecoratedDescriptor(_obj, "setPicture", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setPicture"), _obj), _applyDecoratedDescriptor(_obj, "submit", [_dec3], Object.getOwnPropertyDescriptor(_obj, "submit"), _obj)), _obj)));
  _exports.default = _default;
});