define("soferuj/modules/driving-school/index/components/addition-item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let classes = {
    true: {
      class: 'yes',
      title: 'áno'
    },
    false: {
      class: 'no',
      title: 'nie'
    },
    undefined: {
      class: 'undefined',
      title: 'nezistené'
    }
  };
  class AdditionItem extends _component.default {
    get props() {
      return classes[this.args.value];
    }
  }
  _exports.default = AdditionItem;
});