define("ember-star-rating/templates/components/star-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7XE3KP3P",
    "block": "{\"symbols\":[\"star\",\"index\"],\"statements\":[[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"svg\"],[15,\"width\",[34,0]],[15,\"height\",[34,1]],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[15,\"viewBox\",[34,2]],[12],[2,\"\\n    \"],[10,\"defs\"],[12],[2,\"\\n      \"],[10,\"linearGradient\"],[15,1,[31,[[34,3],\"-star-\",[32,2]]]],[14,\"x1\",\"0%\"],[14,\"y1\",\"0%\"],[14,\"x2\",\"100%\"],[14,\"y2\",\"0%\"],[12],[2,\"\\n        \"],[10,\"stop\"],[14,0,\"star-rating-filled\"],[14,\"offset\",\"0%\"],[15,\"stop-color\",[34,4]],[12],[13],[2,\"\\n        \"],[10,\"stop\"],[14,0,\"star-rating-base\"],[14,\"offset\",\"0%\"],[15,\"stop-color\",[34,5]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"path\"],[15,\"fill\",[31,[\"url(#\",[34,3],\"-star-\",[32,2],\")\"]]],[15,\"d\",[34,6]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"width\",\"height\",\"viewBox\",\"elementId\",\"fillColor\",\"baseColor\",\"svgPath\",\"stars\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-star-rating/templates/components/star-rating.hbs"
    }
  });
  _exports.default = _default;
});