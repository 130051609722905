define("ember-paper/components/paper-divider/component", ["exports", "@ember-decorators/component", "ember-paper/components/paper-divider/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  /**
   * @class PaperDivider
   * @extends Component
   */
  let PaperDivider = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class PaperDivider extends Ember.Component {}) || _class) || _class);
  var _default = PaperDivider;
  _exports.default = _default;
});