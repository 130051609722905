define("soferuj/models/driving-school/user-review", ["exports", "@ember-data/model", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _model, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    text: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    rating: (0, _model.attr)('number'),
    createdAt: (0, _model.attr)('date'),
    publishedAt: (0, _model.attr)('date'),
    rejectReason: (0, _model.attr)('string'),
    likeCount: (0, _model.attr)('number'),
    dislikeCount: (0, _model.attr)('number'),
    response: (0, _attributes.fragment)('review.response')
  });
  _exports.default = _default;
});