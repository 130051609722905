define("soferuj/models/test-history", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    testNumber: (0, _model.attr)('number'),
    count: (0, _model.attr)('number'),
    correctCount: (0, _model.attr)('number'),
    totalPoints: (0, _model.attr)('number'),
    points: (0, _model.attr)('number'),
    percentage: (0, _model.attr)('number'),
    type: (0, _model.attr)(),
    startedAt: (0, _model.attr)('date'),
    createdAt: (0, _model.attr)('date')
  });
  _exports.default = _default;
});