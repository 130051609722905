define("soferuj/models/driving-school/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Názov',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 3
      })]
    },
    ico: {
      description: 'IČO',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 8
      })]
    },
    'contact.phone': {
      description: 'Telefónne číslo',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        type: 'phone'
      })]
    },
    'contact.email': {
      description: 'Email',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })]
    },
    'contact.website': {
      description: 'Webstránka',
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        type: 'url'
      })]
    },
    'contact.facebook': {
      description: 'Facebook',
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        type: 'url'
      })]
    },
    'contact.googlePlus': {
      description: 'Google plus',
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        type: 'url'
      })]
    },
    'address.streetNumber': {
      description: 'Street Number',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.not('model.address.street')
      })]
    }
    // }, {
    // 	debounce: 200
    // }
  });
  var _default = Validations;
  _exports.default = _default;
});