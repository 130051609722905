define("soferuj/models/driving-school", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "soferuj/models/driving-school/validations"], function (_exports, _model, _attributes, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend(_validations.default, {
    verified: (0, _model.attr)('boolean'),
    name: (0, _model.attr)('string'),
    ico: (0, _model.attr)('number'),
    url: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    contact: (0, _attributes.fragment)('driving-school.contact', {
      defaultValue: {}
    }),
    address: (0, _attributes.fragment)('driving-school.address', {
      defaultValue: {}
    }),
    profilePicture: (0, _model.attr)('string'),
    additions: (0, _attributes.fragment)('driving-school.additions', {
      defaultValue: {}
    }),
    rating: (0, _model.attr)('number'),
    reviewCount: (0, _model.attr)('number'),
    ratingDetails: (0, _attributes.fragment)('driving-school.details'),
    ratingEnabled: (0, _model.attr)('boolean'),
    reviewAddedAt: (0, _model.attr)('date'),
    createdAt: (0, _model.attr)('date'),
    publishedAt: (0, _model.attr)('date'),
    userReview: (0, _attributes.fragment)('driving-school.userReview', {
      defaultValue: null
    }),
    // logged in user review
    user: (0, _model.belongsTo)(),
    languages: (0, _model.hasMany)(),
    licenceTypes: (0, _model.hasMany)(),
    reviews: (0, _model.hasMany)(),
    courses: (0, _model.hasMany)()
  });
  _exports.default = _default;
});