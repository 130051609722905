define("soferuj/models/driving-school/additions", ["exports", "@ember-data/model", "ember-data-model-fragments"], function (_exports, _model, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberDataModelFragments.default.Fragment.extend({
    trainingRides: (0, _model.attr)('boolean'),
    trainer: (0, _model.attr)('boolean'),
    firstAid: (0, _model.attr)('boolean'),
    installments: (0, _model.attr)('boolean'),
    studentDiscount: (0, _model.attr)('boolean')
  });
  _exports.default = _default;
});