define("soferuj/modules/components/profile-picture/component", ["exports", "soferuj/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: needs refactor
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    actions: {
      uploadPicture() {
        this.$('#upload-profile-picture').click();
      },
      removeProfilePicture() {
        if (this.onlyCropp) return this.sendAction('onRemoveProfilePicture');
        let model = this.model;
        const url = this.drivingSchool ? `driving-schools/${model.id}` : 'me';
        this.ajax.delete(`${url}/profile-picture`, {
          data: {
            profile_picture: model.profilePicture
          }
        }).then(() => {
          model.set('profilePicture', null);
          this.sendAction('onRemoveProfilePicture');
        });
      },
      onSaveTmpPicture() {
        const data = event.target.files[0];
        var formData = new FormData();
        formData.append('profile_picture', data);
        $.ajax({
          type: 'POST',
          url: `${_environment.default.APP.API_URL}/profile-picture`,
          withCredentials: true,
          processData: false,
          contentType: false,
          dataType: 'json',
          data: formData,
          success: _ref => {
            let {
              data
            } = _ref;
            Ember.run.later(() => {
              this.set('model.profilePicture', data.attributes['profile-picture']);
              this.sendAction('onProfilePictureAdded');
            }, 500);
          }
        });
      }
    }
  });
  _exports.default = _default;
});