define("ember-paper/templates/components/paper-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IVT+Cv5W",
    "block": "{\"symbols\":[\"&default\",\"@iconButton\"],\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"paper-ripple\",[],[[\"@fitRipple\",\"@center\",\"@dimBackground\"],[[32,2],[32,2],[30,[36,2],[[32,2]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"if\",\"not\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-button.hbs"
    }
  });
  _exports.default = _default;
});