define("soferuj/modules/test/components/question-sign/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "p7X9Yijj",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"questionSign\"]}",
    "meta": {
      "moduleName": "soferuj/modules/test/components/question-sign/template.hbs"
    }
  });
  _exports.default = _default;
});