define("soferuj/modules/login/recovery/new-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BKDeNSQu",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"class\"],[\"route-login\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"login-register-container layout-column\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"header\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"strong\"],[12],[2,\"Zvoľ si nové heslo\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\n\"],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,1],null,[[\"submitTitle\",\"token\"],[\"Zmeniť heslo\",[35,0,[\"token\"]]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[6,[37,2],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Spomenul som si na heslo\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"login/components/password-form\",\"link-to\",\"page/page-content\"]}",
    "meta": {
      "moduleName": "soferuj/modules/login/recovery/new-password/template.hbs"
    }
  });
  _exports.default = _default;
});