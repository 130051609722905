define("soferuj/modules/info/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    titleToken: 'Informácie',
    headTags: [{
      type: 'meta',
      tagId: 'description',
      attrs: {
        name: 'description',
        content: 'Prehľad skupín vodičských oprávnení, legislatíva, značky, dopravné značenia a ďalšie informácie, ktoré sa určite zíjdu.'
      }
    }]
  });
  _exports.default = _default;
});