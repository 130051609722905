define("soferuj/modules/test/components/test-evaluation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isSuccessful: Ember.computed('evaluation.points', function () {
      let evaluation = this.evaluation;
      return evaluation.points >= 50;
    })
  });
  _exports.default = _default;
});