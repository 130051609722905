define("ember-paper/components/paper-reset-button", ["exports", "ember-css-transitions/mixins/transition-mixin"], function (_exports, _transitionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_transitionMixin.default, {
    tagName: 'button',
    type: 'button',
    attributeBindings: ['tabindex', 'type'],
    transitionClass: 'ng',
    onReset: null,
    mouseUp(e) {
      let onReset = this.get('onReset');
      if (onReset === null) {
        return;
      }
      onReset(e);
    },
    didTransitionOut() {
      this._super(...arguments);
      if (this.get('onDidTransitionOut')) {
        this.get('onDidTransitionOut')();
      }
    }
  });
  _exports.default = _default;
});