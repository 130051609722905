define("soferuj/modules/driving-school/pricing/components/price-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SEFkGUvt",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[1,[30,[36,8],null,[[\"course\",\"isSubmitted\"],[[35,1],[35,7]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,6],null,[[\"class\"],[\"price-item-content flex\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,0],[[35,3]],[[\"class\"],[\"group-icon\"]]]],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"md-list-item-text description flex\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"h4\"],[14,0,\"title\"],[12],[2,\"Skupina \"],[1,[35,1,[\"category\",\"name\"]]],[13],[2,\"\\n\"],[6,[37,4],[[35,1,[\"duration\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"duration\"],[14,\"title\",\"trvanie kurzu\"],[12],[1,[30,[36,0],[\"clock\"],[[\"prefix\",\"class\"],[\"far\",\"icon\"]]]],[1,[30,[36,2],[[35,1,[\"duration\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[10,\"span\"],[15,0,[31,[\"type \",[34,1,[\"type\"]]]]],[12],[1,[34,5]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"price\"],[12],[1,[30,[36,4],[[35,1,[\"price\"]],[35,1,[\"price\"]],\"?\"],null]],[2,\"€\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"course\",\"moment-duration\",\"groupIcon\",\"if\",\"type\",\"paper-item\",\"isSubmitted\",\"driving-school/pricing/components/course-form\",\"isEditing\"]}",
    "meta": {
      "moduleName": "soferuj/modules/driving-school/pricing/components/price-item/template.hbs"
    }
  });
  _exports.default = _default;
});