define("ember-paper/components/paper-slider/component", ["exports", "ember-paper/components/paper-slider/template", "ember-paper/utils/clamp", "@ember-decorators/component"], function (_exports, _template, _clamp, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /* global Hammer */
  /**
   * @class PaperSlider
   * @extends Ember.Component
   */
  let PaperSlider = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('value', 'min', 'max'), _dec4 = Ember.computed('percent'), _dec5 = Ember.computed('percent'), _dec6 = Ember.computed('percent', 'min'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class PaperSlider extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "min", 0);
      _defineProperty(this, "max", 100);
      _defineProperty(this, "step", 1);
      _defineProperty(this, "tabindex", 0);
      _defineProperty(this, "active", false);
      _defineProperty(this, "dragging", false);
      _defineProperty(this, "focused", false);
      _defineProperty(this, "element", null);
    }
    get percent() {
      let min = parseFloat(this.min, 10);
      let max = parseFloat(this.max, 10);
      return (0, _clamp.default)((this.value - min) / (max - min), 0, 1);
    }
    get activeTrackStyle() {
      let percent = this.percent || 0;
      return Ember.String.htmlSafe(`width: ${percent * 100}%`);
    }
    get thumbContainerStyle() {
      let percent = this.percent || 0;
      return Ember.String.htmlSafe(`left: ${percent * 100}%`);
    }
    get isMinimum() {
      return this.percent === this.min;
    }
    onDidInsert(element) {
      this.element = element;
      if (!this.disabled) {
        this._setupHammer();
      }
    }
    onDidUpdate() {
      if (!this.disabled && !this._hammer) {
        // if it is enabled and we didn't init hammer yet
        this._setupHammer();
      } else if (this.disabled && this._hammer) {
        // if it is disabled and we did init hammer already
        this._teardownHammer();
      }
    }
    onWillDestroy() {
      if (this._hammer) {
        this._teardownHammer();
      }
    }
    _setupHammer() {
      // Enable dragging the slider
      let containerManager = new Hammer.Manager(this.element);
      let pan = new Hammer.Pan({
        direction: Hammer.DIRECTION_HORIZONTAL,
        threshold: 10
      });
      containerManager.add(pan);
      let tap = new Hammer.Tap();
      containerManager.add(tap);
      containerManager.on('panstart', Ember.run.bind(this, this.onDragStart)).on('panmove', Ember.run.bind(this, this.onDrag)).on('panend', Ember.run.bind(this, this.onDragEnd)).on('tap', Ember.run.bind(this, this.onTap));
      this._hammer = containerManager;
    }
    _teardownHammer() {
      this._hammer.destroy();
      delete this._hammer;
    }
    positionToPercent(x) {
      let {
        left,
        width
      } = this.sliderDimensions();
      return Math.max(0, Math.min(1, (x - left) / width));
    }
    percentToValue(x) {
      let min = parseFloat(this.min, 10);
      let max = parseFloat(this.max, 10);
      return min + x * (max - min);
    }
    minMaxValidator(value) {
      let min = parseFloat(this.min, 10);
      let max = parseFloat(this.max, 10);
      return Math.max(min, Math.min(max, value));
    }
    stepValidator(value) {
      let step = parseFloat(this.step, 10);
      return Math.round(value / step) * step;
    }
    sliderDimensions() {
      return this.element.querySelector('.md-track-container').getBoundingClientRect();
    }
    setValueFromEvent(value) {
      let exactVal = this.percentToValue(this.positionToPercent(value));
      let closestVal = this.minMaxValidator(this.stepValidator(exactVal));
      if (this.onChange) {
        this.onChange(closestVal);
      }
    }
    onTap(event) {
      if (this.disabled) {
        return;
      }
      this.setValueFromEvent(event.center.x);
    }
    onDragStart(event) {
      if (this.disabled) {
        return;
      }
      this.set('active', true);
      this.set('dragging', true);
      this.element.focus();
      this.setValueFromEvent(event.center.x);
    }
    onDrag(event) {
      if (this.disabled || !this.dragging) {
        return;
      }
      this.setValueFromEvent(event.center.x);
    }
    onDragEnd() {
      if (this.disabled) {
        return;
      }
      this.set('active', false);
      this.set('dragging', false);
    }
    handleKeyDown(event) {
      if (this.disabled) {
        return;
      }
      let changeAmount, newValue;
      if (['ArrowLeft', 'Left'].includes(event.key)) {
        changeAmount = parseFloat(this.step) * -1;
      } else if (['ArrowRight', 'Right'].includes(event.key)) {
        changeAmount = parseFloat(this.step);
      }
      if (changeAmount) {
        if (event.metaKey || event.ctrlKey || event.altKey) {
          changeAmount *= 4;
        }
        newValue = this.value + changeAmount;
        if (this.onChange) {
          this.onChange(this.minMaxValidator(newValue));
        }
        event.preventDefault();
        event.stopPropagation();
      }
    }
    handleFocusIn() {
      if (!this.disabled) {
        this.set('focused', true);
      }
    }
    handleFocusOut() {
      this.set('focused', false);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "percent", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "percent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "activeTrackStyle", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "activeTrackStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "thumbContainerStyle", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "thumbContainerStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isMinimum", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isMinimum"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onDidInsert", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "onDidInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onDidUpdate", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "onDidUpdate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onWillDestroy", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "onWillDestroy"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleFocusIn", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFocusIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleFocusOut", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFocusOut"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = PaperSlider;
  _exports.default = _default;
});