define("soferuj/modules/driving-schools/controller", ["exports", "ember-cli-pagination/computed/paged-array", "lodash"], function (_exports, _pagedArray, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var timer = null;
  const defaultLocation = {
    label: 'Všetky lokality',
    value: 'all'
  };
  const defaultFilters = {
    text: '',
    licenceType: {
      id: 0,
      name: 'Všetky skupiny'
    },
    location: _lodash.default.clone(defaultLocation),
    sorting: {
      label: 'Názvu A-Z',
      orderBy: 'name',
      order: 'asc'
    },
    hasForeignLanguages: false
    // courseCategory: { label: 'Všetky skupiny', value: 'all' }
  };
  var _default = Ember.Controller.extend((_dec = Ember.computed('filters'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    ajax: Ember.inject.service(),
    filters: _lodash.default.cloneDeep(defaultFilters),
    text: Ember.computed.alias('filters.text'),
    licence: Ember.computed.alias('filters.licenceType.name'),
    orderBy: Ember.computed.alias('filters.sorting.orderBy'),
    order: Ember.computed.alias('filters.sorting.order'),
    hasForeignLanguages: Ember.computed.alias('filters.hasForeignLanguages'),
    locationSlug: Ember.computed.alias('filters.location.slug'),
    sorting: [{
      label: 'Názvu A-Z',
      orderBy: 'name',
      order: 'asc'
    }, {
      label: 'Názvu Z-A',
      orderBy: 'name',
      order: 'desc'
    }, {
      label: 'Hodnotenia 5-1',
      orderBy: 'rating',
      order: 'desc'
    }, {
      label: 'Hodnotenia 1-5',
      orderBy: 'rating',
      order: 'asc'
    }],
    licenceTypes: [],
    locations: [],
    page: 1,
    perPage: 10,
    totalPages: Ember.computed.oneWay('model.drivingSchools.meta.lastPage'),
    pagedDrivingSchools: (0, _pagedArray.default)('model.drivingSchools', {
      page: Ember.computed.alias('parent.page'),
      perPage: Ember.computed.alias('parent.perPage'),
      totalPages: Ember.computed.alias('parent.totalPages')
    }),
    get filtersEnabled() {
      return !!this.filters.text || !!this.filters.licenceType.id || this.filters.location.value !== 'all' || this.filters.hasForeignLanguages;
    },
    changeFilter(name, value) {
      this.setProperties({
        page: 1,
        [`filters.${name}`]: value
      });
      if (name === 'location' && value !== defaultLocation) {
        if (value.slug) {
          this.transitionToRoute('driving-schools.location', value.slug);
        } else {
          this.transitionToRoute('driving-schools');
        }
      }

      // debounce
      if (name === 'text') {
        clearTimeout(timer);
        timer = setTimeout(() => this.send('refreshModel'), 500);
      }
    },
    resetFilters() {
      this.setProperties({
        page: 1,
        filters: _lodash.default.cloneDeep(defaultFilters)
      });
      this.transitionToRoute('driving-schools.index');
    },
    onPageChanged() {
      this.set('preserveScrollPosition', true);
      const toolbar = document.getElementById('toolbar');
      if (!!toolbar && toolbar.scrollIntoView) {
        toolbar.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "filtersEnabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "filtersEnabled"), _obj), _applyDecoratedDescriptor(_obj, "changeFilter", [_dec2], Object.getOwnPropertyDescriptor(_obj, "changeFilter"), _obj), _applyDecoratedDescriptor(_obj, "resetFilters", [_dec3], Object.getOwnPropertyDescriptor(_obj, "resetFilters"), _obj), _applyDecoratedDescriptor(_obj, "onPageChanged", [_dec4], Object.getOwnPropertyDescriptor(_obj, "onPageChanged"), _obj)), _obj)));
  _exports.default = _default;
});