define("ember-google-maps/templates/components/g-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZXKI+3Sh",
    "block": "{\"symbols\":[\"gMap\",\"addons\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,5],null,[[\"canvas\",\"publicAPI\",\"map\",\"_internalAPI\"],[[30,[36,4],[\"g-map/canvas\"],[[\"id\",\"classNames\",\"_internalAPI\"],[[32,0,[\"mapId\"]],[32,0,[\"classNames\"]],[32,0,[\"_internalAPI\"]]]]],[32,0,[\"publicAPI\"]],[32,0,[\"map\"]],[32,0,[\"_internalAPI\"]]]]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],null,[[\"gMap\",\"map\",\"_internalAPI\"],[[32,0,[\"gMap\"]],[32,1,[\"map\"]],[32,1,[\"_internalAPI\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],[[32,0],[32,0,[\"_updateGMap\"]]],null],[32,1],[32,2]],null]],[2,\"\\n\\n\"],[6,[37,2],[[28,[32,4]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"canvas\"]],[[17,3]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[18,4,[[32,0,[\"gMap\"]]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"g-map/compute\",\"unless\",\"-private-api/addon-factory\",\"component\",\"hash\",\"with\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map.hbs"
    }
  });
  _exports.default = _default;
});