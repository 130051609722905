define("soferuj/modules/login/activation/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    titleToken: 'Aktivácia účtu'
  });
  _exports.default = _default;
});