define("ember-composable-helpers/helpers/noop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.noop = noop;
  function noop() {
    return () => {};
  }
  var _default = Ember.Helper.helper(noop);
  _exports.default = _default;
});