define("soferuj/models/course", ["exports", "@ember-data/model", "soferuj/models/course/validations"], function (_exports, _model, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend(_validations.default, {
    category: (0, _model.attr)(),
    type: (0, _model.attr)(),
    duration: (0, _model.attr)(),
    price: (0, _model.attr)('number'),
    updatedAt: (0, _model.attr)('date'),
    drivingSchool: (0, _model.belongsTo)('drivingSchool')
  });
  _exports.default = _default;
});