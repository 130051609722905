define("soferuj/modules/test/components/question-nav-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isRight:right-answer', 'isWrong:wrong-answer'],
    isRight: Ember.computed('question.correct_answer', function () {
      let question = this.question;
      return question.answer && question.correct_answer === question.answer;
    }),
    isWrong: Ember.computed('question.correct_answer', function () {
      let question = this.question;
      return question.correct_answer && !question.answer || question.answer && question.answer !== question.correct_answer;
    })
  });
  _exports.default = _default;
});