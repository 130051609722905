define("soferuj/validations/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // override defaults
  // https://github.com/poteto/ember-changeset-validations#overriding-validation-messages
  var _default = {
    invalid: '{description} nie je validný',
    blank: 'Potrebné vyplniť',
    confirmation: "{description} doesn't match {on}",
    date: 'Nesprávny formát dátumu',
    email: 'Nesprávny formát emailu',
    equalTo: '{description} musí byť rovný {is}',
    even: '{description} sa musí rovnať',
    greaterThan: 'Musí byť väčšie ako {gt}',
    greaterThanOrEqualTo: 'Musí byť väčšie alebo rovné {gte}',
    lessThan: 'Musí byť menšie ako {lt}',
    lessThanOrEqualTo: 'Musí byť menšie alebo rovné {lte}',
    phone: 'Nesprávny formát',
    tooLong: 'Maximum je {max} znakov',
    tooShort: 'Mininum je {min} znakov',
    between: 'Musí obsahovať min. {min} a max. {max}  znakov',
    url: 'Nesprávny URL formát',
    notANumber: 'Musí byť číslo',
    truthy: 'Musí byť true'
  };
  _exports.default = _default;
});