define("soferuj/modules/components/back-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kXL2bUp8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"back-button layout layout-align-center-center\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"container flex\"],[12],[2,\"\\n\"],[6,[37,5],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],null,[[\"route\"],[[35,3]]],[[\"default\"],[{\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"chevron-left\"],[[\"class\"],[\"icon\"]]]],[2,\"\\n\\t\\t\\t\\t\\tNaspäť\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],null,[[\"onClick\"],[[30,[36,1],[[32,0],\"redirectBack\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,0],[\"chevron-left\"],[[\"class\"],[\"icon\"]]]],[2,\"\\n\\t\\t\\t\\tNaspäť\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"action\",\"paper-button\",\"url\",\"link-to\",\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/back-button/template.hbs"
    }
  });
  _exports.default = _default;
});