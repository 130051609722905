define("soferuj/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qm1OIhe0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"title\"],[12],[1,[35,0,[\"title\"]]],[13],[2,\"\\n\"],[8,\"head-tags\",[],[[\"@headTags\"],[[34,0,[\"headTags\"]]]],null],[2,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:title\"],[15,\"content\",[34,0,[\"title\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "soferuj/templates/head.hbs"
    }
  });
  _exports.default = _default;
});