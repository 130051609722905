define("soferuj/modules/register/driving-school/components/register-stepper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cac7C2U5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"register-stepper layout-row hide-xs\"],[12],[2,\"\\n\\t\"],[1,[30,[36,1],null,[[\"title\",\"link\",\"step\",\"lastValidStep\"],[\"Základné info\",\"basic-info\",1,[35,0]]]]],[2,\"\\n\\n\\t\"],[1,[30,[36,1],null,[[\"title\",\"link\",\"step\",\"lastValidStep\",\"isDuplicate\"],[\"Kontakt\",\"contact\",2,[35,0],[35,2]]]]],[2,\"\\n\\t\\n\\t\"],[1,[30,[36,1],null,[[\"title\",\"link\",\"step\",\"lastValidStep\",\"isDuplicate\"],[\"Profilová fotka\",\"profile-picture\",3,[35,0],[35,2]]]]],[2,\"\\n\\n\\t\"],[1,[30,[36,1],null,[[\"title\",\"link\",\"step\",\"lastValidStep\"],[\"Dokončenie\",\"finish\",4,[35,0]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"lastValidStep\",\"register/driving-school/components/register-stepper/register-step\",\"isDuplicate\"]}",
    "meta": {
      "moduleName": "soferuj/modules/register/driving-school/components/register-stepper/template.hbs"
    }
  });
  _exports.default = _default;
});