define("soferuj/modules/register/driving-school/finish/user-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['user-form', 'layout-column'],
    isPasswordVisible: false,
    inputType: Ember.computed('isPasswordVisible', function () {
      return this.isPasswordVisible ? "text" : "password";
    })
  });
  _exports.default = _default;
});