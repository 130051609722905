define("soferuj/modules/login/recovery/index/route", ["exports", "soferuj/mixins/unauthenticated-route"], function (_exports, _unauthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_unauthenticatedRoute.default, {
    titleToken: 'Obnova hesla'
  });
  _exports.default = _default;
});