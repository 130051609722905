define("soferuj/modules/register/driving-school/components/register-stepper/register-step/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['step', 'flex'],
    classNameBindings: ['isCompleted:completed', 'isNext:next'],
    isNext: Ember.computed('lastValidStep', function () {
      return this.step === this.lastValidStep;
    }),
    isCompleted: Ember.computed('lastValidStep', function () {
      return this.step < this.lastValidStep;
    })
  });
  _exports.default = _default;
});