define("soferuj/modules/register/user/components/user-form/component", ["exports", "soferuj/config/environment", "lodash"], function (_exports, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['user-form', 'layout-column'],
    showMessage: false,
    isPasswordVisible: false,
    conditionsError: false,
    serverErrors: {},
    ajax: Ember.inject.service(),
    metrics: Ember.inject.service(),
    isTouched: Ember.computed.or('serverErrors.{email,username}'),
    inputType: Ember.computed('isPasswordVisible', function () {
      return this.isPasswordVisible ? 'text' : 'password';
    }),
    // TODO: check if I can nest it errors={email: xx, username: xx}
    emailErrors: Ember.computed.union('model.validations.attrs.email.messages', 'serverErrors.email'),
    usernameErrors: Ember.computed.union('model.validations.attrs.username.messages', 'serverErrors.username'),
    actions: {
      removeErrors(prop) {
        if (this.get(`serverErrors.${prop}`)) {
          let errors = _lodash.default.omit(this.serverErrors, prop);
          this.set('serverErrors', errors);
        }
      },
      createUser() {
        let model = this.model;
        this.set('showMessage', false);
        this.conditionsError = false;
        if (!this.pageConditions || !this.dataProtection) {
          this.set('conditionsError', true);
          return;
        }
        if (model.get('validations.isValid')) {
          this.metrics.trackEvent({
            category: 'User',
            action: 'registration',
            label: 'website'
          });
          this.ajax.post('users', {
            data: _lodash.default.merge(model.toJSON(), {
              password: model.password
            })
          }).then(() => {
            this.set('showMessage', true);
            this.sendAction('resetModel');
          }).catch(_ref => {
            let {
              payload
            } = _ref;
            let serverErrors = {};

            // TODO: some translator service, or api should send it translated
            if (payload.errors[0].source.pointer === 'email') {
              serverErrors['email'] = ['Používateľ s takým emailom už existuje'];
            }
            if (payload.errors[0].source.pointer === 'username') {
              serverErrors['username'] = ['Takéto používateľské meno už existuje'];
            }
            this.set('serverErrors', serverErrors);
          });
        }
      },
      facebookRegistration() {
        if (!this.pageConditions || !this.dataProtection) {
          this.set('conditionsError', true);
          return;
        }
        this.metrics.trackEvent({
          category: 'User',
          action: 'registration',
          label: 'facebook'
        });
        window.location.href = _environment.default.APP.API_URL + '/facebook-login';
      }
    }
  });
  _exports.default = _default;
});