define("ember-cli-analytics/mixins/trackable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    /*
     * Inject the analytics service
     * so we may make use of multiple
     * analytics integrations.
     */
    analytics: Ember.inject.service(),
    /*
     * Push the page transition to all
     * analytics adapters.
     *
     * @method trackPageView
     * @on didTransition
     */
    trackPageView: Ember.on('didTransition', function () {
      const analytics = Ember.get(this, 'analytics');
      const owner = Ember.getOwner(this);
      (false && !(analytics) && Ember.assert('Could not find the analytics service.', analytics));
      let limitRouteInformation = false;
      if (owner) {
        const config = owner.resolveRegistration('config:environment');
        limitRouteInformation = Ember.get(config, 'analytics.options.limitRouteInformation');
      }
      const routeData = limitRouteInformation ? Ember.get(this, 'currentRouteName') : Ember.get(this, 'url');
      analytics.trackPage({
        page: routeData,
        title: routeData
      });
    })
  });
  _exports.default = _default;
});