define("soferuj/modules/components/page-numbers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "A/zLIN04",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"pagination\"],[12],[2,\"\\n  \"],[6,[37,3],null,[[\"primary\",\"class\",\"disabled\",\"onClick\"],[true,\"arrow small\",[30,[36,5],[[35,4]],null],[30,[36,2],[[32,0],\"incrementPage\",-1],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"chevron-left\"],null]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"dots\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[12],[2,\"...\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[6,[37,3],null,[[\"class\",\"disabled\",\"onClick\"],[\"small\",[32,1,[\"current\"]],[30,[36,2],[[32,0],\"pageClicked\",[32,1,[\"page\"]]],null]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"page\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[6,[37,3],null,[[\"primary\",\"class\",\"disabled\",\"onClick\"],[true,\"arrow small\",[30,[36,5],[[35,9]],null],[30,[36,2],[[32,0],\"incrementPage\",1],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"chevron-right\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"if\",\"action\",\"paper-button\",\"canStepBackward\",\"not\",\"pageItems\",\"-track-array\",\"each\",\"canStepForward\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/page-numbers/template.hbs"
    }
  });
  _exports.default = _default;
});