define("ember-paper/components/paper-menu/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CiQmgeeF",
    "block": "{\"symbols\":[\"&default\",\"@href\",\"@target\",\"@disabled\",\"&attrs\"],\"statements\":[[11,\"md-menu-item\"],[17,5],[16,\"disabled\",[32,4]],[4,[38,0],[\"mouseenter\",[32,0,[\"handleMouseEnter\"]]],null],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"shouldRenderButton\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"paper-button\",[],[[\"@onClick\",\"@href\",\"@target\",\"@disabled\"],[[32,0,[\"handleClick\"]],[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-menu/item/template.hbs"
    }
  });
  _exports.default = _default;
});