define("soferuj/modules/register/driving-school/finish/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: refactor
  var _default = Ember.Controller.extend({
    identity: Ember.inject.service('identity'),
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    registration: Ember.inject.controller('register.drivingSchool'),
    errors: {},
    // some service for that? https://github.com/offirgolan/ember-cp-validations/issues/342
    emailErrors: Ember.computed('model.validations.attrs.email.messages', 'errors.email', function () {
      let errors = this.get('model.validations.attrs.email.messages');
      // let serverErrors = this.get('errors');

      // if (serverErrors && serverErrors.email) { // TODO: serverErrors is empty, causing error
      //   errors.push('Používateľ s takýmto emailom už existuje');
      // }

      return errors;
    }),
    actions: {
      removeErrors() {
        this.set('errors', {});
      },
      async submit() {
        let registration = this.registration;
        let drivingSchool = registration.get('model');
        const notifications = this.paperToaster;
        const schoolId = this.registration.get('schoolId');
        const inviteToken = this.registration.get('inviteToken');
        if (!this.pageConditions) {
          notifications.show('Pre pokračovanie musíte súhlasiť s pravidlami šoferuj.sk.', {
            toastClass: 'warning'
          });
          return;
        }
        if (!this.dataProtection) {
          notifications.show('Pre pokračovanie musíte súhlasiť s pravidlami spracovania osobných údajov.', {
            toastClass: 'warning'
          });
          return;
        }
        if (!schoolId && drivingSchool.get('validations.isInvalid')) {
          notifications.show('Našli sa chyby v niektorých poliach. Skontrolujte všetky údaje a skúste to znova.', {
            toastClass: 'warning'
          });
          return;
        }
        if (this.get('identity.isAuthenticated')) {
          drivingSchool.set('user', this.get('identity.user'));
          if (schoolId) {
            this.ajax.patch(`driving-schools/${schoolId}/update-owner`, {
              data: {
                inviteToken
              }
            }).then(() => registration.send('onCompleted')).catch(() => console.log('errror'));
          } else {
            drivingSchool.save().then(drivingSchool => {
              registration.send('onCompleted');
              if (drivingSchool.get('profilePicture')) this.send('saveProfilePicture', drivingSchool);
            });
          }
        } else {
          const model = this.model;
          let createdUser = await this.ajax.post('users', {
            data: {
              type: 'driving-school',
              username: drivingSchool.get('name') ? 'autoskola' + _lodash.default.deburr(drivingSchool.get('name').replace(/\s+/, '').toLowerCase()) : model.get('email'),
              email: model.get('email'),
              password: model.password
            }
          }).catch(() => {
            notifications.show('Používateľ s takýmto emailom už existuje', {
              toastClass: 'warning'
            });
          });
          if (createdUser) {
            this.store.pushPayload(createdUser);
            const user = this.store.peekRecord('user', createdUser.data.id);
            if (schoolId) {
              this.ajax.patch(`driving-schools/${schoolId}/update-owner`, {
                data: {
                  user: {
                    id: createdUser.data.id,
                    ...createdUser.data.attributes
                  },
                  inviteToken
                }
              }).then(drivingSchool => registration.send('onCompleted')).catch(() => console.log('errror'));
            } else {
              drivingSchool.set('user', user);
              drivingSchool.save().then(drivingSchool => {
                registration.send('onCompleted');
                if (drivingSchool.get('profilePicture')) this.send('saveProfilePicture', drivingSchool);
                this.send('resetModel');
              });
            }
          }
        }
      },
      saveProfilePicture(drivingSchool) {
        this.ajax.patch(`driving-schools/${drivingSchool.get('id')}/profile-picture`, {
          data: {
            profile_picture: drivingSchool.get('profilePicture'),
            edit_properties: this.get('registration.profilePictureProperties')
          }
        });
      }
    }
  });
  _exports.default = _default;
});