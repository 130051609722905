define("soferuj/models/user", ["exports", "@ember-data/model", "soferuj/models/user/validations"], function (_exports, _model, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend(_validations.default, {
    username: (0, _model.attr)('string'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    name: Ember.computed('firstName', 'lastName', function () {
      return `${this.firstName || ''} ${this.lastName || ''}`;
    }),
    isDrivingSchool: Ember.computed('type', function () {
      return this.type === 'driving-school';
    }),
    isUser: Ember.computed('type', function () {
      return this.type === 'user';
    }),
    type: (0, _model.attr)(),
    profilePicture: (0, _model.attr)(),
    email: (0, _model.attr)('string'),
    //phone: DS.attr('string'),
    //lastLogin: DS.attr('date'),
    sendNews: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    canAssignNewSchool: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    sendNews: (0, _model.attr)('boolean'),
    drivingSchools: (0, _model.hasMany)()
  });
  _exports.default = _default;
});