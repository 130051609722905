define("soferuj/modules/application/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    headData: Ember.inject.service(),
    identity: Ember.inject.service(),
    moment: Ember.inject.service(),
    title: function (tokens) {
      const title = tokens.length ? tokens.reverse().join(' - ') + ' - Šoféruj.sk' : 'Autoškoly, Testy, Informácie - Šoféruj.sk';
      this.headData.set('title', title);
      return title;
    },
    beforeModel() {
      this.moment.setLocale('sk');
      this._loadCurrentUser();
    },
    async _loadCurrentUser() {
      await this.identity.load();
    }
  });
  _exports.default = _default;
});