define("soferuj/modules/components/edit-profile-picture-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UorZilog",
    "block": "{\"symbols\":[\"@onlyCropp\",\"@model\",\"@isDrivingSchool\",\"@closeDialog\"],\"statements\":[[8,\"paper-dialog\",[],[[\"@class\",\"@onClose\",\"@clickOutsideToClose\"],[\"edit-profile-picture-dialog\",[32,4],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-dialog-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[14,0,\"md-title title\"],[12],[2,\"Upraviť profilovú fotku\"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isCropping\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"image-cropper\",[],[[\"@model\",\"@onCancel\",\"@onCropped\"],[[32,2],[32,0,[\"onCancel\"]],[32,0,[\"savePhoto\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"profile-picture-container\"],[12],[2,\"\\n        \"],[8,\"profile-picture-new\",[],[[\"@tempPicture\",\"@image\",\"@drivingSchool\"],[[32,1],[32,2,[\"profilePicture\"]],[32,3]]],null],[2,\"\\n\"],[6,[37,0],[[32,2,[\"profilePicture\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"           \"],[8,\"paper-button\",[],[[\"@primary\",\"@onClick\"],[true,[32,0,[\"removeProfilePicture\"]]]],[[\"default\"],[{\"statements\":[[2,\"Zmazať profilovku\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[8,\"paper-button\",[],[[\"@onClick\"],[[32,0,[\"onUploadPicture\"]]]],[[\"default\"],[{\"statements\":[[2,\"Nahrať obrázok\"]],\"parameters\":[]}]]],[2,\"\\n          \"],[10,\"input\"],[14,1,\"upload-profile-picture\"],[14,5,\"visibility:hidden;\"],[15,\"onchange\",[32,0,[\"onSaveTmpPicture\"]]],[14,\"accept\",\"image/png,image/jpeg\"],[14,4,\"file\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/edit-profile-picture-dialog/template.hbs"
    }
  });
  _exports.default = _default;
});