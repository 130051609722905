define("soferuj/modules/driving-schools/route", ["exports", "ember-cli-pagination/remote/route-mixin", "query-string"], function (_exports, _routeMixin, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DrivingSchools = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class DrivingSchools extends Ember.Route.extend(_routeMixin.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "title", _descriptor2, this);
      _defineProperty(this, "queryParams", {
        text: {},
        page: {
          refreshModel: true
        },
        licence: {
          refreshModel: true
        },
        locationSlug: {
          refreshModel: true
        },
        order: {
          refreshModel: true
        },
        orderBy: {
          refreshModel: true
        },
        hasForeignLanguages: {
          refreshModel: true
        }
      });
      _defineProperty(this, "headTags", [{
        type: 'meta',
        tagId: 'description',
        attrs: {
          name: 'description',
          content: 'Nájdite si tu správnu autoškolu v našom zozname autoškôl. Informácie, ceny a kontaktné údaje.'
        }
      }]);
    }
    get locationSlug() {
      const path = window.location ? window.location.pathname : '';
      return path.includes('/autoskoly') ? path.split('/')[2] : undefined;
    }
    beforeModel() {
      const {
        locationSlug
      } = this.paramsFor('driving-schools');
      const path = window.location ? window.location.pathname : '';
      if (locationSlug && !path.includes(locationSlug)) {
        this.transitionTo('driving-schools.location', locationSlug);
      }
      const controller = this.controllerFor('driving-schools');
      const location = controller.filters.location;
      this.title = location.value !== 'all' ? `${location.label} - Katalóg autoškôl` : 'Katalóg autoškôl';
    }
    model(params) {
      params = {
        ...params,
        text: params.text || undefined,
        locationSlug: params.locationSlug === 'all' ? undefined : params.locationSlug,
        licence: params.licence === 'Všetky skupiny' ? undefined : params.licence,
        hasForeignLanguages: params.hasForeignLanguages || undefined
      };
      return Ember.RSVP.hash({
        drivingSchools: this.findPaged('driving-school', {
          ...params,
          include: 'languages,licenceTypes'
        }),
        mapLocations: this.ajax.request(`driving-schools/locations?${_queryString.default.stringify(params)}`).then(locations => locations).catch(e => console.log('Chyba lokácií'))
      });
    }
    async setupController(controller, model) {
      super.setupController(controller, model);
      const params = this.paramsFor('driving-schools');
      controller.preserveScrollPosition = true;
      if (!controller.licenceTypes.length || !controller.locations.length) {
        const licenceTypes = await this.store.findAll('licenceType');
        controller.set('licenceTypes', [{
          id: 0,
          name: 'Všetky skupiny'
        }].concat(licenceTypes.toArray()));
        const villagesByRegion = await this.ajax.request('region-villages?schoolsPresent=true');
        const locations = villagesByRegion.reduce((locations, region) => {
          const villages = region.villages.map(village => ({
            label: village.name,
            value: village.id,
            slug: village.slug
          }));
          locations = locations.concat([{
            label: region.name,
            value: region.id,
            slug: region.slug,
            class: 'region'
          }]).concat(villages);
          return locations;
        }, [{
          label: 'Všetky lokality',
          value: 'all'
        }]);
        controller.set('locations', locations);
      }
      controller.set('filters', {
        text: params.text,
        licenceType: controller.licenceTypes.find(licence => params.licence === licence.name),
        location: controller.locations.find(location => (params.locationSlug || this.locationSlug) === location.slug),
        sorting: controller.sorting.find(sorting => sorting.order === (params.order || 'asc') && sorting.orderBy === (params.orderBy || 'name')),
        hasForeignLanguages: !!params.hasForeignLanguages
      });
    }
    refreshModel() {
      this.refresh();
    }
    deactivate() {
      this.controller.preserveScrollPosition = false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Katalóg autoškôl';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class));
  _exports.default = DrivingSchools;
});