define("soferuj/modules/components/ember-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NHZGy6Xe",
    "block": "{\"symbols\":[],\"statements\":[[10,\"canvas\"],[15,1,[30,[36,1],[[35,0],\"-chart\"],null]],[14,0,\"chart\"],[15,\"width\",[34,2]],[15,\"height\",[34,3]],[12],[13]],\"hasEval\":false,\"upvars\":[\"elementId\",\"concat\",\"width\",\"height\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/ember-chart/template.hbs"
    }
  });
  _exports.default = _default;
});