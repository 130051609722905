define("soferuj/modules/settings/index/password-form/component", ["exports", "soferuj/modules/settings/index/password-form/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_validations.default, {
    classNames: ['change-password'],
    ajax: Ember.inject.service('ajax'),
    isPasswordVisible: false,
    paperToaster: Ember.inject.service(),
    errors: {},
    inputType: Ember.computed('isPasswordVisible', function () {
      return this.isPasswordVisible ? 'text' : 'password';
    }),
    actions: {
      removeErrors() {
        this.set('errors', {});
      },
      updatePassword() {
        let data = {
          password: this.currentPassword,
          newPassword: this.newPassword
        };
        this.ajax.patch('me/change-password', {
          data
        }).then(() => {
          this.paperToaster.show('Heslo bolo zmenené');
          this.set('currentPassword', null);
          this.set('newPassword', null);
        }).catch(() => {
          this.set('currentPassword', null);
          this.set('errors', {
            currentPassword: ['Nesprávne heslo']
          });
        });
      }
    }
  });
  _exports.default = _default;
});