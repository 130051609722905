define("soferuj/modules/info/index/controller", ["exports", "lodash", "soferuj/modules/info/index/pages"], function (_exports, _lodash, _pages2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    searchString: '',
    pages: Ember.computed('searchString.length', function () {
      const searchString = (0, _lodash.deburr)(this.searchString).toLowerCase();
      return searchString ? _pages2.default.filter(page => (0, _lodash.deburr)(page.name).toLowerCase().search(searchString) >= 0 || (0, _lodash.deburr)(page.description).toLowerCase().search(searchString) >= 0) : _pages2.default;
    }),
    actions: {
      redirect(url) {
        this.transitionToRoute(`info.${url}`);
      },
      open(url) {
        window.open(url, '_blank');
      }
    }
  });
  _exports.default = _default;
});