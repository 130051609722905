define("ember-paper/components/paper-contact-chips/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8oEyU2Iu",
    "block": "{\"symbols\":[\"item\",\"type\",\"@class\",\"&attrs\",\"@readOnly\",\"@addItem\",\"@removeItem\",\"@content\",\"@options\",\"@defaultHighlighted\",\"@placeholder\",\"@search\",\"@matcher\",\"@noMatchesMessage\"],\"statements\":[[11,\"md-contact-chips\"],[16,0,[31,[\"md-default-theme \",[32,3]]]],[17,4],[12],[2,\"\\n  \"],[8,\"paper-chips\",[[24,0,\"md-contact-chips\"]],[[\"@readOnly\",\"@addItem\",\"@removeItem\",\"@requireMatch\",\"@searchField\",\"@content\",\"@options\",\"@defaultHighlighted\",\"@placeholder\",\"@search\",\"@matcher\",\"@noMatchesMessage\"],[[32,5],[32,6],[32,7],true,[32,0,[\"searchField\"]],[32,8],[32,9],[32,10],[32,11],[32,12],[32,13],[32,14]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[32,2],\"suggestion\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"md-contact-suggestion\"],[12],[2,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,[36,1],[[32,1],[32,0,[\"imageField\"]]],null]],[15,\"alt\",[30,[36,1],[[32,1],[32,0,[\"nameField\"]]],null]],[14,0,\"md-contact-avatar\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"md-contact-name\"],[12],[1,[30,[36,1],[[32,1],[32,0,[\"nameField\"]]],null]],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"md-contact-email\"],[12],[1,[30,[36,1],[[32,1],[32,0,[\"emailField\"]]],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,2],\"chip\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"md-contact-avatar\"],[12],[2,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,[36,1],[[32,1],[35,0]],null]],[15,\"alt\",[30,[36,1],[[32,1],[35,2]],null]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"md-contact-name\"],[12],[1,[30,[36,1],[[32,1],[35,2]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"imageField\",\"get\",\"nameField\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-contact-chips/template.hbs"
    }
  });
  _exports.default = _default;
});