define("soferuj/helpers/route-params", ["exports", "ember-router-helpers/helpers/route-params"], function (_exports, _routeParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _routeParams.default;
    }
  });
  Object.defineProperty(_exports, "routeParams", {
    enumerable: true,
    get: function () {
      return _routeParams.routeParams;
    }
  });
});