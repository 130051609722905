define("soferuj/helpers/duration", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.duration = duration;
  const formats = {
    hours: ['hodina', 'hodiny', 'hodín'],
    minutes: ['minúta', 'minúty', 'minút']
  };
  function formatDurationUnit(value, precision) {
    if (value === 1) return formats[precision][0];
    if (value > 1 && value <= 4) return formats[precision][1];
    return formats[precision][2];
  }
  function duration(_ref) {
    let [milliseconds, short] = _ref;
    const {
      hours,
      minutes
    } = _moment.default.duration(milliseconds)._data;
    let time = '';
    time += hours ? `${hours} ${short ? 'hod' : formatDurationUnit(hours, 'hours')} ` : '';
    time += minutes ? `${minutes} ${short ? 'min' : formatDurationUnit(minutes, 'minutes')} ` : '';
    return time;
  }
  var _default = Ember.Helper.helper(duration);
  _exports.default = _default;
});