define("ember-power-select/components/power-select/no-matches-message", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select/no-matches-message"], function (_exports, _component, _noMatchesMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  let NoMatchesMessage = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_noMatchesMessage.default), _dec(_class = _dec2(_class = class NoMatchesMessage extends Ember.Component {}) || _class) || _class);
  _exports.default = NoMatchesMessage;
});