define("soferuj/modules/info/index/pages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    name: 'Ako si správne vybrať autoškolu',
    description: 'Poradíme, ako sa nestratiť v množstve autoškôl, ktoré máme na Slovensku',
    action: 'redirect',
    url: 'choose-school'
  }, {
    name: 'Skupiny vodičských oprávnení',
    description: 'Prehľad vodičských oprávnení podľa skupín motorových vozidiel',
    action: 'redirect',
    url: 'licence-types'
  }, {
    name: 'Dopravné značky a značenia',
    description: 'Prehľad zvislých, vodorovných dopravných značiek a značení',
    action: 'redirect',
    url: 'road-signs'
  }, {
    name: 'Zákon č. 8/2009 o cestnej premávke a o zmene a doplnení niektorých zákonov',
    description: 'Platnosť od 16.01.2009, Účinnosť od	01.04.2020',
    action: 'open',
    url: 'http://www.zakonypreludi.sk/zz/2009-8'
  }, {
    name: 'Konštrukcia, údržba a výbava vozidla',
    description: 'Znalosti potrebné pri praktickej časti – o prevádzke vozidla pre skupinu „B“',
    action: 'redirect',
    url: 'vehicle-usage'
  }, {
    name: 'Kurz prvej pomoci',
    description: 'Čo je jeho obsahom, ako prebieha, cena kurzu?',
    action: 'redirect',
    url: 'first-aid'
  }, {
    name: 'Záverečná skúška na získanie vodičského preukazu',
    description: 'Ako to celé prebieha, na čo si treba dať pozor a čo sa stane, ak skúšku nespravíš?',
    action: 'redirect',
    url: 'final-exam'
  }, {
    name: 'Pokuty',
    description: 'Koľko ťa môžu stáť tvoje priestupky? To sa dozvieš tu',
    action: 'redirect',
    url: 'fines'
  }, {
    name: 'Ako postupovať v prípade dopravnej nehody',
    description: '',
    action: 'redirect',
    url: 'car-accident'
  }, {
    name: 'Bezpečná vzdialenosť',
    description: '',
    action: 'redirect',
    url: 'safe-distance'
  }];
  _exports.default = _default;
});