define("ember-math-helpers/helpers/atan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.atan = atan;
  _exports.default = void 0;
  /**
   * Executes `Math.atan` on the number passed to the helper.
   *
   * ```hbs
   * {{atan a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.atan`
   * @return {number} The atan of the passed number
   */
  function atan(_ref) {
    let [number] = _ref;
    return Math.atan(number);
  }
  var _default = Ember.Helper.helper(atan);
  _exports.default = _default;
});