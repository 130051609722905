define("soferuj/components/fb-share-button", ["exports", "ember-social-share/components/fb-share-button"], function (_exports, _fbShareButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fbShareButton.default;
    }
  });
});