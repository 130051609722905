define("soferuj/modules/settings/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    isCropping: false,
    croppedImage: null,
    actions: {
      setPicture(image) {
        this.set('croppedImage', image);
        this.set('isCropping', false);
      }
    }
  });
  _exports.default = _default;
});