define("soferuj/modules/application/components/main-footer/component", ["exports", "soferuj/modules/statics"], function (_exports, _statics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    contact: _statics.default.contact
  });
  _exports.default = _default;
});