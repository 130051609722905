define("soferuj/modules/test/history/index/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultFilters = {
    type: 'ab',
    testType: {
      label: 'Všetky',
      value: 'all'
    },
    successType: {
      label: 'Všetky',
      value: 'all'
    }
  };
  var _default = Ember.Controller.extend({
    queryParams: ['stranka'],
    filters: Ember.Object.create(defaultFilters),
    stranka: 1,
    naStranku: 20,
    pagedTests: (0, _pagedArray.default)('tests', {
      page: Ember.computed.alias('parent.stranka'),
      perPage: Ember.computed.alias('parent.naStranku')
    }),
    tests: Ember.computed('model', 'filters.{type,testType,successType}', function () {
      let tests = this.model;
      let filters = this.filters;
      if (filters.testType.value !== 'all') {
        tests = tests.filter(test => test.type === filters.testType.value);
      }
      if (filters.successType.value !== 'all') {
        tests = tests.filter(test => filters.successType.value === 'success' ? test.points >= 50 : test.points < 50);
      }
      return tests;
    }),
    chart: Ember.computed('tests', function () {
      return {
        data: {
          labels: ['Úspešné', 'Neúspešné'],
          datasets: [{
            data: [this.tests.filter(test => test.points >= 50).length, this.tests.filter(test => test.points < 50).length],
            backgroundColor: ['#4caf50', '#f44336'],
            borderWidth: 3
          }]
        },
        options: {
          cutoutPercentage: 65,
          legend: {
            display: false
          }
        }
      };
    }),
    actions: {
      onFilterChange(name, value) {
        this.setProperties({
          stranka: 1,
          [`filters.${name}`]: value
        });
        if (name === 'type') {
          this.send('refreshModel');
        }
      },
      resetFilters() {
        this.setProperties({
          stranka: 1,
          filters: Ember.Object.create(defaultFilters)
        });
        if (this.get('filters.type') !== 'ab') {
          this.send('refreshModel');
        }
      },
      openTest(testId) {
        this.transitionToRoute('test.history.test', testId);
      },
      onPageChanged() {
        this.set('preserveScrollPosition', true);
        const toolbar = document.getElementById('main-toolbar');
        if (!!toolbar && toolbar.scrollIntoView) {
          toolbar.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }
    }
  });
  _exports.default = _default;
});