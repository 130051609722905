define("soferuj/modules/register/driving-school/finish/registration-complete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MvztRFVE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"layout-column layout-align-center-center flex\"],[12],[2,\"\\n\\t\"],[1,[30,[36,1],[\"check-circle\"],[[\"prefix\",\"size\",\"class\"],[\"far\",\"5x\",\"registration-completed-icon\"]]]],[2,\"\\n\\t\"],[10,\"h2\"],[12],[2,\"Registrácia prebehla úspešne\"],[13],[2,\"\\n\\t\"],[10,\"p\"],[12],[2,\"Akonáhle preveríme vašu autoškolu, zobrazí sa v katalógu autoškol.\"],[13],[2,\"\\n\"],[6,[37,2],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[6,[37,0],null,[[\"primary\",\"raised\"],[true,true]],[[\"default\"],[{\"statements\":[[2,\"Späť na hlavnú stránku\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"paper-button\",\"fa-icon\",\"link-to\"]}",
    "meta": {
      "moduleName": "soferuj/modules/register/driving-school/finish/registration-complete/template.hbs"
    }
  });
  _exports.default = _default;
});