define("soferuj/modules/application/components/scroll-up/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+8NRtkt2",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-button\",[[24,0,\"scroll-up-button\"],[24,\"title\",\"Hoď ma hore\"]],[[\"@raised\",\"@mini\",\"@primary\",\"@onClick\"],[true,true,true,[30,[36,0],[[32,0],\"scrollUp\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"angle-double-up\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "soferuj/modules/application/components/scroll-up/template.hbs"
    }
  });
  _exports.default = _default;
});