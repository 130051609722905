define("soferuj/modules/components/soferuj-support-us/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ti9l2CGA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"soferuj-support-us\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"description\"],[12],[2,\"\\n    Pomôž malému projektu rásť\\n    \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[\"click\",[32,0,[\"toggleDialog\"]]],null],[12],[2,\"Dozvedieť sa viac\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"fa-icon\",[[24,0,\"icon\"]],[[\"@icon\"],[\"heart\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"dialogOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"soferuj-support-us/dialog\",[],[[\"@closeDialog\"],[[32,0,[\"toggleDialog\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/soferuj-support-us/template.hbs"
    }
  });
  _exports.default = _default;
});