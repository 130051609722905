define("soferuj/modules/components/google-ad/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eiVR5xqV",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"soferuj-contribution layout-row layout-align-center-center\"],[4,[38,4],[[32,0,[\"initialize\"]]],null],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex-sm-90 flex-gt-sm-80 flex\"],[12],[2,\"\\n\"],[6,[37,5],[[32,0,[\"isAdBlockActive\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"disabled layout-row layout-xs-column layout-align-center-center\"],[12],[2,\"\\n          \"],[1,[30,[36,2],[\"heart-broken\"],[[\"class\"],[\"icon\"]]]],[2,\"\\n          \"],[10,\"div\"],[12],[2,\"\\n            \"],[10,\"h3\"],[14,0,\"title\"],[12],[2,\"Sme smutní. Máš zapnuté blokovanie reklám.\"],[13],[2,\"\\n            \"],[10,\"p\"],[12],[2,\"\\n              Týchto pár nenápadných reklám nám zabezpečuje chod stránky.\\n              Ak naďalej chceš blokovať reklamy \"],[11,\"a\"],[24,6,\"#\"],[4,[38,3],[\"click\",[32,0,[\"toggleDialog\"]]],null],[12],[2,\"pozri si dalšie možnosti ako nás môžeš podporiť\"],[13],[2,\".\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"ins\"],[14,0,\"adsbygoogle ad\"],[14,5,\"display:block; text-align:center;\"],[15,\"data-ad-layout\",[34,0]],[14,\"data-ad-client\",\"ca-pub-7527916407092390\"],[15,\"data-ad-slot\",[34,1]],[14,\"data-ad-format\",\"fluid\"],[14,\"data-full-width-responsive\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],[[32,0,[\"dialogOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"soferuj-support-us/dialog\",[],[[\"@closeDialog\"],[[32,0,[\"toggleDialog\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"type\",\"slot\",\"fa-icon\",\"on\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "soferuj/modules/components/google-ad/template.hbs"
    }
  });
  _exports.default = _default;
});