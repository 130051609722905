define("soferuj/modules/driving-school/pricing/components/price-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['price-item'],
    types: {
      standard: 'štandardný',
      training: 'kondičné jazdy',
      express: 'rýchlokurz'
    },
    groupIcon: Ember.computed('course.category.name', function () {
      const category = this.get('course.category.name') || '';
      var icon = '';
      switch (true) {
        case category.startsWith('A'):
          icon = 'motorcycle';
          break;
        case category.startsWith('B'):
          icon = 'car';
          break;
        case category.startsWith('C'):
          icon = 'truck';
          break;
        case category.startsWith('D'):
          icon = 'bus';
          break;
        case category.startsWith('T'):
          icon = 'truck-monster';
          break;
        // TODO: change
        default:
          icon = 'car';
          break;
      }
      return icon;
    }),
    type: Ember.computed('course.type', function () {
      return this.types[this.get('course.type')];
    })
  });
  _exports.default = _default;
});